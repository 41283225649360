import { styled } from "@vitality-ds/system";
import getCellStyles from "./styles/BaseCell.styles";
import getHeaderStyles from "./styles/BaseHeader.styles";
import getRowStyles from "./styles/BaseRow.styles";
import getTableStyles from "./styles/BaseTable.styles";
import BaseTableWrapperStyles from "./styles/BaseTableWrapper.styles";
export var BaseTableWrapper = styled("div", BaseTableWrapperStyles);
var baseTableStyles = function baseTableStyles(size, onRowClick, rowHighlight, tableLayout, numOfCols) {
  return {
    Table: getTableStyles(tableLayout, numOfCols),
    HeaderRow: getHeaderStyles(size),
    Row: getRowStyles(onRowClick, rowHighlight),
    BaseCell: getCellStyles(size)
  };
};
export default baseTableStyles;