import React from "react";
import IconPrimitive from "@vitality-ds/icons";

const GuideDog = (props) => (
  <IconPrimitive {...props}>
    <path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M14.148 10.059l-2.398 2.398v3.357c1.366-.182 2.534-.465 4-1.314 1.069 1.96 1.964 2.523 2.424 2.812.185.116.3.188.326.29.06.23.123.398.172.53.15.4.173.46-.422.868H20v-2.262c0-.988-1.5-3.624-1.5-3.624L20.75 16c.203.127.476-.117.25-.5-.094-.252-.198-.582-.314-.956-.557-1.783-1.424-4.558-3.127-4.558-.246 0-.6.013-1.018.028-.693.024-1.56.055-2.393.045zm-3.398 5.873V12.75H6.793c.404.876.887 1.511 1.261 2.003.07.091.135.178.196.26.25.337 1 2.588-.25 3.624 1.553 0 1.915.53 1.99.493.034-.017.01-.148.01-.493v-2.624l.466-.05.284-.031zM6.41 11.75h4.633L12.8 9.99a12.583 12.583 0 01-.051-.005c-2.038-.217-3.346-.997-4.25-3.43C7.667 4.312 5 5.25 5 7.073c-.607.215-2 .69-2 .69s.25 1.325.75 1.987c.27.197 1.168.236 1.75.236.205 0 .39-.004.556-.012.068.667.194 1.255.355 1.776z"
      fill="currentColor"
    />
    <path
      d="M7.5 19a.5.5 0 01.5-.5h1.5a.5.5 0 010 1H8a.5.5 0 01-.5-.5zM18 19a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM8.987 6.156c.59.362.754 2.2.513 2.594-.241.393-.797-.808-1.387-1.17-.59-.362-.873-.974-.632-1.368.242-.393.916-.418 1.506-.056z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M19.354 4.854a.5.5 0 00-.708-.708L12.793 10h1.414l5.146-5.146z"
      fill="currentColor"
    />
  </IconPrimitive>
);

export default GuideDog;
