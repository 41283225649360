import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var borders = colorUseCases.borders,
  backgrounds = colorUseCases.backgrounds;
export default css({
  display: "flex",
  gap: "$sm",
  paddingLeft: "$md",
  paddingRight: "$sm",
  border: "1px solid ".concat(getColorScaleValueByUseCase("neutral", borders.uiElement)),
  borderRadius: "$default",
  backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.app),
  "&:hover": {
    borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_hovered)
  }
});