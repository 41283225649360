import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const red: ColorScale<"red"> = {
  red1: {
    name: "red1",
    data: generateColorData("red1", { h: 0, s: 23, l: 9, a: 1 }),
  },
  red2: {
    name: "red2",
    data: generateColorData("red2", { h: 0, s: 34, l: 12, a: 1 }),
  },
  red3: {
    name: "red3",
    data: generateColorData("red3", { h: 0, s: 43, l: 16, a: 1 }),
  },
  red4: {
    name: "red4",
    data: generateColorData("red4", { h: 0, s: 47, l: 19, a: 1 }),
  },
  red5: {
    name: "red5",
    data: generateColorData("red5", { h: 0, s: 51, l: 21, a: 1 }),
  },
  red6: {
    name: "red6",
    data: generateColorData("red6", { h: 0, s: 55, l: 25, a: 1 }),
  },
  red7: {
    name: "red7",
    data: generateColorData("red7", { h: 0, s: 60, l: 31, a: 1 }),
  },
  red8: {
    name: "red8",
    data: generateColorData("red8", { h: 0, s: 65, l: 40, a: 1 }),
  },
  red9: {
    name: "red9",
    data: generateColorData("red9", { h: 0, s: 85, l: 61, a: 1 }),
  },
  red10: {
    name: "red10",
    data: generateColorData("red10", { h: 0, s: 85, l: 64, a: 1 }),
  },
  red11: {
    name: "red11",
    data: generateColorData("red11", { h: 0, s: 100, l: 69, a: 1 }),
  },
  red12: {
    name: "red12",
    data: generateColorData("red12", { h: 0, s: 89, l: 96, a: 1 }),
  },
  red13: {
    name: "red13",
    data: generateColorData("red13", { h: 0, s: 23, l: 9, a: 1 }),
  },
};

export default red;
