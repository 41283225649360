import { getSpaceScaleVariants } from "./logic";
export default {
  padding: getSpaceScaleVariants("padding"),
  px: getSpaceScaleVariants("paddingInline"),
  py: getSpaceScaleVariants("paddingBlock"),
  pt: getSpaceScaleVariants("paddingTop"),
  pb: getSpaceScaleVariants("paddingBottom"),
  pl: getSpaceScaleVariants("paddingLeft"),
  pr: getSpaceScaleVariants("paddingRight"),
  margin: getSpaceScaleVariants("margin"),
  mx: getSpaceScaleVariants("marginInline"),
  my: getSpaceScaleVariants("marginBlock"),
  mt: getSpaceScaleVariants("marginTop"),
  mb: getSpaceScaleVariants("marginBottom"),
  ml: getSpaceScaleVariants("marginLeft"),
  mr: getSpaceScaleVariants("marginRight"),
  shouldGrow: {
    "true": {
      flexGrow: 1
    },
    "false": {
      flexGrow: 0
    }
  },
  shouldShrink: {
    "true": {
      flexShrink: 1
    },
    "false": {
      flexShrink: 0
    }
  }
};