import _extends from "@babel/runtime/helpers/extends";
import { colorUseCases, getColorScaleFromThemeObject, theme } from "@vitality-ds/system";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds,
  borders = colorUseCases.borders;
export default (function (baseStyles) {
  return _extends({}, baseStyles, {
    textAlign: "left",
    width: "100%",
    background: getColorScaleFromThemeObject("neutral", backgrounds.app, theme),
    color: getColorScaleFromThemeObject("neutral", text.hiContrast, theme),
    padding: "".concat(theme.space.md.value),
    margin: "".concat(theme.space.md.value, " 0"),
    border: "1px solid",
    borderColor: getColorScaleFromThemeObject("neutral", borders.uiElement, theme),
    borderRadius: theme.radii["default"].value,
    display: "flex",
    flexDirection: "column",
    gap: theme.space.sm.value,
    boxShadow: theme.shadows.sm.value,
    zIndex: theme.zIndices.portalElement.value
  });
});