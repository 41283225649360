import {
  colorUseCases,
  getColorScaleValueByUseCase,
  styled,
} from "@vitality-ds/system";

const { backgrounds, text } = colorUseCases;

export const sharedStyles = {
  $$color: getColorScaleValueByUseCase("primary", text.hiSaturation),
  $$backgroundColor: getColorScaleValueByUseCase(
    "primary",
    backgrounds.uiElement
  ),
  color: "$$color",
  backgroundColor: "$$backgroundColor",
  fontSize: "inherit",
  lineHeight: 1,
  fontFamily: "monospace",
  padding: "$sm",
  borderRadius: "$default",
};

const InlineCode = styled("span", {
  ...sharedStyles,
  "&::selection": {
    background: getColorScaleValueByUseCase(
      "primary",
      backgrounds.uiElement_active
    ),
  },
  variants: {
    variant: {
      primaryStrong: {
        $$color: getColorScaleValueByUseCase("primary", backgrounds.app),
        $$backgroundColor: getColorScaleValueByUseCase(
          "primary",
          text.hiSaturation
        ),
      },
      fade: {
        $$color: getColorScaleValueByUseCase("greyA", text.hiContrast),
        $$backgroundColor: getColorScaleValueByUseCase("grey", backgrounds.app),
      },
      accent: {
        $$color: getColorScaleValueByUseCase("accent", text.hiSaturation),
        $$backgroundColor: getColorScaleValueByUseCase(
          "accent",
          backgrounds.uiElement
        ),
      },
    },
  },
});

export default InlineCode;
