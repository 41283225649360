import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text,
  borders = colorUseCases.borders;
export default css({
  fontSize: "$caption",
  fontFamily: 'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
  color: getColorScaleValueByUseCase("neutralA", text.lowContrast),
  minWidth: ".625rem",
  variants: {
    variant: {
      stylized: {
        borderRadius: 2,
        borderStyle: "solid",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderWidth: "1px 1px 3px",
        borderColor: getColorScaleValueByUseCase("neutralA", borders.uiElement)
      },
      color: {
        inherit: {
          color: "inherit"
        }
      }
    }
  }
});