import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const greyA: ColorScale<"greyA"> = {
  greyA1: {
    name: "greyA1",
    data: generateColorData("greyA1", { h: 187, s: 76, l: 9, a: 0.01 }),
  },
  greyA2: {
    name: "greyA2",
    data: generateColorData("greyA2", { h: 187, s: 34, l: 20, a: 0.03 }),
  },
  greyA3: {
    name: "greyA3",
    data: generateColorData("greyA3", { h: 187, s: 85, l: 18, a: 0.057 }),
  },
  greyA4: {
    name: "greyA4",
    data: generateColorData("greyA4", { h: 187, s: 75, l: 11, a: 0.08 }),
  },
  greyA5: {
    name: "greyA5",
    data: generateColorData("greyA5", { h: 187, s: 76, l: 11, a: 0.1 }),
  },
  greyA6: {
    name: "greyA6",
    data: generateColorData("greyA6", { h: 187, s: 76, l: 15, a: 0.14 }),
  },
  greyA7: {
    name: "greyA7",
    data: generateColorData("greyA7", { h: 187, s: 44, l: 21, a: 0.2 }),
  },
  greyA8: {
    name: "greyA8",
    data: generateColorData("greyA8", { h: 187, s: 44, l: 24, a: 0.44 }),
  },
  greyA9: {
    name: "greyA9",
    data: generateColorData("greyA9", { h: 187, s: 25, l: 24, a: 0.8 }),
  },
  greyA10: {
    name: "greyA10",
    data: generateColorData("greyA10", { h: 187, s: 29, l: 32, a: 0.7 }),
  },
  greyA11: {
    name: "greyA11",
    data: generateColorData("greyA11", { h: 187, s: 54, l: 11, a: 0.78 }),
  },
  greyA12: {
    name: "greyA12",
    data: generateColorData("greyA12", { h: 187, s: 76, l: 9, a: 1 }),
  },
  greyA13: {
    name: "greyA13",
    data: generateColorData("greyA13", { h: 187, s: 76, l: 9, a: 0.01 }),
  },
};

export default greyA;
