import _extends from "@babel/runtime/helpers/extends";
import { colorUseCases, getColorScaleFromThemeObject, theme } from "@vitality-ds/system";
var borders = colorUseCases.borders;
export default (function (baseStyles) {
  return _extends({}, baseStyles, {
    "&:not(:last-child)": {
      paddingBottom: theme.space.sm.value,
      borderBottom: "1px solid",
      borderColor: getColorScaleFromThemeObject("neutral", borders.uiElement, theme)
    }
  });
});