import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import { BaseTypography } from "../../Typography/styled";
var text = colorUseCases.text;
export default css({
  alignItems: "center",
  boxSizing: "border-box",
  gap: "$sm",
  color: getColorScaleValueByUseCase("neutral", text.hiContrast),
  width: "100%",
  display: "flex",
  justify: "between",
  variants: {
    overflowBehavior: {
      truncate: _defineProperty({}, "& ".concat(BaseTypography), {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        flexGrow: 1
      }),
      wrap: {
        whiteSpace: "break-spaces"
      }
    }
  }
});