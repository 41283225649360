import _extends from "@babel/runtime/helpers/extends";
import { css } from "@vitality-ds/system";
import { flexVariants, spacingVariants } from "../../helpers/styles";
export default css({
  boxSizing: "border-box",
  display: "flex",
  variants: _extends({}, flexVariants, spacingVariants, {
    /**
     * Define the direction of stacking. Under the hood, this sets the `flex-direction` CSS property. For more information: https://developer.mozilla.org/en-US/docs/Web/CSS/flex-direction
     */
    direction: {
      horizontal: {
        flexDirection: "row"
      },
      vertical: {
        flexDirection: "column"
      },
      horizontalReverse: {
        flexDirection: "row-reverse"
      },
      verticalReverse: {
        flexDirection: "column-reverse"
      }
    },
    /**
     * This Property is shorthand for width: 100% CSS property.
     */
    shouldFitContainer: {
      "true": {
        width: "100%"
      },
      "false": {
        width: "unset"
      }
    }
  })
});