import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _true;
import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import { BaseChipCheckbox } from "../components/Checkbox/styled";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds,
  borders = colorUseCases.borders;
export default css({
  $$indentation: "$space$sm",
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",
  gap: "$sm",
  color: getColorScaleValueByUseCase("neutral", text.hiContrast),
  backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement),
  border: "1px solid",
  borderLeftWidth: "$$indentation",
  borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement),
  borderRadius: "$rounded",
  paddingRight: "$$indentation",
  cursor: "default",
  fontSize: "$caption",
  whiteSpace: "break-spaces",
  svg: {
    marginTop: 0,
    // @todo need to review the random -mt on all icons
    flexShrink: 0
  },
  variants: {
    /**
     * Internal Variant, not to be exposed
     * @ignore
     */
    isInteractive: {
      "true": (_true = {
        cursor: "pointer"
      }, _defineProperty(_true, "&:hover:not(:has(".concat(BaseChipCheckbox, ":hover)):not(:active)"), {
        backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_hovered),
        borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_hovered)
      }), _defineProperty(_true, "&:focus", {
        backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_focused),
        borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_focused)
      }), _defineProperty(_true, "&:active", {
        backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_active)
      }), _true)
    },
    /**
     * The size variation of the Chip.
     */
    size: {
      "default": {
        paddingLeft: "$md",
        paddingBlock: 0,
        minHeight: 24
      },
      lg: {
        paddingLeft: "$md",
        paddingBlock: "$md"
      }
    },
    /**
     * Internal Variant, not to be exposed
     * @ignore
     */
    includeRightPadding: {
      "true": {
        paddingRight: "$md"
      }
    },
    /**
     * Internal Variant, not to be exposed
     * @ignore
     */
    reduceLeftPadding: {
      "true": {
        paddingLeft: "$sm"
      }
    },
    isDisabled: {
      // Empty object required for compountVariants to work
      "true": {
        cursor: "not-allowed",
        color: getColorScaleValueByUseCase("neutral", text.disabled),
        borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_disabled)
      }
    }
  }
});