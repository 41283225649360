import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { AVAILABLE_THEMES } from "@vitality-ds/system";
var setSavedTheme = function setSavedTheme(newMode) {
  try {
    if (typeof newMode === "string") window.localStorage.setItem("vitality-app-theme", newMode);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
};
var getSavedTheme = function getSavedTheme() {
  try {
    var savedMode = window.localStorage.getItem("vitality-app-theme");
    // If the user has explicitly chosen a theme,
    // let's use it. Otherwise, this value will be null.
    if (typeof savedMode === "string") return savedMode;
  } catch (e) {
    // When Chrome in incognito, localStorage cannot be accessed
    // eslint-disable-next-line no-console
    console.warn(e);
    return null;
  }
  return null;
};
var getMediaTheme = function getMediaTheme(defaultTheme) {
  // If they haven't been explicitly set, let's check the media query
  var mql = matchMedia("(prefers-color-scheme: dark)");
  var hasMediaQueryPreference = typeof mql.matches === "boolean";
  if (hasMediaQueryPreference) return mql.matches ? "dark" : "light";
  return defaultTheme;
};

// Set theme on the html tag
var setClassName = function setClassName(_ref) {
  var _htmlElement$classLis;
  var htmlElement = _ref.htmlElement,
    newTheme = _ref.newTheme;
  (_htmlElement$classLis = htmlElement.classList).remove.apply(_htmlElement$classLis, _toConsumableArray(Object.values(AVAILABLE_THEMES)));
  htmlElement.classList.add(AVAILABLE_THEMES[newTheme]);
};
var getNextClampedIndex = function getNextClampedIndex(list, currentIndex) {
  return (currentIndex + 1) % list.length;
};
export var setMediaListeners = function setMediaListeners(_ref2) {
  var htmlElement = _ref2.htmlElement,
    onChange = _ref2.onChange;
  // Listen out for if a user changes operating system mode,
  // but don't save the change in local storage.
  // The only two options here are dark or light.
  window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", function (e) {
    var newTheme = e.matches ? "dark" : "light";
    setClassName({
      htmlElement: htmlElement,
      newTheme: newTheme
    });
    onChange(newTheme);
  });
  window.matchMedia("(prefers-color-scheme: light)").addEventListener("change", function (e) {
    var newTheme = e.matches ? "light" : "dark";
    setClassName({
      htmlElement: htmlElement,
      newTheme: newTheme
    });
    onChange(newTheme);
  });
};
export var handleCycleTheme = function handleCycleTheme(htmlElement, theme, setTheme) {
  var keys = Object.keys(AVAILABLE_THEMES);
  var newIndex = getNextClampedIndex(keys, keys.indexOf(theme));
  var newTheme = keys[newIndex];
  setClassName({
    htmlElement: htmlElement,
    newTheme: newTheme
  });
  setTheme(newTheme);
  setSavedTheme(newTheme);
};
export var handleSetInitialState = function handleSetInitialState(htmlElement, setTheme, defaultTheme, themeOverride) {
  var savedTheme;
  if (themeOverride) {
    savedTheme = themeOverride;
  } else {
    // If no localStorage exists, use the user's OS setting
    savedTheme = getSavedTheme() || getMediaTheme(defaultTheme);
  }
  htmlElement.classList.add(AVAILABLE_THEMES[savedTheme]);
  setTheme(savedTheme);
};