import IconPrimitive from "@vitality-ds/icons";
import React from "react";

function Borders(props) {
  return (
    <IconPrimitive {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 3.034C2 2.464 2.463 2 3.034 2h17.931C21.537 2 22 2.463 22 3.034v17.931c0 .572-.463 1.035-1.035 1.035H3.035A1.035 1.035 0 012 20.965V3.035zM4.069 4.07v15.862h15.862V4.07H4.07z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M12.69 9.241a.69.69 0 10-1.38 0 .69.69 0 001.38 0zM12.69 6.483a.69.69 0 10-1.38 0 .69.69 0 001.38 0zM12.69 12a.69.69 0 10-1.38 0 .69.69 0 001.38 0zM9.931 12a.69.69 0 10-1.38 0 .69.69 0 001.38 0zM7.172 12a.69.69 0 10-1.379 0 .69.69 0 001.38 0zM15.448 12a.69.69 0 10-1.379 0 .69.69 0 001.38 0zM18.207 12a.69.69 0 10-1.38 0 .69.69 0 001.38 0zM12.69 14.759a.69.69 0 10-1.38 0 .69.69 0 001.38 0zM12.69 17.517a.69.69 0 10-1.38 0 .69.69 0 001.38 0z"
      />
    </IconPrimitive>
  );
}
export default Borders;
