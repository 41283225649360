import { keyframes } from "@vitality-ds/system";
export default keyframes({
  "0%": {
    transform: "rotate(0deg) scale(1)"
  },
  "50%": {
    transform: "rotate(10deg) scale(1.15)"
  },
  "100%": {
    transform: "rotate(0deg) scale(1)"
  }
});