import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const pink: ColorScale<"pink"> = {
  pink1: {
    name: "pink1",
    data: generateColorData("pink1", { h: 332, s: 100, l: 99, a: 1 }),
  },
  pink2: {
    name: "pink2",
    data: generateColorData("pink2", { h: 330, s: 100, l: 98, a: 1 }),
  },
  pink3: {
    name: "pink3",
    data: generateColorData("pink3", { h: 331, s: 85, l: 96, a: 1 }),
  },
  pink4: {
    name: "pink4",
    data: generateColorData("pink4", { h: 331, s: 78, l: 94, a: 1 }),
  },
  pink5: {
    name: "pink5",
    data: generateColorData("pink5", { h: 332, s: 72, l: 91, a: 1 }),
  },
  pink6: {
    name: "pink6",
    data: generateColorData("pink6", { h: 333, s: 67, l: 86, a: 1 }),
  },
  pink7: {
    name: "pink7",
    data: generateColorData("pink7", { h: 335, s: 63, l: 80, a: 1 }),
  },
  pink8: {
    name: "pink8",
    data: generateColorData("pink8", { h: 336, s: 62, l: 72, a: 1 }),
  },
  pink9: {
    name: "pink9",
    data: generateColorData("pink9", { h: 336, s: 80, l: 48, a: 1 }),
  },
  pink10: {
    name: "pink10",
    data: generateColorData("pink10", { h: 336, s: 73, l: 53, a: 1 }),
  },
  pink11: {
    name: "pink11",
    data: generateColorData("pink11", { h: 336, s: 75, l: 47, a: 1 }),
  },
  pink12: {
    name: "pink12",
    data: generateColorData("pink12", { h: 340, s: 65, l: 11, a: 1 }),
  },
  pink13: {
    name: "pink13",
    data: generateColorData("pink13", { h: 332, s: 100, l: 99, a: 1 }),
  },
};

export default pink;
