import { Add, Completed, Delete, Edit, GentuLogo, Link, LinkOff, Print, Restore, RestrictedOff, RestrictedOn, ResultsAndLetters, ReviewFiles, Send } from "@vitality-ds/icons";
export var PREPOSITIONS = {
  by: "by",
  to: "to",
  from: "from",
  "for": "for"
};

// dear future contributor the below is alphabetically ordered
export var ACTIONS = {
  complete: {
    actionName: "Completed",
    preposition: PREPOSITIONS.by,
    fallbackIcon: Completed,
    forceIcon: false
  },
  create: {
    actionName: "Created",
    preposition: PREPOSITIONS.by,
    fallbackIcon: Add,
    forceIcon: false
  },
  "delete": {
    actionName: "Deleted",
    preposition: PREPOSITIONS.by,
    fallbackIcon: Delete,
    forceIcon: false
  },
  edit: {
    actionName: "Edited",
    preposition: PREPOSITIONS.by,
    fallbackIcon: Edit,
    forceIcon: false
  },
  linkBy: {
    actionName: "Linked",
    preposition: PREPOSITIONS.by,
    fallbackIcon: Link,
    forceIcon: false
  },
  linkTo: {
    actionName: "Linked",
    preposition: PREPOSITIONS.to,
    fallbackIcon: Link,
    forceIcon: true
  },
  print: {
    actionName: "Printed",
    preposition: PREPOSITIONS.by,
    fallbackIcon: Print,
    forceIcon: true
  },
  receive: {
    actionName: "Received",
    preposition: PREPOSITIONS.by,
    fallbackIcon: ResultsAndLetters,
    forceIcon: false
  },
  remove: {
    actionName: "Removed",
    preposition: PREPOSITIONS.by,
    fallbackIcon: Delete,
    forceIcon: false
  },
  restrict: {
    actionName: "Restricted",
    preposition: PREPOSITIONS.by,
    fallbackIcon: RestrictedOn,
    forceIcon: false
  },
  restore: {
    actionName: "Restored",
    preposition: PREPOSITIONS.by,
    fallbackIcon: Restore,
    forceIcon: false
  },
  review: {
    actionName: "Reviewed",
    preposition: PREPOSITIONS.by,
    fallbackIcon: ReviewFiles,
    forceIcon: false
  },
  sendBy: {
    actionName: "Sent",
    preposition: PREPOSITIONS.by,
    fallbackIcon: Send,
    forceIcon: false
  },
  sendTo: {
    actionName: "Sent",
    preposition: PREPOSITIONS.to,
    fallbackIcon: Send,
    forceIcon: true
  },
  unlinkBy: {
    actionName: "Unlinked",
    preposition: PREPOSITIONS.by,
    fallbackIcon: LinkOff,
    forceIcon: false
  },
  unlinkFrom: {
    actionName: "Unlinked",
    preposition: PREPOSITIONS.from,
    fallbackIcon: LinkOff,
    forceIcon: true
  },
  unrestrict: {
    actionName: "Unrestricted",
    preposition: PREPOSITIONS.by,
    fallbackIcon: RestrictedOff,
    forceIcon: false
  },
  update: {
    actionName: "Updated",
    preposition: PREPOSITIONS.by,
    fallbackIcon: Edit,
    forceIcon: false
  },
  "void": {
    actionName: "Voided",
    preposition: PREPOSITIONS.by,
    fallbackIcon: Delete,
    forceIcon: false
  }
};
export var TITLES_TO_IGNORE = ["dr", "mr", "mrs", "ms", "miss", "prof", "mx", "dr.", "mr.", "mrs.", "ms.", "miss.", "prof.", "mx."];
export var APPLICATION_ICON_TRIGGERS = ["gentu"];
export var BRAND_ICONS = {
  gentu: GentuLogo
};