import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import { BORDER_WIDTH } from "../../TabsList/constants";
var text = colorUseCases.text,
  borders = colorUseCases.borders;
export default css({
  padding: "$md 0",
  backgroundColor: "transparent",
  fontFamily: "$display200",
  fontSize: "$display200",
  fontWeight: "$display200",
  lineHeight: "$display200",
  color: getColorScaleValueByUseCase("neutral", text.lowContrast),
  border: 0,
  borderBottom: "".concat(BORDER_WIDTH, "px solid transparent"),
  display: "flex",
  justifyContent: "center",
  flex: "1 1 auto",
  // for the bottom hairline border
  marginBottom: "-".concat(BORDER_WIDTH, "px"),
  path: {
    fill: "currentColor"
  },
  "&:hover": {
    cursor: "pointer",
    color: getColorScaleValueByUseCase("primary", text.hiSaturation)
  },
  "&:focus-visible": {
    outline: "none",
    boxShadow: "0 0 0 $space$outlineWidth ".concat(getColorScaleValueByUseCase("primary", borders.focusRing))
  },
  '&[data-state="active"]': {
    color: getColorScaleValueByUseCase("primary", text.hiSaturation),
    borderBottomColor: getColorScaleValueByUseCase("primary", borders.uiElement_active),
    "&[data-disabled]": {
      color: getColorScaleValueByUseCase("primary", text.disabled)
    }
  },
  "&[data-disabled]": {
    pointerEvents: "none",
    color: getColorScaleValueByUseCase("neutral", text.disabled)
  }
});