import { Typography } from "@vitality-ds/components";
import React from "react";

import DocsBox from "../../../../../DocsBox";
import DocsFlex from "../../../../../DocsFlex";
import { INPUT_WIDTH } from "../../constants";
import Result from "./components/Result";
import ResultGroup from "./components/ResultGroup";
import { ResultsProps } from "./types";

function Results({
  getListboxProps,
  getOptionProps,
  groupedOptions,
  query,
}: ResultsProps): JSX.Element {
  if (!query) return null;

  return (
    <DocsBox
      as="ul"
      css={{
        maxHeight: "50vh",
        overflowY: "auto",
        marginTop: "$xxl",
        backgroundColor: "$grey2",
        boxShadow: "$large",
        borderRadius: "$default",
        border: "1px solid $greyA7",
        position: "absolute",
        top: 4,
        left: -1,
        padding: "$md",
        width: INPUT_WIDTH,
        listStyleType: "none",
      }}
      {...getListboxProps()}
    >
      {groupedOptions.length > 0 ? (
        groupedOptions.map((optionGroup) => (
          <ResultGroup key={optionGroup.group} title={optionGroup.group}>
            {optionGroup.options.map((option, index) => (
              <Result
                key={option.id}
                page={option}
                {...getOptionProps({
                  option,
                  index: index + optionGroup.index,
                })}
              />
            ))}
          </ResultGroup>
        ))
      ) : (
        <DocsFlex css={{ margin: "$lg" }}>
          <Typography color="lowContrast">No results found.</Typography>
        </DocsFlex>
      )}
    </DocsBox>
  );
}

export default Results;
