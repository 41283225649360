import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
export default css({
  fontSize: "$caption",
  fontFamily: "$caption",
  lineHeight: "$caption",
  color: getColorScaleValueByUseCase("neutral", text.lowContrast),
  variants: {
    hasError: {
      "true": {
        color: getColorScaleValueByUseCase("critical", text.hiSaturation)
      }
    }
  }
});