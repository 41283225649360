import { styled } from "@vitality-ds/system";
import React from "react";

const Container = styled("div", {
  display: "flex",
  position: "absolute",
  justifyContent: "center",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  zIndex: -1,
  overflow: "hidden",
});

const GradientSwatch = styled("div", {
  position: "absolute",
  opacity: "0.1",
  variants: {
    color: {
      orange: {
        width: 442,
        height: 653,
        left: 590,
        top: 59,
        background:
          "radial-gradient(50% 50% at 50% 50%, #FF6200 0%, rgba(255, 98, 0, 0) 100%)",

        transform: "matrix(0.36, 0.86, -1.08, 0.07, 0, 0)",
      },
      cyan: {
        width: 896,
        height: 526,
        left: 101,
        top: -95,
        background:
          "radial-gradient(39.67% 39.67% at 50% 50%, #12A0B5 0.01%, rgba(18, 160, 181, 0) 100%) ",
        backgroundBlendMode: "screen",
        opacity: "0.2",
        filter: "blur(13.8111px)",
        transform: "matrix(0.97, 0.12, 0.23, 0.99, 0, 0)",
      },
      pink: {
        width: 950,
        height: 492,
        left: 0,
        top: -163,
        background:
          "radial-gradient(50% 50% at 50% 50%, #DC1867 0%, rgba(220, 24, 103, 0) 100%)",
        transform: "matrix(0.36, 0.86, -1.08, 0.07, 0, 0)",
      },
      blue: {
        width: 950,
        height: 492,
        left: 0,
        top: 22,
        background:
          "radial-gradient(50% 50% at 50% 50%, #0070F0 0%, rgba(0, 112, 240, 0) 100%)",
        backgroundBlendMode: "multiply",
        transform: "matrix(0.36, 0.86, -1.08, 0.07, 0, 0)",
      },
    },
  },
});

const Inner = styled("div", {
  position: "relative",
  width: 1061,
  height: 1096,
});

const Grid = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundImage:
    "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(148 163 184 / 0.05)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e\")",
  maskImage: "linear-gradient(to bottom, transparent, black)",
});

function BackgroundGradient({ showGrid }: { showGrid: boolean }) {
  return (
    <Container>
      <Inner>
        <GradientSwatch color="pink" />
        <GradientSwatch color="cyan" />
        <GradientSwatch color="blue" />
        <GradientSwatch color="orange" />
      </Inner>
      {showGrid && <Grid />}
    </Container>
  );
}

export default BackgroundGradient;
