import { colorUseCases, getColorScaleValueByUseCase } from "@vitality-ds/system";
var backgrounds = colorUseCases.backgrounds,
  text = colorUseCases.text;
function getVariantHoverStatesByColorScale(colorScale) {
  return {
    svg: {
      "&:hover": {
        background: getColorScaleValueByUseCase(colorScale, backgrounds.uiElement_hovered),
        color: getColorScaleValueByUseCase(colorScale, text.lowContrast)
      }
    }
  };
}
export default getVariantHoverStatesByColorScale;