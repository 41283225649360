import { Completed, Error, Info } from "@vitality-ds/icons";
var TOAST_TYPES = {
  success: {
    icon: Completed
  },
  warning: {
    icon: Error
  },
  error: {
    icon: Error
  },
  info: {
    icon: Info
  },
  general: {
    icon: Info
  }
};
export default TOAST_TYPES;