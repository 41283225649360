import { ALLOWED_CHARACTERS } from "./constants";

// eslint-disable-next-line import/prefer-default-export
export function format(value) {
  var formattedValue = value === null || value === void 0 ? void 0 : value.replace(ALLOWED_CHARACTERS, "");
  formattedValue = new Intl.NumberFormat("native", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(parseFloat(formattedValue));
  if (formattedValue === "NaN") {
    formattedValue = "";
  }
  return formattedValue;
}