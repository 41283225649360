import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import CHECKED_ANIMATION from "./BaseChipCheckbox.keyframes";
import { ANIMATION_DURATION } from "./BaseChipCheckboxIcon.styles";
var text = colorUseCases.text,
  borders = colorUseCases.borders,
  backgrounds = colorUseCases.backgrounds;
export default css({
  fontFamily: "$default",
  fontSize: "$caption",
  display: "flex",
  border: "1px solid",
  borderColor: getColorScaleValueByUseCase("neutralA", borders.uiElement),
  backgroundColor: getColorScaleValueByUseCase("neutralA", backgrounds.uiElement),
  color: getColorScaleValueByUseCase("neutralA", text.lowContrast),
  borderRadius: "$default",
  padding: "0 $xs",
  paddingRight: "$sm",
  gap: "$xs",
  alignItems: "center",
  marginLeft: "$xs",
  transition: "all 350ms",
  cursor: "pointer",
  "&:hover:not([disabled])": {
    borderColor: getColorScaleValueByUseCase("neutralA", borders.uiElement_hovered),
    backgroundColor: getColorScaleValueByUseCase("neutralA", backgrounds.uiElement_hovered),
    svg: {
      opacity: 0.5
    }
  },
  "&[disabled]": {
    cursor: "not-allowed",
    borderColor: getColorScaleValueByUseCase("neutralA", borders.uiElement_disabled),
    backgroundColor: getColorScaleValueByUseCase("neutralA", backgrounds.uiElement_disabled),
    color: getColorScaleValueByUseCase("neutralA", text.disabled)
  },
  /* todo this should be a checkbox primitive size variant? */
  button: {
    $$size: "14px"
  },
  variants: {
    state: {
      UNCHECKED: {},
      LOADING: {
        cursor: "not-allowed",
        color: getColorScaleValueByUseCase("neutralA", text.disabled)
      },
      CHECKED: {
        animation: "".concat(CHECKED_ANIMATION, " ").concat(ANIMATION_DURATION),
        backgroundColor: getColorScaleValueByUseCase("success", backgrounds.uiElement_selected),
        borderColor: "transparent",
        "&:hover": {
          borderColor: "transparent",
          backgroundColor: getColorScaleValueByUseCase("success", backgrounds.uiElement_hovered)
        },
        color: getColorScaleValueByUseCase("success", text.hiSaturation),
        "&[disabled]": {
          cursor: "not-allowed",
          borderColor: "transparent",
          backgroundColor: getColorScaleValueByUseCase("success", backgrounds.uiElement_disabled),
          color: getColorScaleValueByUseCase("success", text.disabled)
        }
      }
    }
  }
});