import { styled } from "@vitality-ds/system";

import DocsBox from "../DocsBox";
import { SIDEBAR_WIDTH } from "../Sidebar/constants";
import { FOOTER_HEIGHT } from "./constants";

const StyledContainer = styled(DocsBox, {
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  bottom: 0,
  height: FOOTER_HEIGHT,
  textAlign: "center",
  color: "$greyA12",
  backgroundColor: "transparent",
  left: 0,
  width: "100%",
  "@bp1": {
    right: SIDEBAR_WIDTH,
    left: SIDEBAR_WIDTH,
    width: "auto",
  },
});

const FooterContainer = styled(StyledContainer, {
  variants: {
    isHomePage: {
      true: {
        "@bp1": {
          right: 0,
          left: 0,
          width: "100%",
        },
      },
    },
  },
});

export default FooterContainer;
