import { styled } from "@vitality-ds/system";
import BaseToolbarStyles from "./styles/BaseToolbarContainer.styles";
import LeftActionsContainerStyles from "./styles/LeftActionsContainer.styles";
import LeftSideContainerStyles from "./styles/LeftSideContainer.styles";
import RightActionsContainerStyles from "./styles/RightActionsContainer.styles";
import TitleContainerStyles from "./styles/TitleContainer.styles";
export var BaseToolbar = styled("div", BaseToolbarStyles);
export var TitleContainer = styled("div", TitleContainerStyles);
export var LeftActionsContainer = styled("div", LeftActionsContainerStyles);
export var RightActionsContainer = styled("div", RightActionsContainerStyles);
export var LeftSideContainer = styled("div", LeftSideContainerStyles);