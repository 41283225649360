import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import SearchField from "./components/SearchField";

function Search(): JSX.Element {
  const data = useStaticQuery(graphql`
    query SearchIndexQuery {
      siteSearchIndex {
        index
      }
    }
  `);
  return <SearchField searchIndex={data.siteSearchIndex.index} />;
}

export default Search;
