import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
export default css({
  border: 0,
  paddingInline: "$sm",
  flexGrow: 1,
  width: "100%",
  fontSize: "$textInput",
  fontFamily: "$textInput",
  lineHeight: "$textInput",
  fontWeight: "$textInput",
  color: getColorScaleValueByUseCase("neutral", text.hiContrast),
  backgroundColor: "transparent",
  "&:focus-visible": {
    outline: "none"
  },
  variants: {
    textAlign: {
      left: {
        textAlign: "left"
      },
      right: {
        textAlign: "right"
      }
    }
  },
  "&[disabled]": {
    color: getColorScaleValueByUseCase("neutral", text.disabled)
  },
  "&::placeholder": {
    color: getColorScaleValueByUseCase("neutral", text.placeholder)
  }
});