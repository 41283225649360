import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var backgrounds = colorUseCases.backgrounds;
export default css({
  backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.card),
  borderRadius: "$large",
  variants: {
    elevation: {
      sm: {
        boxShadow: "$sm"
      },
      md: {
        boxShadow: "$md"
      },
      lg: {
        boxShadow: "$lg"
      },
      none: {
        boxShadow: "none"
      }
    },
    padding: {
      xs: {
        padding: "$xs"
      },
      sm: {
        padding: "$sm"
      },
      md: {
        padding: "$md"
      },
      lg: {
        padding: "$lg"
      },
      xl: {
        padding: "$xl"
      },
      xxl: {
        padding: "$xxl"
      },
      none: {
        padding: "none"
      }
    }
  }
});