import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const grey: ColorScale<"grey"> = {
  grey1: {
    name: "grey1",
    data: generateColorData("grey1", { h: 187, s: 10, l: 8, a: 1 }),
  },
  grey2: {
    name: "grey2",
    data: generateColorData("grey2", { h: 187, s: 15, l: 11, a: 1 }),
  },
  grey3: {
    name: "grey3",
    data: generateColorData("grey3", { h: 187, s: 15, l: 13, a: 1 }),
  },
  grey4: {
    name: "grey4",
    data: generateColorData("grey4", { h: 187, s: 15, l: 15, a: 1 }),
  },
  grey5: {
    name: "grey5",
    data: generateColorData("grey5", { h: 187, s: 15, l: 17, a: 1 }),
  },
  grey6: {
    name: "grey6",
    data: generateColorData("grey6", { h: 187, s: 15, l: 20, a: 1 }),
  },
  grey7: {
    name: "grey7",
    data: generateColorData("grey7", { h: 187, s: 15, l: 24, a: 1 }),
  },
  grey8: {
    name: "grey8",
    data: generateColorData("grey8", { h: 187, s: 15, l: 31, a: 1 }),
  },
  grey9: {
    name: "grey9",
    data: generateColorData("grey9", { h: 187, s: 15, l: 43, a: 1 }),
  },
  grey10: {
    name: "grey10",
    data: generateColorData("grey10", { h: 187, s: 15, l: 49, a: 1 }),
  },
  grey11: {
    name: "grey11",
    data: generateColorData("grey11", { h: 192, s: 23, l: 74, a: 1 }),
  },
  grey12: {
    name: "grey12",
    data: generateColorData("grey12", { h: 187, s: 15, l: 93, a: 1 }),
  },
  grey13: {
    name: "grey13",
    data: generateColorData("grey13", { h: 187, s: 15, l: 99, a: 1 }),
  },
};

export default grey;
