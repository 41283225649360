import { css } from "@vitality-ds/system";
import { getColorVariant } from "./helpers";
export default css({
  padding: "$lg",
  borderRadius: "$large",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "$md",
  width: "100%",
  boxSizing: "border-box",
  variants: {
    severity: {
      info: {},
      warning: {},
      critical: {},
      success: {}
    },
    size: {
      compact: {
        backgroundColor: "transparent",
        padding: 0
      },
      "default": {}
    }
  },
  compoundVariants: [{
    size: "default",
    severity: "warning",
    css: getColorVariant("warning")
  }, {
    size: "default",
    severity: "success",
    css: getColorVariant("success")
  }, {
    size: "default",
    severity: "info",
    css: getColorVariant("info")
  }, {
    size: "default",
    severity: "critical",
    css: getColorVariant("critical")
  }]
});