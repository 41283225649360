import { TIME_ENTER, TOAST_STATES } from "../../constants";
var animationEasingFunction = "cubic-bezier(0.45, 0, 0.55, 1)";
export var getContainerAnimationStyles = function getContainerAnimationStyles(toastState) {
  var height = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 76;
  return {
    maxHeight: toastState > TOAST_STATES.display ? 0 : "".concat(height + 8, "px"),
    paddingTop: toastState > TOAST_STATES.display ? 0 : "8px",
    transition: "all 0.3s ".concat(animationEasingFunction),
    position: "relative",
    zIndex: toastState > TOAST_STATES.display ? 1 : 10
  };
};
export var getToastAnimationStyles = function getToastAnimationStyles(toastState) {
  return {
    transform: "translate(".concat(toastState > TOAST_STATES.pending ? "-10px" : "500px", ", ").concat(toastState <= TOAST_STATES.display ? "0px" : "-500px", ")"),
    transition: "transform ".concat(TIME_ENTER / 1000, "s ").concat(animationEasingFunction)
  };
};