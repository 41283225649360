import { FIGMA_FRIENDLY_FONT_NAMES } from "./constants";
import { FontFamilyTokens } from "./types";

const FontFamilies: FontFamilyTokens = {
  web: {
    sans: {
      name: "sans",
      description: "Sans-serif font used for all UI text.",
      data: {
        fontNameFigma: FIGMA_FRIENDLY_FONT_NAMES.standard,
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      },
    },
    sansDisplay: {
      name: "sans-display",
      description: "Display variant of sans-serif font.",
      data: {
        fontNameFigma: FIGMA_FRIENDLY_FONT_NAMES.display,
        fontFamily:
          '-apple-system-headline, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      },
    },
  },
  reactNative: {
    sans: {
      name: "sans",
      description: "Sans-serif font used for all UI text.",
      data: {
        fontNameFigma: FIGMA_FRIENDLY_FONT_NAMES.standard,
        fontFamily: "System",
      },
    },
    sansDisplay: {
      name: "sans-display",
      description: "Display variant of sans-serif font.",
      data: {
        fontNameFigma: FIGMA_FRIENDLY_FONT_NAMES.display,
        fontFamily: "System",
      },
    },
  },
};

export default FontFamilies;
