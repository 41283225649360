import React from "react";

import DocsFlex from "../../../DocsFlex";
import Search from "../../../Search";
import Container from "../Container";
import Inner from "../Inner";
import Logo from "../StandardHeader/components/Logo";
import ThemeToggleButton from "../ThemeToggleButton";

function HomePageHeader(): JSX.Element {
  return (
    <Container
      css={{
        border: 0,
        backgroundColor: "transparent",
        top: "$lg",
      }}
    >
      <Inner css={{ gap: "$sm" }}>
        <Logo />
        <DocsFlex align="center" spacing="sm">
          <Search />
          <ThemeToggleButton />
        </DocsFlex>
      </Inner>
    </Container>
  );
}

export default HomePageHeader;
