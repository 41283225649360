import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import CONFIG from "../constants";
var text = colorUseCases.text;
export default css({
  position: "absolute",
  left: 0,
  width: CONFIG.THUMB,
  height: CONFIG.THUMB,
  borderRadius: "$rounded",
  transition: "transform ".concat(CONFIG.TRANSITION_DURATION_MS, "ms ").concat(CONFIG.TRANSITION_TIMING_FUNCTION),
  transform: "translateX(".concat(CONFIG.INSET_SPACING, "px)"),
  willChange: "transform",
  backgroundColor: getColorScaleValueByUseCase("neutral", text.onSolidBackgrounds),
  "&[data-disabled]": {
    backgroundColor: getColorScaleValueByUseCase("neutral", text.onSolidBackgrounds)
  },
  variants: {
    checked: {
      "true": {
        transform: "translateX(".concat(CONFIG.TRACK_WIDTH - CONFIG.THUMB - CONFIG.INSET_SPACING, "px)"),
        "&[data-disabled]": {
          backgroundColor: getColorScaleValueByUseCase("primary", text.disabled)
        }
      }
    }
  }
});