import { css } from "@vitality-ds/system";
import { getHighImportanceVariant, getLowImportanceVariant, getNormalImportanceVariant } from "./helpers";
export default css({
  fontFamily: "$caption",
  fontSize: "$caption",
  fontWeight: "$caption",
  lineHeight: "$caption",
  borderRadius: "$default",
  textDecoration: "none",
  userSelect: "none",
  padding: "$sm",
  cursor: "default",
  display: "inline-flex",
  gap: "$sm",
  alignItems: "center",
  variants: {
    interactive: {
      "true": {
        fontWeight: "$display100",
        "&:hover": {
          cursor: "pointer",
          "a&": {
            textDecoration: "underline"
          }
        },
        "&:active": {
          transform: "translateY(1px)"
        }
      }
    },
    /**
     * Empty objects on purpose to preserve variant keys used in compound variants
     */
    importance: {
      normal: {},
      low: {},
      high: {}
    },
    severity: {
      neutral: {},
      success: {},
      warning: {},
      critical: {},
      info: {},
      accent: {}
    }
  },
  compoundVariants: [
  /**
   * Normal Importance
   */
  {
    importance: "normal",
    severity: "neutral",
    css: getNormalImportanceVariant("neutral")
  }, {
    importance: "normal",
    severity: "success",
    css: getNormalImportanceVariant("success")
  }, {
    importance: "normal",
    severity: "warning",
    css: getNormalImportanceVariant("warning")
  }, {
    importance: "normal",
    severity: "neutral",
    css: getNormalImportanceVariant("neutral")
  }, {
    importance: "normal",
    severity: "critical",
    css: getNormalImportanceVariant("critical")
  }, {
    importance: "normal",
    severity: "info",
    css: getNormalImportanceVariant("info")
  }, {
    importance: "normal",
    severity: "accent",
    css: getNormalImportanceVariant("accent")
  },
  /**
   * Low Importance
   */
  {
    importance: "low",
    severity: "neutral",
    css: getLowImportanceVariant("neutral")
  }, {
    importance: "low",
    severity: "success",
    css: getLowImportanceVariant("success")
  }, {
    importance: "low",
    severity: "warning",
    css: getLowImportanceVariant("warning")
  }, {
    importance: "low",
    severity: "neutral",
    css: getLowImportanceVariant("neutral")
  }, {
    importance: "low",
    severity: "critical",
    css: getLowImportanceVariant("critical")
  }, {
    importance: "low",
    severity: "info",
    css: getLowImportanceVariant("info")
  }, {
    importance: "low",
    severity: "accent",
    css: getLowImportanceVariant("accent")
  },
  /**
   * High Importance
   */
  {
    importance: "high",
    severity: "neutral",
    css: getHighImportanceVariant("neutral")
  }, {
    importance: "high",
    severity: "success",
    css: getHighImportanceVariant("success")
  }, {
    importance: "high",
    severity: "warning",
    css: getHighImportanceVariant("warning")
  }, {
    importance: "high",
    severity: "neutral",
    css: getHighImportanceVariant("neutral")
  }, {
    importance: "high",
    severity: "critical",
    css: getHighImportanceVariant("critical")
  }, {
    importance: "high",
    severity: "info",
    css: getHighImportanceVariant("info")
  }, {
    importance: "high",
    severity: "accent",
    css: getHighImportanceVariant("accent")
  }]
});