import { Stack, Typography } from "@vitality-ds/components";
import React from "react";

import SidebarItem from "./components/SidebarItem";
import { Container } from "./styled";
import { SidebarSectionProps } from "./types";

function SidebarSection({
  sectionTitle,
  data,
}: SidebarSectionProps): JSX.Element {
  return (
    <Container>
      <Stack>
        <Typography variant="display200">{sectionTitle}</Typography>
        <div>
          {data.edges.map(({ node }, index) => (
            <SidebarItem key={node.fields.slug} index={index} node={node} />
          ))}
        </div>
      </Stack>
    </Container>
  );
}

export default SidebarSection;
