import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var backgrounds = colorUseCases.backgrounds,
  borders = colorUseCases.borders;
export default css({
  $$size: "10px",
  width: "$$size",
  height: "$$size",
  borderRadius: "$default",
  border: "1px solid",
  borderColor: getColorScaleValueByUseCase("neutralA", borders.uiElement),
  backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement),
  "&:hover": {
    backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_hovered)
  }
});