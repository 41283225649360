import { css } from "@vitality-ds/system";
export default css({
  paddingRight: 0,
  variants: {
    indentContent: {
      "true": {
        paddingLeft: "$xl"
      }
    }
  }
});