import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds;
export default css({
  width: 288,
  ".react-calendar": {
    "&__month-view": {
      "&__weekdays": {
        marginBottom: "$md",
        color: getColorScaleValueByUseCase("neutral", text.lowContrast),
        "&__weekday": {
          display: "flex",
          justifyContent: "center",
          textTransform: "uppercase",
          fontFamily: "$display200",
          fontWeight: "$display200",
          fontSize: "$display200",
          abbr: {
            textDecoration: "none"
          }
        }
      },
      "&__days": {
        "&__day": {
          color: getColorScaleValueByUseCase("neutral", text.hiContrast),
          "&--neighboringMonth": {
            color: getColorScaleValueByUseCase("neutral", text.disabled),
            "&:hover": {
              backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_hovered),
              color: getColorScaleValueByUseCase("neutral", text.lowContrast)
            },
            "&:active": {
              backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElement_active)
            }
          }
        }
      }
    },
    "&__tile": {
      height: 32,
      backgroundColor: "transparent",
      border: "none",
      fontSide: "$body",
      borderRadius: "$default",
      fontWeight: "$body",
      "&:hover": {
        backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_hovered)
      },
      "&:active": {
        backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElement_active)
      },
      "&--now": {
        fontWeight: "$display100",
        color: getColorScaleValueByUseCase("primary", text.hiSaturation),
        $$underlineColor: getColorScaleValueByUseCase("primary", text.hiSaturation),
        backgroundImage: "linear-gradient(to right, $$underlineColor, $$underlineColor)",
        backgroundPosition: "4px 26px",
        backgroundRepeat: "no-repeat",
        backgroundClip: "content-box",
        backgroundSize: "32px 2px",
        "&:hover": {
          backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_hovered),
          color: getColorScaleValueByUseCase("neutral", text.hiContrast)
        },
        "&:active": {
          backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElement_active),
          color: getColorScaleValueByUseCase("neutral", text.hiContrast)
        }
      },
      "&--hover": {
        backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElement_hovered),
        color: getColorScaleValueByUseCase("neutral", text.hiContrast),
        borderRadius: "0"
      },
      "&--hoverEnd": {
        borderRadius: "0 $default $default 0",
        "&:hover": {
          backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_hovered),
          color: getColorScaleValueByUseCase("neutral", text.onSolidBackgrounds)
        }
      },
      "&--active, &--hasActive": {
        color: getColorScaleValueByUseCase("primary", text.onSolidBackgrounds),
        $$underlineColor: getColorScaleValueByUseCase("primary", text.hiContrast),
        backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_selected),
        borderRadius: "$default",
        "&:hover": {
          backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_hovered),
          color: getColorScaleValueByUseCase("primary", text.onSolidBackgrounds)
        }
      },
      "&--hoverStart": {
        borderRadius: "$default 0 0 $default"
      },
      "&--range": {
        color: getColorScaleValueByUseCase("primary", text.hiContrast),
        borderRadius: "0",
        backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElement_focused)
      },
      "&--rangeStart": {
        color: getColorScaleValueByUseCase("primary", text.onSolidBackgrounds),
        borderRadius: "$default 0 0 $default",
        backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_selected)
      },
      "&--rangeEnd": {
        color: getColorScaleValueByUseCase("primary", text.onSolidBackgrounds),
        borderRadius: "0 $default $default 0",
        backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_selected)
      },
      "&--rangeBothEnds": {
        borderRadius: "$default"
      }
    }
  }
});