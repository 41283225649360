import _extends from "@babel/runtime/helpers/extends";
import { colorUseCases, getColorScaleFromThemeObject, theme } from "@vitality-ds/system";
var text = colorUseCases.text;
export default (function (baseStyles) {
  return _extends({}, baseStyles, {
    color: getColorScaleFromThemeObject("neutral", text.lowContrast, theme),
    fontSize: theme.fontSizes.body.value,
    fontStyle: theme.fonts.body.value,
    lineHeight: theme.lineHeights.body.value,
    padding: "".concat(theme.space.sm.value, " ").concat(theme.space.md.value)
  });
});