import { Typography } from "@vitality-ds/components";
import React from "react";

import Container from "./styled";
import { ResultGroupProps } from "./types";

function ResultGroup({
  children,
  title,
}: React.PropsWithChildren<ResultGroupProps>) {
  return (
    <Container>
      <Typography variant="caption">
        <strong>{title}</strong>
      </Typography>
      {children}
    </Container>
  );
}

export default ResultGroup;
