import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";
import { getTooltipContents } from "../logic";
var useCalendar = function useCalendar(minYear, maxYear, state) {
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    hoverValue = _useState2[0],
    setHoverValue = _useState2[1];
  var handleMouseOver = function handleMouseOver(newHover) {
    setHoverValue(newHover);
  };
  var _getTooltipContents = getTooltipContents(state.selectedMonth, state.selectedYear, minYear, maxYear),
    prevMonthTooltip = _getTooltipContents.prevMonthTooltip,
    nextMonthTooltip = _getTooltipContents.nextMonthTooltip;
  return {
    hoverValue: hoverValue,
    handleMouseOver: handleMouseOver,
    prevMonthTooltip: prevMonthTooltip,
    nextMonthTooltip: nextMonthTooltip
  };
};
export default useCalendar;