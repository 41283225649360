import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var backgrounds = colorUseCases.backgrounds;
export default css({
  svg: {
    height: 80,
    "& .illustrationFill": {
      fill: getColorScaleValueByUseCase("primary", backgrounds.illustration_fill)
    },
    "& .illustrationStroke": {
      fill: getColorScaleValueByUseCase("primary", backgrounds.illustration_stroke)
    }
  }
});