import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
export default css({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  marginTop: "$md",
  fontFamily: "$caption",
  fontSize: "$caption",
  fontWeight: "$caption",
  lineHeight: "$caption",
  color: getColorScaleValueByUseCase("neutral", text.lowContrast)
});