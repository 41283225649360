import { css } from "@vitality-ds/system";
import { TRANSITION_DURATION } from "../../../contexts/SidebarContext/constants";
import { SIDEBAR_WIDTH } from "../../SidebarBar/constants";
export default css({
  position: "relative",
  transition: "margin-right ".concat(TRANSITION_DURATION, "ms ease"),
  variants: {
    isOpen: {
      "true": {
        // below bp1, the wrapper does not add a margin, so the sidebar presents more like a drawer
        marginRight: "unset",
        "@bp1": {
          // from bp1 and above, our wrapper adds a margin to slide app content over.
          marginRight: SIDEBAR_WIDTH
        }
      }
    }
  }
});