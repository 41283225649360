import _extends from "@babel/runtime/helpers/extends";
import { css } from "@vitality-ds/system";
import { flexVariants, layoutVariants, spacingVariants } from "../../helpers/styles";
export default css({
  boxSizing: "border-box",
  display: "flex",
  variants: _extends({}, layoutVariants, spacingVariants, flexVariants, {
    wrap: {
      noWrap: {
        flexWrap: "nowrap"
      },
      wrap: {
        flexWrap: "wrap"
      },
      wrapReverse: {
        flexWrap: "wrap-reverse"
      }
    }
  })
});