import colorUseCases from "./constants";

const appendUseCaseToStep = (useCase, currentValue = []) => [
  ...currentValue,
  useCase,
];

const getUseCasesByStep = (useCases: typeof colorUseCases) =>
  Object.entries(useCases).reduce((steps, [useCaseGroupKey, useCaseGroup]) => {
    const newSteps = {
      ...steps,
    };
    Object.entries(useCaseGroup).forEach(([useCase, step]) => {
      if (!newSteps[step]) {
        newSteps[step] = {};
      }
      newSteps[step][useCaseGroupKey] = appendUseCaseToStep(
        useCase,
        newSteps[step][useCaseGroupKey]
      );
    });
    return newSteps;
  }, {});

export default getUseCasesByStep;
