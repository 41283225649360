import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { createStitches } from "@stitches/react";
import { getTheme } from "@vitality-ds/tokens";
var _getTheme = getTheme("web"),
  colors = _getTheme.colors,
  baseTheme = _objectWithoutProperties(_getTheme, ["colors"]);
export var stitches = createStitches({
  // To scope all CSS Vars eg. `--vitality-foo`
  prefix: "vitality",
  theme: _extends({}, baseTheme, {
    colors: colors.light
  }),
  media: {
    bp1: "(min-width: 48rem)",
    bp2: "(min-width: 69rem)",
    bp3: "(min-width: 85rem)"
  }
});
var styled = stitches.styled,
  css = stitches.css,
  theme = stitches.theme,
  getCssText = stitches.getCssText,
  globalCss = stitches.globalCss,
  keyframes = stitches.keyframes,
  config = stitches.config,
  createTheme = stitches.createTheme;
export { styled, css, theme, getCssText, globalCss, keyframes, config, createTheme };
export var darkTheme = createTheme({
  colors: colors.dark
});