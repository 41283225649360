import { Link, Stack, Typography } from "@vitality-ds/components";
import React from "react";

import MagentusLogo from "../MagentusLogo";
import FooterContainer from "./styled";
import { FooterProps } from "./types";

function Footer({ isHomePage }: FooterProps): JSX.Element {
  return (
    <FooterContainer as="footer" isHomePage={isHomePage}>
      <Stack direction="horizontal" align="center" justify="center">
        <Typography variant="body" color="lowContrast">
          <strong>© {new Date().getFullYear()} </strong>
        </Typography>
        <Link color="lowContrast" href="https://www.magentus.com">
          <MagentusLogo width={120} style={{ verticalAlign: "middle" }} />
        </Link>
      </Stack>
    </FooterContainer>
  );
}

export default Footer;
