import { ElevationToken } from "../../Elevation/types";
import appendPx from "./appendPx";

/**
 * Creates a CSS-compliant box shadow value based on the design token input
 * @returns string
 */
export default (elevation: ElevationToken["data"]): string => {
  const output: string[] = [];
  elevation.forEach((item) => {
    const { color, offset, radius = 0, spread = 0 } = item;
    const { r, g, b, a } = color;
    const { x, y } = offset;

    output.push(
      `${appendPx(x)} ${appendPx(y)} ${appendPx(radius)} ${appendPx(
        spread
      )} rgba(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(
        b * 255
      )}, ${a})`
    );
  });
  return output.join(", ");
};
