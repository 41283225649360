import {
  colorUseCases,
  getColorScaleValueByUseCase,
  styled,
} from "@vitality-ds/system";

const { backgrounds, text, borders } = colorUseCases;

// eslint-disable-next-line import/prefer-default-export
export const SidebarLink = styled("div", {
  textDecoration: "none",
  lineHeight: "$body",
  display: "block",
  fontSize: "$body",
  color: getColorScaleValueByUseCase("neutral", text.lowContrast),
  borderLeft: `1px solid ${getColorScaleValueByUseCase(
    "neutral",
    borders.subtle
  )}`,
  padding: "6px $lg",
  "&::selection": {
    background: getColorScaleValueByUseCase(
      "primary",
      backgrounds.uiElement_active
    ),
  },
  "&:hover": {
    color: getColorScaleValueByUseCase("neutral", text.hiContrast),
    borderLeftColor: getColorScaleValueByUseCase(
      "primary",
      borders.uiElement_hovered
    ),
  },
  "&:focus-visible": {
    boxShadow: `0 0 0 $space$outlineWidth ${getColorScaleValueByUseCase(
      "primary",
      borders.uiElement_focused
    )} inset`,
    outline: "none",
  },
  "&.active": {
    color: getColorScaleValueByUseCase("primary", text.hiSaturation),
    borderLeftColor: "$primary9",
    fontWeight: "$display200",
  },
  "&:active": {
    borderRadius: "0 $default 0",
    backgroundColor: getColorScaleValueByUseCase(
      "primary",
      backgrounds.surface
    ),
  },
});
