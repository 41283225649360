import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var backgrounds = colorUseCases.backgrounds;
export default css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  position: "relative",
  "&::after": {
    content: '""',
    $$size: "8px",
    display: "block",
    width: "$$size",
    height: "$$size",
    borderRadius: "$rounded",
    backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid)
  },
  variants: {
    disabled: {
      "true": {
        "&::after": {
          backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElementSolid_disabled)
        }
      },
      "false": {
        "&:hover::after": {
          backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_hovered)
        },
        "&:active::after": {
          backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_active)
        }
      }
    }
  }
});