import React from "react";
import IconPrimitive from "@vitality-ds/icons";

const TopSpeed = (props) => (
  <IconPrimitive {...props}>
    <path
      d="M10.05 15.42l6.256-8.475a.694.694 0 011.235.57l-.03.098-3.87 9.799a2.07 2.07 0 11-3.737-1.765l.069-.116.076-.11 6.257-8.476-6.257 8.476zm8.56-8.006a10.654 10.654 0 012.022 2.172c.524.749 1.03 1.656 1.32 2.398a.75.75 0 11-1.397.547 8.26 8.26 0 00-.377-.812l-2.05 1.183a.75.75 0 01-.835-1.242l.085-.057 2.018-1.166-.23-.314a9.163 9.163 0 00-1.058-1.16l.38-.964c.038-.096.067-.194.087-.292l.024-.147.01-.146zm-2.63-1.561a1.713 1.713 0 00-.406.328l-.114.14-.54.733a9.204 9.204 0 00-2.17-.47v2.672a.75.75 0 01-1.493.102l-.007-.102v-2.69A9.108 9.108 0 006.659 8.2c-.816.572-1.527 1.322-2.119 2.205l2.082 1.202a.75.75 0 01-.658 1.344l-.092-.045-2.074-1.197c-.128.266-.246.54-.355.821a.75.75 0 01-1.399-.543C2.851 9.91 4.124 8.143 5.8 6.97A10.642 10.642 0 0115.98 5.853z"
      fill="currentColor"
    />
  </IconPrimitive>
);

export default TopSpeed;
