import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var backgrounds = colorUseCases.backgrounds,
  text = colorUseCases.text;
export default css({
  $$size: "18px",
  $$dotSize: "$space$md",
  fontFamily: "$default",
  boxSizing: "border-box",
  display: "flex",
  whiteSpace: "nowrap",
  borderRadius: "$radii$rounded",
  minWidth: "$$size",
  height: "$$size",
  fontSize: "$caption",
  alignItems: "center",
  justifyContent: "center",
  color: getColorScaleValueByUseCase("accent", text.onSolidBackgrounds),
  padding: 4,
  variants: {
    inline: {
      "true": {
        position: "relative",
        transform: "inherit"
      },
      "false": {
        position: "absolute"
      }
    },
    variant: {
      dot: {
        minWidth: "$$dotSize",
        width: "$$dotSize",
        height: "$$dotSize",
        boxShadow: "0 0 0 2px ".concat(getColorScaleValueByUseCase("neutral", backgrounds.app))
      }
    },
    color: {
      accent: {
        background: getColorScaleValueByUseCase("accent", backgrounds.uiElementSolid)
      },
      neutral: {
        background: getColorScaleValueByUseCase("neutral", backgrounds.uiElementSolid),
        color: getColorScaleValueByUseCase("neutral", text.onSolidBackgrounds)
      }
    },
    position: {
      topLeft: {
        right: "100%",
        top: 0,
        transformOrigin: "left center",
        transform: "translate(calc($$size/2), -50%)"
      },
      topRight: {
        left: "100%",
        top: 0,
        transform: "translate(calc($$size/-2), -50%)"
      },
      bottomLeft: {
        right: "100%",
        bottom: 0,
        transform: "translate(calc($$size/2), 50%)"
      },
      bottomRight: {
        left: "100%",
        bottom: 0,
        transform: "translate(calc($$size/-2), 50%)"
      }
    }
  },
  compoundVariants: [{
    variant: "dot",
    position: "topLeft",
    css: {
      $$size: "$$dotSize"
    }
  }, {
    variant: "dot",
    position: "topRight",
    css: {
      $$size: "$$dotSize"
    }
  }, {
    variant: "dot",
    position: "bottomLeft",
    css: {
      $$size: "$$dotSize"
    }
  }, {
    variant: "dot",
    position: "bottomRight",
    css: {
      $$size: "$$dotSize"
    }
  }]
});