// eslint-disable-next-line import/prefer-default-export
export var APPEARANCES = {
  standard: {
    color: "neutral",
    variant: "subtle",
    spinnerColor: null
  },
  onSurface: {
    color: "neutral",
    variant: "onSurface",
    spinnerColor: null
  },
  primary: {
    color: "primary",
    variant: "solid",
    spinnerColor: "primary"
  },
  destructive: {
    color: "critical",
    variant: "solid",
    spinnerColor: "critical"
  },
  warning: {
    color: "warning",
    variant: "solid",
    spinnerColor: "warning"
  },
  ghost: {
    color: "neutral",
    variant: "ghost",
    spinnerColor: null
  },
  link: {
    color: "primary",
    variant: "link",
    spinnerColor: null
  }
};