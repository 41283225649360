import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _extends from "@babel/runtime/helpers/extends";
import { EMPTY_OBJ } from "../helpers/logic/empties";
import { MAX_TOASTS, TIME_DISPLAY, TIME_ENTER, TIME_EXIT, TIME_WAIT, TOAST_STATES } from "./constants";
export var getInitialState = function getInitialState() {
  return {
    toasts: []
  };
};
export var selectGetToastById = function selectGetToastById(state) {
  return function (toastId) {
    return state.toasts.find(function (t) {
      return t.id === toastId;
    });
  };
};
export var selectDeadToasts = function selectDeadToasts(state) {
  return state.toasts.filter(function (t) {
    return t.state === TOAST_STATES.dead;
  });
};
export var syncToasts = function syncToasts(toasts, dateNow) {
  return function (state) {
    var newToasts = toasts.filter(function (t) {
      return !state.toasts.find(function (st) {
        return st.id === t.id;
      });
    }).map(function (t) {
      return _extends({}, t, {
        options: t.options || EMPTY_OBJ,
        startTime: dateNow,
        stateTime: dateNow,
        state: TOAST_STATES.pending,
        hovering: false
      });
    });
    var activeToasts = state.toasts.filter(function (st) {
      return toasts.findIndex(function (t) {
        return t.id === st.id;
      }) >= 0;
    });
    return _extends({}, state, {
      toasts: [].concat(_toConsumableArray(activeToasts), _toConsumableArray(newToasts))
    });
  };
};
export var updateToast = function updateToast(toast) {
  return function (state) {
    var toastIndex = state.toasts.findIndex(function (t) {
      return t.id === toast.id;
    });
    if (toastIndex === -1) {
      return state;
    }
    var updatedToasts = [].concat(_toConsumableArray(state.toasts.slice(0, toastIndex)), [toast], _toConsumableArray(state.toasts.slice(toastIndex + 1)));
    return _extends({}, state, {
      toasts: updatedToasts
    });
  };
};
export var transitToast = function transitToast(toastId, dateNow, newState) {
  return function (state) {
    var toast = selectGetToastById(state)(toastId);
    if (toast) {
      return updateToast(_extends({}, toast, {
        stateTime: dateNow,
        state: newState
      }))(state);
    }
    return state;
  };
};
export var removeToast = function removeToast(toastId, dateNow) {
  return function (state) {
    return transitToast(toastId, dateNow, TOAST_STATES.exit)(state);
  };
};
export var setHover = function setHover(toastId, isOver, dateNow) {
  return function (state) {
    var toast = selectGetToastById(state)(toastId);
    if (!toast || toast.hovering === isOver) {
      return state;
    }
    return updateToast(_extends({}, toast, {
      hovering: isOver,
      stateTime: dateNow
    }))(state);
  };
};
export var updateToastStates = function updateToastStates(dateNow) {
  return function (state) {
    return state.toasts.reduce(function (acc, toast, toastIndex, arr) {
      if (acc.aliveCount >= MAX_TOASTS) {
        return acc;
      }
      var timeInState = dateNow - toast.stateTime;
      switch (toast.state) {
        case TOAST_STATES.pending:
          if (
          // First toast can always slide in
          toastIndex === 0 ||
          // Otherwise it has to wait for the one before it.
          arr[toastIndex - 1].state === TOAST_STATES.display && dateNow - arr[toastIndex - 1].stateTime >= TIME_WAIT - TIME_ENTER || arr[toastIndex - 1].state > TOAST_STATES.display) {
            return {
              state: transitToast(toast.id, dateNow, TOAST_STATES.enter)(acc.state),
              aliveCount: acc.aliveCount + 1
            };
          }
          break;
        case TOAST_STATES.enter:
          if (timeInState >= TIME_ENTER) {
            return {
              state: transitToast(toast.id, dateNow, TOAST_STATES.display)(acc.state),
              aliveCount: acc.aliveCount + 1
            };
          }
          break;
        case TOAST_STATES.display:
          if (!toast.options.indefinite && timeInState >= TIME_DISPLAY && !toast.hovering) {
            return {
              state: transitToast(toast.id, dateNow, TOAST_STATES.exit)(acc.state),
              aliveCount: acc.aliveCount
            };
          }
          break;
        case TOAST_STATES.exit:
          if (timeInState >= TIME_EXIT) {
            return {
              state: transitToast(toast.id, dateNow, TOAST_STATES.dead)(acc.state),
              aliveCount: acc.aliveCount
            };
          }
          break;
        default:
          return null;
      }
      return {
        state: acc.state,
        aliveCount: acc.aliveCount + (toast.state <= TOAST_STATES.display)
      };
    }, {
      state: state,
      aliveCount: 0
    }).state;
  };
};