import { styled } from "@vitality-ds/system";

const Inner = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flex: "1 1 100%",
  paddingLeft: "$xl",
  paddingRight: "$xl",
  marginLeft: "auto",
  marginRight: "auto",
  paddingInline: "$xxl",
  boxSizing: "border-box",
});

export default Inner;
