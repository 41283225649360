import { ColorScales, DarkThemeColors, ThemeColors } from "../../Color";
import convertScalesToStitches from "../utils/convertScalesToStitches";
import makeThemeForStitches from "../utils/makeThemeForStitches";

const { dark, light } = ColorScales;

const lightTheme = makeThemeForStitches(ThemeColors);
const darkTheme = makeThemeForStitches(DarkThemeColors);

export default () => ({
  light: {
    ...lightTheme,
    ...convertScalesToStitches(light),
  },
  dark: {
    ...darkTheme,
    ...convertScalesToStitches(dark),
  },
});
