import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
export default css({
  color: getColorScaleValueByUseCase("primary", text.hiSaturation),
  fontSize: "inherit",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
    filter: "brightness(110%)"
  },
  variants: {
    standalone: {
      "true": {
        textDecoration: "none"
      }
    }
  }
});