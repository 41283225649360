import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
export default css({
  "&, & > a": {
    color: getColorScaleValueByUseCase("primary", text.hiSaturation),
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  }
});