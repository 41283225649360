import { css } from "@vitality-ds/system";
export default css({
  width: "100%",
  display: "flex",
  whiteSpace: "break-spaces",
  lineHeight: "$display400",
  variants: {
    alignContent: {
      end: {
        justifyContent: "flex-end",
        textAlign: "right"
      }
    }
  }
});