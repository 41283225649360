import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import CONFIG from "../constants";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds,
  borders = colorUseCases.borders;
export default css({
  all: "unset",
  boxSizing: "border-box",
  userSelect: "none",
  padding: 4,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "flex-end",
  fontSize: "$body",
  lineHeight: 1,
  margin: "0",
  outline: "none",
  transition: "background-color ".concat(CONFIG.TRANSITION_DURATION_MS, "ms ").concat(CONFIG.TRANSITION_TIMING_FUNCTION),
  borderRadius: "$rounded",
  position: "relative",
  cursor: "pointer",
  color: getColorScaleValueByUseCase("neutral", text.onSolidBackgrounds),
  backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElementSolid),
  "&[data-disabled]": {
    cursor: "not-allowed",
    "&, &:hover": {
      backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElementSolid_disabled),
      color: getColorScaleValueByUseCase("neutral", text.onSolidBackgrounds)
    }
  },
  "&:hover": {
    backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElementSolid_hovered)
  },
  "&:focus-visible": {
    "&:not([data-disabled])": {
      boxShadow: "0 0 0 2px ".concat(getColorScaleValueByUseCase("neutral", backgrounds.app), ", 0 0 0 4px ").concat(getColorScaleValueByUseCase("primary", borders.focusRing))
    }
  },
  variants: {
    size: {
      "1": {
        width: CONFIG.TRACK_WIDTH,
        height: CONFIG.TRACK_HEIGHT
      }
    },
    checked: {
      "true": {
        justifyContent: "flex-start",
        backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_active),
        "&:hover": {
          backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_hovered)
        },
        "&[data-disabled]": {
          "&, &:hover": {
            backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_disabled),
            color: getColorScaleValueByUseCase("primary", text.disabled)
          }
        }
      }
    }
  },
  defaultVariants: {
    size: "1"
  }
});