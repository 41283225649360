import { Alert, Error } from "@vitality-ds/icons";
var VARIANT_OPTIONS = {
  custom: {
    titleIcon: true,
    forcedIcon: null,
    actionsAllowed: true,
    primaryActionVariant: "primary",
    primaryActionText: "Accept",
    secondaryActionsAllowed: true,
    cancelActionAllowed: true,
    headerCloseAllowed: true
  },
  transactional: {
    titleIcon: true,
    forcedIcon: null,
    actionsAllowed: true,
    primaryActionVariant: "primary",
    primaryActionText: "Confirm",
    secondaryActionsAllowed: true,
    cancelActionAllowed: true,
    headerCloseAllowed: false
  },
  passive: {
    titleIcon: true,
    forcedIcon: null,
    actionsAllowed: false,
    primaryActionVariant: null,
    primaryActionText: false,
    secondaryActionsAllowed: false,
    cancelActionAllowed: true,
    headerCloseAllowed: true
  },
  warning: {
    titleIcon: true,
    forcedIcon: Alert,
    actionsAllowed: true,
    primaryActionVariant: "warning",
    primaryActionText: "Confirm",
    secondaryActionsAllowed: true,
    cancelActionAllowed: true,
    headerCloseAllowed: false
  },
  destructive: {
    titleIcon: true,
    forcedIcon: Error,
    actionsAllowed: true,
    primaryActionVariant: "destructive",
    primaryActionText: "Delete",
    secondaryActionsAllowed: true,
    cancelActionAllowed: true,
    headerCloseAllowed: false
  },
  acknowledgement: {
    titleIcon: false,
    forcedIcon: null,
    actionsAllowed: true,
    primaryActionVariant: "primary",
    primaryActionText: "Ok",
    secondaryActionsAllowed: false,
    cancelActionAllowed: false,
    headerCloseAllowed: false
  }
};
export default VARIANT_OPTIONS;