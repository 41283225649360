import { IconButton, ThemeContext } from "@vitality-ds/components";
import React, { useContext } from "react";
import { GlobalHotKeys } from "react-hotkeys";

import { Moon, Sun } from "../../../DocsIcons";

function ThemeToggleButton(): JSX.Element {
  const themeContext = useContext(ThemeContext);
  const { cycleTheme, theme } = themeContext;
  return (
    <>
      <GlobalHotKeys
        allowChanges
        keyMap={{ TOGGLE_THEME: "shift+c" }}
        handlers={{ TOGGLE_THEME: cycleTheme }}
      />

      <IconButton
        onClick={cycleTheme}
        tooltipContent="Switch the color mode ( ⇪ + C)"
        icon={theme === "light" ? <Sun /> : <Moon />}
      />
    </>
  );
}

export default ThemeToggleButton;
