import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { SIDEBAR_TOTAL_WIDTH } from "../../../components/SidebarBar/constants";
import { TRANSITION_DURATION } from "../constants";
export default (function () {
  var _React$useState = React.useState(false),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    isSidebarOpen = _React$useState2[0],
    setIsSidebarOpen = _React$useState2[1];
  var _React$useState3 = React.useState(),
    _React$useState4 = _slicedToArray(_React$useState3, 2),
    sidebarContent = _React$useState4[0],
    setSidebarContent = _React$useState4[1];
  var closeSidebar = function closeSidebar() {
    return setIsSidebarOpen(false);
  };
  // @todo fix this default in beta
  var openSidebar = function openSidebar() {
    var content = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "CONTENT";
    if (!isSidebarOpen) {
      setIsSidebarOpen(true);
    }
    setSidebarContent(content);
  };
  return {
    isSidebarOpen: isSidebarOpen,
    openSidebar: openSidebar,
    closeSidebar: closeSidebar,
    sidebarContent: sidebarContent,
    TRANSITION_DURATION: TRANSITION_DURATION,
    SIDEBAR_WIDTH: SIDEBAR_TOTAL_WIDTH
  };
});