import { styled } from "@vitality-ds/system";

const Container = styled("ul", {
  display: "flex",
  flexDirection: "column",
  gap: "$md",
  paddingLeft: 0,
  listStyleType: "none",
  strong: {
    textTransform: "uppercase",
  },
});

export default Container;
