import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
export default css({
  color: getColorScaleValueByUseCase("neutral", text.lowContrast),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  $$size: 22,
  height: "$$size",
  width: "$$size",
  fontSize: "$$size",
  alignSelf: "center",
  variants: {
    disabled: {
      "true": {
        color: getColorScaleValueByUseCase("neutral", text.disabled)
      }
    }
  }
});