import React from "react";
import IconPrimitive from "@vitality-ds/icons";

const Defibrilator = (props) => (
  <IconPrimitive {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.922 4.986c1.169 1.288 1.372 3.096.762 4.648-1.044 2.655-3.393 5.06-5.62 7.342-1.09 1.116-2.15 2.203-3.016 3.274l-.618-.6c-2.844-2.76-6.662-6.463-8.09-10.016a4.444 4.444 0 01.096-3.536 4.48 4.48 0 011.082-1.452c1.816-1.623 4.66-1.37 6.389.344l1.141 1.133L13.37 4.81a4.548 4.548 0 015.087-.898c.557.255 1.055.62 1.465 1.073zM9.484 12.401l3.97-5.133-.763 3.875h2.263a.25.25 0 01.198.403l-3.97 5.133.763-3.875H9.682a.25.25 0 01-.198-.403z"
      clipRule="evenodd"
    ></path>
  </IconPrimitive>
);

export default Defibrilator;
