import Divider from "../../../Divider";
import CheckableDropdownMenuItem from "../CheckableDropdownMenuItem";
import CustomDropdownMenuItem from "../CustomDropdownMenuItem";
import DropdownMenuItem from "../DropdownMenuItem";
import GroupLabel from "../GroupLabel";
export default {
  item: {
    component: DropdownMenuItem
  },
  checkableItem: {
    component: CheckableDropdownMenuItem
  },
  divider: {
    component: Divider
  },
  groupLabel: {
    component: GroupLabel
  },
  custom: {
    component: CustomDropdownMenuItem
  }
};