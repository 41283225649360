import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _css;
import { css } from "@vitality-ds/system";
import { ButtonIcon } from "../../Button/components/ButtonIcon";
export default css((_css = {
  display: "flex",
  gap: 1,
  alignItems: "flex-start"
}, _defineProperty(_css, "[data-vitality-component='combo-button-main']", {
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0
}), _defineProperty(_css, "[data-vitality-component='combo-button-dropdown']", _defineProperty({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  paddingInline: "$md"
}, "".concat(ButtonIcon), {
  margin: 0
})), _defineProperty(_css, "variants", {
  size: {
    compact: _defineProperty({}, "[data-vitality-component='combo-button-dropdown']", {
      paddingInline: "$sm"
    })
  }
}), _css));