import { colorUseCases, css, getColorScaleValueByUseCase, keyframes } from "@vitality-ds/system";
import EASING_FUNCTION from "../constants";
var backgrounds = colorUseCases.backgrounds;
var overlayShow = keyframes({
  "0%": {
    opacity: 0
  },
  "100%": {
    opacity: 1
  }
});
export default css({
  backgroundColor: getColorScaleValueByUseCase("neutralA", backgrounds.blanket),
  position: "fixed",
  inset: 0,
  zIndex: "$blanket",
  animation: "".concat(overlayShow, " 150ms ").concat(EASING_FUNCTION),
  variants: {
    isLayeredModal: {
      "true": {
        zIndex: "calc($dialog + 1)"
      }
    }
  }
});