import { css } from "@vitality-ds/system";
export default css({
  $$spacing: "$space$sm",
  svg: {
    $$size: "16px",
    width: "$$size",
    height: "$$size",
    opacity: 0.8,
    "altGlyph, circle, ellipse, path, polygon, polyline, rect, text, textPath, tref, tspan,": {
      fill: "currentColor"
    }
  },
  variants: {
    spacing: {
      end: {
        marginInlineEnd: "$$spacing"
      },
      start: {
        marginInlineStart: "$$spacing"
      }
    },
    size: {
      "default": {},
      compact: {
        $$spacing: "$space$xs"
      }
    }
  }
});