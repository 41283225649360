import { AsyncSelectCell, ButtonCell, CreatableSelectCell, CurrencyInputCell, DatePickerCell, DurationInputCell, IconButtonCell, IconCell, LinkCell, NumberCell, RowActionsCell, SearchInputCell, SelectCell, StatusBadgeCell, SwitchCell, TextCell, TextInputCell, TimePickerCell } from "../Cells";
import CustomCell from "../Cells/Custom";
import SearchSelectInputCell from "../Cells/SearchSelectInput";
export var DEFAULT_EMPTY_PLACEHOLDER = "-";
var errorGetCellProps = function errorGetCellProps(componentName) {
  // eslint-disable-next-line no-console
  console.error("[Vitality] You're trying to render a ".concat(componentName, " cell. But no getCellProps function was given to the column as a result nothing could be rendered. Please refer to https://vitalitydesignsystem.com/components/table for an implementation."));
  return null;
};
export var CELL_TYPES = {
  text: {
    component: TextCell,
    getCellProps: function getCellProps(colId, rowData) {
      return {
        children: rowData[colId]
      };
    }
  },
  link: {
    component: LinkCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("link");
    }
  },
  iconButton: {
    component: IconButtonCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("iconButton");
    }
  },
  icon: {
    component: IconCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("icon");
    }
  },
  number: {
    component: NumberCell,
    getCellProps: function getCellProps(colId, rowData) {
      return {
        children: rowData[colId]
      };
    }
  },
  statusBadge: {
    component: StatusBadgeCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("statusBadge");
    }
  },
  button: {
    component: ButtonCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("button");
    }
  },
  rowActions: {
    component: RowActionsCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("rowActions");
    }
  },
  "switch": {
    component: SwitchCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("switch");
    }
  },
  textInput: {
    component: TextInputCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("textInput");
    }
  },
  select: {
    component: SelectCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("select");
    }
  },
  currencyInput: {
    component: CurrencyInputCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("currencyInput");
    }
  },
  durationInput: {
    component: DurationInputCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("durationInput");
    }
  },
  searchInput: {
    component: SearchInputCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("searchInput");
    }
  },
  timePicker: {
    component: TimePickerCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("timePicker");
    }
  },
  datePicker: {
    component: DatePickerCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("datePicker");
    }
  },
  asyncSelect: {
    component: AsyncSelectCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("asyncSelect");
    }
  },
  creatableSelect: {
    component: CreatableSelectCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("creatableSelect");
    }
  },
  searchSelectInput: {
    component: SearchSelectInputCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("searchSelectInput");
    }
  },
  custom: {
    component: CustomCell,
    getCellProps: function getCellProps() {
      return errorGetCellProps("custom");
    }
  }
};