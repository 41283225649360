import { colorUseCases, css, getColorScaleValueByUseCase, keyframes } from "@vitality-ds/system";
var pulse = keyframes({
  "0%": {
    backgroundColor: getColorScaleValueByUseCase("neutral", colorUseCases.backgrounds.uiElement_hovered)
  },
  "100%": {
    backgroundColor: getColorScaleValueByUseCase("neutral", colorUseCases.borders.uiElement_disabled)
  }
});
export default css({
  height: "100%",
  width: "100%",
  borderRadius: "$default",
  animation: "".concat(pulse, " 1s linear infinite alternate")
});