import { css } from "@vitality-ds/system";
import { getCompactTextColorVariant, getTextColorVariant } from "./helpers";
export default css({
  fontFamily: "$body",
  fontSize: "$body",
  fontWeight: "$body",
  lineHeight: "$body",
  flex: 1,
  variants: {
    size: {
      compact: {
        fontFamily: "$caption",
        fontSize: "$caption",
        fontWeight: "$caption",
        lineHeight: "$caption"
      },
      "default": {}
    },
    severity: {
      info: getTextColorVariant("info"),
      critical: getTextColorVariant("critical"),
      success: getTextColorVariant("success"),
      warning: getTextColorVariant("warning")
    },
    hideTitle: {
      "true": {}
    }
  },
  compoundVariants: [{
    size: "default",
    hideTitle: true,
    css: {
      // help to align text with the icon whilst allowing text to overflow over two lines
      marginTop: "$xs"
    }
  }, {
    size: "compact",
    severity: "info",
    css: getCompactTextColorVariant("info")
  }, {
    size: "compact",
    severity: "critical",
    css: getCompactTextColorVariant("critical")
  }, {
    size: "compact",
    severity: "success",
    css: getCompactTextColorVariant("success")
  }, {
    size: "compact",
    severity: "warning",
    css: getCompactTextColorVariant("warning")
  }]
});