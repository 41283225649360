import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var borders = colorUseCases.borders,
  backgrounds = colorUseCases.backgrounds;
export default css({
  $$size: "14px",
  boxSizing: "border-box",
  all: "unset",
  backgroundColor: "none",
  WebkitAppearence: "none",
  width: "$$size",
  height: "$$size",
  flexGrow: 0,
  flexShrink: 0,
  borderRadius: "100%",
  alignSelf: "flex-start",
  border: "2px solid",
  borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement),
  "&:hover:enabled": {
    borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_hovered)
  },
  "&:active:enabled": {
    borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_active)
  },
  "&:focus-visible:enabled": {
    boxShadow: "0 0 0 2px ".concat(getColorScaleValueByUseCase("primary", borders.focusRing))
  },
  "&:disabled": {
    borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_disabled)
  },
  "&[data-state='checked']": {
    borderColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid),
    "&:hover:enabled": {
      borderColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_hovered)
    },
    "&:active:enabled": {
      borderColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_active)
    },
    "&:disabled": {
      borderColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElementSolid_disabled)
    }
  }
});