import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text,
  borders = colorUseCases.borders,
  backgrounds = colorUseCases.backgrounds;
export default css({
  $$size: "18px",
  $$color: getColorScaleValueByUseCase("neutral", text.hiContrast),
  $$outlineStyle: "0 0 0 2px ".concat(getColorScaleValueByUseCase("primary", borders.uiElement_focused)),
  width: "$$size",
  height: "$$size",
  padding: 0,
  flexShrink: 0,
  borderRadius: "$default",
  border: "2px solid",
  borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement),
  color: "$$color",
  svg: {
    fill: "$$color"
  },
  backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.app),
  "&:hover:enabled": {
    borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_hovered),
    backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_hovered)
  },
  "&:active:enabled": {
    boxShadow: "$$outlineStyle",
    borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_active),
    backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_active)
  },
  "&:focus-visible:enabled": {
    outline: "none",
    boxShadow: "$$outlineStyle",
    borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_focused),
    backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_focused)
  },
  "&[disabled]": {
    borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_disabled),
    "&, & + label": {
      cursor: "not-allowed"
    }
  },
  "&[data-state='checked'], &[data-state='indeterminate']": {
    borderColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid),
    backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid),
    color: getColorScaleValueByUseCase("neutral", text.onSolidBackgrounds),
    "&:hover:enabled": {
      backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_hovered),
      borderColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_hovered)
    },
    "&:active:enabled": {
      backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_active),
      borderColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_active)
    },
    "&:focus-visible:enabled": {
      backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_focused),
      borderColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_focused)
    },
    "&[disabled]": {
      backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElementSolid_disabled),
      borderColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElementSolid_disabled),
      // An edge case where we need to use a different scale step for text color to make the checkmark stand out
      color: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_selected)
    }
  }
});