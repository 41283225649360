import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
export default css({
  color: getColorScaleValueByUseCase("neutral", text.lowContrast),
  variants: {
    sortBy: {
      descending: {
        transform: "rotate(270deg)"
      },
      ascending: {
        transform: "rotate(90deg)"
      }
    }
  }
});