import { INPUT_MAPPINGS } from "./constants";
// eslint-disable-next-line import/prefer-default-export
export var getInputComponent = function getInputComponent(type) {
  return INPUT_MAPPINGS[type] || INPUT_MAPPINGS.text;
};
export var getDescribedById = function getDescribedById(id) {
  return "vitality-describedBy-".concat(id);
};
export var getErrorMessageId = function getErrorMessageId(id) {
  return "vitality-errorMessage-".concat(id);
};