import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const blue: ColorScale<"blue"> = {
  blue1: {
    name: "blue1",
    data: generateColorData("blue1", { h: 212, s: 100, l: 99, a: 1 }),
  },
  blue2: {
    name: "blue2",
    data: generateColorData("blue2", { h: 212, s: 100, l: 98, a: 1 }),
  },
  blue3: {
    name: "blue3",
    data: generateColorData("blue3", { h: 212, s: 100, l: 96, a: 1 }),
  },
  blue4: {
    name: "blue4",
    data: generateColorData("blue4", { h: 212, s: 98, l: 94, a: 1 }),
  },
  blue5: {
    name: "blue5",
    data: generateColorData("blue5", { h: 212, s: 95, l: 90, a: 1 }),
  },
  blue6: {
    name: "blue6",
    data: generateColorData("blue6", { h: 212, s: 81, l: 84, a: 1 }),
  },
  blue7: {
    name: "blue7",
    data: generateColorData("blue7", { h: 212, s: 77, l: 76, a: 1 }),
  },
  blue8: {
    name: "blue8",
    data: generateColorData("blue8", { h: 212, s: 81, l: 65, a: 1 }),
  },
  blue9: {
    name: "blue9",
    data: generateColorData("blue9", { h: 212, s: 100, l: 47, a: 1 }),
  },
  blue10: {
    name: "blue10",
    data: generateColorData("blue10", { h: 212, s: 100, l: 50, a: 1 }),
  },
  blue11: {
    name: "blue11",
    data: generateColorData("blue11", { h: 212, s: 100, l: 43, a: 1 }),
  },
  blue12: {
    name: "blue12",
    data: generateColorData("blue12", { h: 212, s: 100, l: 15, a: 1 }),
  },
  blue13: {
    name: "blue13",
    data: generateColorData("blue13", { h: 212, s: 100, l: 99, a: 1 }),
  },
};

export default blue;
