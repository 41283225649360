import { css } from "@vitality-ds/system";
export default css({
  transition: "opacity 300ms",
  opacity: 1,
  variants: {
    isLoading: {
      "true": {
        opacity: 0
      }
    }
  }
});