import { useMemo } from "react";
import { getIcon } from "../logic";
var useChipCheckbox = function useChipCheckbox(_ref) {
  var onClick = _ref.onClick,
    isLoading = _ref.isLoading,
    isChecked = _ref.isChecked;
  var handleClick = useMemo(function () {
    return function (e) {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    };
  }, [onClick]);
  var state = useMemo(function () {
    if (isLoading) {
      return "LOADING";
    }
    if (isChecked) {
      return "CHECKED";
    }
    return "UNCHECKED";
  }, [isLoading, isChecked]);
  var icon = getIcon(state);
  return {
    handleClick: handleClick,
    state: state,
    icon: icon
  };
};
export default useChipCheckbox;