import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import SidebarInner from "./components/SidebarInner";
import { SidebarProps } from "./types";

const QUERY = graphql`
  fragment mdxContent on MdxConnection {
    edges {
      node {
        id
        frontmatter {
          title
          order
        }
        fields {
          slug
          sourceName
        }
      }
    }
  }

  {
    components: allMdx(
      sort: { frontmatter: { title: ASC } }
      filter: { fields: { sourceName: { eq: "components" } } }
    ) {
      ...mdxContent
    }
    foundations: allMdx(
      sort: { frontmatter: { order: ASC } }
      filter: { fields: { sourceName: { eq: "foundations" } } }
    ) {
      ...mdxContent
    }
    guides: allMdx(
      sort: { frontmatter: { title: ASC } }
      filter: { fields: { sourceName: { eq: "guides" } } }
    ) {
      ...mdxContent
    }
    patterns: allMdx(
      sort: { frontmatter: { title: ASC } }
      filter: { fields: { sourceName: { eq: "patterns" } } }
    ) {
      ...mdxContent
    }
  }
`;

function Sidebar({ toggleSidebarOpen, isOpen }: SidebarProps): JSX.Element {
  const data = useStaticQuery(QUERY);
  return (
    <SidebarInner
      data={data}
      isOpen={isOpen}
      toggleSidebarOpen={toggleSidebarOpen}
    />
  );
}

export default Sidebar;
