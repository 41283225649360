var flexVariants = {
  /**
   * Define the alignment of items within the stack. Under the hood, this sets the `align-items` CSS property. For more information: https://developer.mozilla.org/en-US/docs/Web/CSS/align-items
   */
  align: {
    start: {
      alignItems: "flex-start"
    },
    center: {
      alignItems: "center"
    },
    end: {
      alignItems: "flex-end"
    },
    stretch: {
      alignItems: "stretch"
    },
    baseline: {
      alignItems: "baseline"
    }
  },
  /**
   * Sets the `justify-content` CSS property. For more information: https://developer.mozilla.org/en-US/docs/Web/CSS/align-items
   */
  justify: {
    start: {
      justifyContent: "flex-start"
    },
    center: {
      justifyContent: "center"
    },
    end: {
      justifyContent: "flex-end"
    },
    between: {
      justifyContent: "space-between"
    },
    stretch: {
      justifyContent: "stretch"
    },
    around: {
      justifyContent: "space-around"
    }
  },
  /**
   * This property is shorthand for the flex-wrap CSS property.
   */
  wrap: {
    wrap: {
      flexWrap: "wrap"
    },
    wrapReverse: {
      flexWrap: "wrap-reverse"
    },
    noWrap: {
      flexWrap: "nowrap"
    }
  },
  /**
   * Define the direction of stacking. Under the hood, this sets the `flex-direction` CSS property. For more information: https://developer.mozilla.org/en-US/docs/Web/CSS/flex-direction
   */
  direction: {
    row: {
      flexDirection: "row"
    },
    column: {
      flexDirection: "column"
    },
    rowReverse: {
      flexDirection: "row-reverse"
    },
    columnReverse: {
      flexDirection: "column-reverse"
    }
    /**
     * This property is shorthand for the gap CSS property. Gap values refer to the `theme.space` object.
     */
  }
};
export default flexVariants;