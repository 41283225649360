import ChipMultiValueContainer from "./components/Chip/MultiValueContainer";
import ChipMultiValueLabel from "./components/Chip/MultiValueLabel";
import ChipMultiValueRemove from "./components/Chip/MultiValueRemove";
// eslint-disable-next-line import/prefer-default-export
export var CUSTOM_COMPONENTS = {
  chip: {
    MultiValueContainer: ChipMultiValueContainer,
    MultiValueLabel: ChipMultiValueLabel,
    MultiValueRemove: ChipMultiValueRemove
  }
};