import _extends from "@babel/runtime/helpers/extends";
import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import { BUTTON_BORDER_WIDTH } from "../constants";
import { getColorVariant, getGhostVariant, getLinkVariant, getOnSurfaceVariant, getSubtleVariant } from "./helpers";
var text = colorUseCases.text;
export default css({
  fontFamily: "$button",
  fontSize: "$button",
  fontWeight: "$button",
  lineHeight: "calc($button * 1em - (".concat(BUTTON_BORDER_WIDTH, " * 2))"),
  letterSpacing: "$button",
  borderRadius: "$default",
  border: "none",
  textDecoration: "none",
  position: "relative",
  whiteSpace: "nowrap",
  cursor: "pointer",
  textAlign: "center",
  "&:active:not([disabled])": {
    transform: "translateY(0.5px)"
  },
  variants: {
    shouldFitContainer: {
      "true": {
        width: "100%"
      },
      "false": {
        width: "initial"
      }
    },
    /**
     * The stylistic changes in the button (Internal Prop - Not Exposed)
     * Note that these variants are empty on purpose
     * to allow for the compound variants
     */
    variant: {
      solid: {},
      subtle: {},
      onSurface: {},
      ghost: {},
      link: {}
    },
    /**
     * The tint of the button (Internal Prop - Not Exposed)
     * Note that these variants are empty on purpose
     * to allow for the compound variants
     */
    color: {
      neutral: {},
      primary: {},
      critical: {},
      success: {},
      warning: {},
      info: {}
    },
    /**
     * The size of the button
     */
    size: {
      "default": {
        paddingInline: "$lg",
        paddingTop: "$sm",
        paddingBottom: "$sm"
      },
      compact: {
        paddingInline: "$md"
      }
    },
    isLoading: {
      "true": {
        pointerEvents: "none"
      }
    }
  },
  compoundVariants: [
  /**
   * Subtle Variants
   */
  {
    color: "neutral",
    variant: "subtle",
    css: _extends({}, getSubtleVariant("neutral"), {
      // Override the usual colour use case
      color: getColorScaleValueByUseCase("neutralA", text.lowContrast)
    })
  }, {
    color: "primary",
    variant: "subtle",
    css: getSubtleVariant("primary")
  }, {
    color: "success",
    variant: "subtle",
    css: getSubtleVariant("success")
  }, {
    color: "warning",
    variant: "subtle",
    css: getSubtleVariant("warning")
  }, {
    color: "critical",
    variant: "subtle",
    css: getSubtleVariant("critical")
  }, {
    color: "info",
    variant: "subtle",
    css: getSubtleVariant("info")
  },
  /**
   * OnSurface Variants
   */
  {
    color: "neutral",
    variant: "onSurface",
    css: _extends({}, getOnSurfaceVariant("neutral"), {
      // Override the usual colour use case
      color: getColorScaleValueByUseCase("neutralA", text.lowContrast)
    })
  }, {
    color: "primary",
    variant: "onSurface",
    css: getOnSurfaceVariant("primary")
  }, {
    color: "success",
    variant: "onSurface",
    css: getOnSurfaceVariant("success")
  }, {
    color: "warning",
    variant: "onSurface",
    css: getOnSurfaceVariant("warning")
  }, {
    color: "critical",
    variant: "onSurface",
    css: getOnSurfaceVariant("critical")
  }, {
    color: "info",
    variant: "onSurface",
    css: getOnSurfaceVariant("info")
  },
  /**
   * Ghost Variants
   */
  {
    color: "neutral",
    variant: "ghost",
    css: getGhostVariant("neutralA")
  }, {
    color: "primary",
    variant: "ghost",
    css: getGhostVariant("primary")
  }, {
    color: "success",
    variant: "ghost",
    css: getGhostVariant("success")
  }, {
    color: "warning",
    variant: "ghost",
    css: getGhostVariant("warning")
  }, {
    color: "critical",
    variant: "ghost",
    css: getGhostVariant("critical")
  }, {
    color: "info",
    variant: "ghost",
    css: getGhostVariant("info")
  },
  /**
   * Link Variants
   */
  {
    color: "neutral",
    variant: "link",
    css: getLinkVariant("neutralA")
  }, {
    color: "primary",
    variant: "link",
    css: getLinkVariant("primary")
  }, {
    color: "success",
    variant: "link",
    css: getLinkVariant("success")
  }, {
    color: "warning",
    variant: "link",
    css: getLinkVariant("warning")
  }, {
    color: "critical",
    variant: "link",
    css: getLinkVariant("critical")
  }, {
    color: "info",
    variant: "link",
    css: getLinkVariant("info")
  },
  /**
   * Solid Variants
   */
  {
    color: "neutral",
    variant: "solid",
    css: getColorVariant("neutral")
  }, {
    color: "primary",
    variant: "solid",
    css: getColorVariant("primary")
  }, {
    color: "success",
    variant: "solid",
    css: getColorVariant("success")
  }, {
    color: "warning",
    variant: "solid",
    css: getColorVariant("warning")
  }, {
    color: "critical",
    variant: "solid",
    css: getColorVariant("critical")
  }, {
    color: "info",
    variant: "solid",
    css: getColorVariant("info")
  }]
});