import React from "react";
import IconPrimitive from "@vitality-ds/icons";

const Utensils = (props) => (
  <IconPrimitive {...props}>
    <path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M14 2H9.5v20H14v-2h-2v-1h2v-1.5h-1v-1h1V15h-1v-1h1v-1.5h-2v-1h2V10h-1V9h1V7.5h-1v-1h1V5h-2V4h2V2zM16.5 14a1 1 0 00-1 1v2.5H19V15a1 1 0 00-1-1h-1.5zm2.5 4.5h-3.5V21a1 1 0 001 1H18a1 1 0 001-1v-2.5z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M16.5 15v1.5H18V15h-1.5zm2.5 0a1 1 0 00-1-1h-1.5a1 1 0 00-1 1v2.5H19V15zm-3.5 3.5H19V21a1 1 0 01-1 1h-1.5a1 1 0 01-1-1v-2.5zm2.5 1h-1.5V21H18v-1.5zM6.5 5.5A1.5 1.5 0 005 7v1.5h3V7a1.5 1.5 0 00-1.5-1.5zm1.5 4H5V20l1.5 2L8 20V9.5z"
      fill="currentColor"
    />
  </IconPrimitive>
);

export default Utensils;
