import IconPrimitive from "@vitality-ds/icons";
import React from "react";

function Moon(props) {
  return (
    <IconPrimitive {...props}>
      <path d="M12.0637 2.51019C12.1586 2.67856 12.2027 2.87077 12.1908 3.06365C12.1789 3.25653 12.1114 3.44184 11.9966 3.59727C11.1399 4.75513 10.7275 6.18208 10.8343 7.61843C10.941 9.05478 11.56 10.4051 12.5784 11.4235C13.5969 12.442 14.9472 13.0609 16.3835 13.1677C17.8199 13.2745 19.2468 12.862 20.4047 12.0053C20.4047 12.0053 20.9998 11.5184 21.5008 12.0194C22.0017 12.0194 21.9977 12.9031 21.9977 12.9031C21.8226 14.798 21.1114 16.6039 19.9473 18.1093C18.7833 19.6148 17.2146 20.7577 15.4247 21.4042C13.6349 22.0506 11.698 22.174 9.84056 21.7598C7.98316 21.3457 6.28213 20.4111 4.9365 19.0655C3.59086 17.7198 2.65629 16.0188 2.24212 14.1614C1.82795 12.304 1.95132 10.3671 2.5978 8.57721C3.24428 6.78737 4.38712 5.21866 5.89261 4.05463C7.3981 2.89059 9.20396 2.17939 11.0989 2.00422C11.2914 1.98649 11.4849 2.02483 11.6561 2.1146C11.8273 2.20438 11.9689 2.34177 12.0637 2.51019ZM19.5581 14.7085C18.1257 15.2273 16.5751 15.3272 15.088 14.9963C13.6009 14.6654 12.2389 13.9175 11.1617 12.8403C10.0845 11.763 9.33656 10.401 9.00568 8.91395C8.67479 7.42687 8.77461 5.87627 9.29344 4.44389C8.025 4.90296 6.89294 5.67473 6.00212 6.68772C5.11131 7.70071 4.49057 8.92214 4.19741 10.2389C3.90425 11.5556 3.94814 12.925 4.32503 14.2202C4.70192 15.5155 5.39961 16.6946 6.35347 17.6485C7.30733 18.6023 8.48649 19.3 9.78173 19.6769C11.077 20.0538 12.4464 20.0977 13.7631 19.8045C15.0798 19.5114 16.3012 18.8906 17.3142 17.9998C18.3272 17.109 19.099 15.977 19.5581 14.7085Z" />
    </IconPrimitive>
  );
}
export default Moon;
