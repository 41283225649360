import { keyframes } from "@vitality-ds/system";
export default keyframes({
  "0%": {
    borderColor: "transparent"
  },
  "50%": {
    borderColor: "$success7"
  },
  "100%": {
    borderColor: "transparent"
  }
});