import { darkTheme, theme } from "./stitches.config";

/*
  Any available themes. The value of each key
  returns the className (string) from stitches' createTheme()
*/

export var AVAILABLE_THEMES = {
  light: theme,
  // stitches' default theme.
  dark: darkTheme
};