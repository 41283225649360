/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

// empty basics
export var EMPTY_ARR = Object.freeze([]);
export var EMPTY_OBJ = Object.freeze({});
export var EMPTY_PROMISE = function EMPTY_PROMISE() {
  return new Promise(function (resolve) {
    var _window;
    (_window = window) === null || _window === void 0 ? void 0 : _window.setTimeout(function () {
      resolve();
    });
  });
};
export var EMPTY_UNRESOLVED_PROMISE = function EMPTY_UNRESOLVED_PROMISE() {
  return new Promise(function () {
    // empty fn
  });
};

// empty functions
export var NOOP = function NOOP() {
  // empty fn
};
export var FALSE_OP = function FALSE_OP() {
  return false;
};
export function NULL_OP() {
  return null;
}
export var TRUE_OP = function TRUE_OP() {
  return true;
};