import { colorUseCases, css, getColorScaleValueByUseCase, keyframes } from "@vitality-ds/system";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds,
  borders = colorUseCases.borders;
var toggleOn = keyframes({
  "0%": {
    opacity: 0,
    transform: "translateY(2px) rotateZ(-10deg)"
  },
  "100%": {
    opacity: 1,
    transform: "translateY(0)"
  }
});
export default css({
  $$iconColor: getColorScaleValueByUseCase("neutral", text.lowContrast),
  backgroundColor: "transparent",
  border: "none",
  boxSizing: "border-box",
  borderRadius: "$rounded",
  padding: "$md",
  cursor: "pointer",
  lineHeight: 1,
  alignSelf: "center",
  svg: {
    pointerEvents: "none",
    marginTop: 0,
    "altGlyph, circle, ellipse, path, polygon, polyline, rect, text, textPath, tref, tspan,": {
      fill: "$$iconColor"
    }
  },
  "&:hover": {
    backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElement_hovered),
    $$iconColor: getColorScaleValueByUseCase("primary", text.hiSaturation)
  },
  "&:active:enabled": {
    transform: "translateY(0.5px)"
  },
  "&:active": {
    backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElement_active),
    $$iconColor: getColorScaleValueByUseCase("primary", text.hiSaturation)
  },
  "&:disabled": {
    cursor: "not-allowed",
    backgroundColor: "transparent",
    $$iconColor: getColorScaleValueByUseCase("neutral", text.disabled)
  },
  "&:focus-visible": {
    outline: "none",
    boxShadow: "0 0 0 $space$outlineWidth ".concat(getColorScaleValueByUseCase("primary", borders.focusRing))
  },
  variants: {
    /**
     * Internal, should not be exposed in docs. Indicates whether the component is selected. Documented in types file
     * @ignore
     */
    selected: {
      "true": {
        svg: {
          animation: "".concat(toggleOn, " 250ms cubic-bezier(0.64, 0.57, 0.67, 1.53)")
        },
        $$iconColor: getColorScaleValueByUseCase("accent", text.hiSaturation),
        "&:hover": {
          backgroundColor: getColorScaleValueByUseCase("accent", backgrounds.uiElement_hovered),
          $$iconColor: getColorScaleValueByUseCase("accent", text.hiSaturation)
        },
        "&:focus-visible": {
          outlineColor: getColorScaleValueByUseCase("accent", borders.focusRing)
        }
      }
    },
    size: {
      compact: {
        padding: "$xs",
        svg: {
          $$size: "22px",
          width: "$$size",
          height: "$$size"
        }
      }
    }
  }
});