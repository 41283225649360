import { Space } from "../../Space";
import { SpaceToken, ThemeSpaceType } from "../../Space/types";
import { Platforms } from "../../types";
import appendPx from "../utils/appendPx";
import commonGetter from "./commonGetter";

const getSpaceValue = ({ value }: SpaceToken["data"], platform: Platforms) => {
  switch (platform) {
    case "web":
      return appendPx(value);
    case "reactNative":
      return value;
    default:
      return value;
  }
};
export default <T extends Platforms>(platform: T) =>
  commonGetter({
    platform,
    tokens: Space,
    getValue: getSpaceValue,
    additionalValues:
      platform === "web"
        ? {
            outlineWidth: "3px",
          }
        : null,
  }) as ThemeSpaceType<T>;
