var useTypeaheadChangeHandlers = function useTypeaheadChangeHandlers(onChange, onInputChange, valueRenderType) {
  var handleOnChange = function handleOnChange(option) {
    onChange(option);
    if (valueRenderType === "typeahead") {
      onInputChange(option ? option.label : "");
    }
  };
  var handleInputOnChange = function handleInputOnChange(inputValue, actionMeta) {
    if ((actionMeta === null || actionMeta === void 0 ? void 0 : actionMeta.action) === "input-change") {
      onInputChange(inputValue);
    }
  };
  return [handleOnChange, handleInputOnChange];
};
export default useTypeaheadChangeHandlers;