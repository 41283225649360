import { css } from "@vitality-ds/system";
export default css({
  // These values are dynamically set as a prop is passed in
  $$fadeToColor: "$colors$neutral1",
  $$initialHeight: "75px",
  variants: {
    truncated: {
      "true": {
        overflow: "hidden",
        position: "relative",
        maxHeight: "$$initialHeight",
        "&:before": {
          content: "",
          position: "absolute",
          height: "50%",
          bottom: 0,
          left: 0,
          right: 0,
          color: "inherit",
          background: "linear-gradient(to top, $$fadeToColor, rgba(255, 255, 255, 0))"
        }
      }
    }
  }
});