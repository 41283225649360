/**
 * Converts a "raw" color token to  `primary1: 'rgba(12, 34, 56, 1)'`
 */

import { Palette } from "../../Color/types";
import createScaleInStitchesFormat from "./createScaleInStitchesFormat";

type CommonThemeValue = {
  [token: string]: string;
};

export default (scalesInput: Palette): CommonThemeValue => {
  let output = {} as CommonThemeValue;

  Object.keys(scalesInput).forEach((scaleName) => {
    const scale = scalesInput[scaleName];
    output = { ...output, ...createScaleInStitchesFormat(scale) };
  });

  return output;
};
