import { css } from "@vitality-ds/system";
import getVariantHoverStatesByColorScale from "../../Toast/helpers/getVariantHoverStatesByColorScale";
export default css({
  backgroundColor: "transparent",
  border: "none",
  boxSizing: "content-box",
  alignSelf: "flex-start",
  cursor: "pointer",
  margin: "-$xs",
  padding: 0,
  svg: {
    borderRadius: "$rounded",
    padding: "$xs"
  },
  variants: {
    type: {
      success: getVariantHoverStatesByColorScale("success"),
      warning: getVariantHoverStatesByColorScale("warning"),
      error: getVariantHoverStatesByColorScale("critical"),
      info: getVariantHoverStatesByColorScale("info"),
      general: getVariantHoverStatesByColorScale("general")
    }
  }
});