import _extends from "@babel/runtime/helpers/extends";
import { colorUseCases, getColorScaleFromThemeObject, theme } from "@vitality-ds/system";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds;
var getBackgroundColor = function getBackgroundColor(_ref) {
  var isDisabled = _ref.isDisabled,
    isFocused = _ref.isFocused,
    isSelected = _ref.isSelected;
  if (isSelected || isSelected && isFocused) {
    return getColorScaleFromThemeObject("primary", backgrounds.uiElement_selected, theme);
  }
  if (isFocused && !isDisabled) {
    return getColorScaleFromThemeObject("neutral", backgrounds.uiElement_hovered, theme);
  }
  return "transparent";
};
var getActiveBackgroundColor = function getActiveBackgroundColor(_ref2) {
  var isDisabled = _ref2.isDisabled,
    isSelected = _ref2.isSelected;
  if (isSelected && !isDisabled) {
    return getColorScaleFromThemeObject("primary", backgrounds.uiElement_active, theme);
  }
  if (!isDisabled) {
    return getColorScaleFromThemeObject("neutral", backgrounds.uiElement_active, theme);
  }
  return "transparent";
};
export default (function (baseStyles, _ref3) {
  var isDisabled = _ref3.isDisabled,
    isFocused = _ref3.isFocused,
    isSelected = _ref3.isSelected;
  return _extends({}, baseStyles, {
    borderRadius: theme.radii["default"].value,
    backgroundColor: getBackgroundColor({
      isDisabled: isDisabled,
      isFocused: isFocused,
      isSelected: isSelected
    }),
    "&:active": {
      background: getActiveBackgroundColor({
        isDisabled: isDisabled,
        isSelected: isSelected
      })
    },
    color: isDisabled ? getColorScaleFromThemeObject("neutral", text.disabled, theme) : "inherit"
  });
});