import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useMemo, useState } from "react";
import { getInitialState, removeToast as removeToastLogic, selectDeadToasts, setHover as setHoverLogic, syncToasts as _syncToastsLogic, updateToastStates as updateToastStatesLogic } from "../logic";
import useInterval from "./useInterval";
var useToasterLogic = function useToasterLogic(incomingToasts, onClose) {
  // State / Logic
  var _useState = useState(getInitialState()),
    _useState2 = _slicedToArray(_useState, 2),
    toasterState = _useState2[0],
    setToasterState = _useState2[1];

  // Internal State Controls
  var controls = useMemo(function () {
    return {
      syncToastsLogic: function syncToastsLogic(toasts) {
        return setToasterState(_syncToastsLogic(toasts, Date.now()));
      },
      removeToast: function removeToast(toastId) {
        return setToasterState(removeToastLogic(toastId, Date.now()));
      },
      setHover: function setHover(toastId, isOver) {
        return setToasterState(setHoverLogic(toastId, isOver, Date.now()));
      },
      updateToastStates: function updateToastStates() {
        return setToasterState(updateToastStatesLogic(Date.now()));
      }
    };
  }, []);

  // Attempt to add new toasts every time the incomingToasts change
  useEffect(function () {
    controls.syncToastsLogic(incomingToasts);
  }, [incomingToasts, controls]);

  // Update the state transitions, while we have toasts
  useInterval(function () {
    controls.updateToastStates();
  }, toasterState.toasts.length > 0 ? 250 : null);

  // Call onClose on dead toasts and remove them
  useEffect(function () {
    var deadToasts = selectDeadToasts(toasterState);
    deadToasts.forEach(function (t) {
      return onClose(t.id);
    });
  }, [toasterState, controls, onClose]);
  return [toasterState.toasts, controls];
};
export default useToasterLogic;