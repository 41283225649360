import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _extends from "@babel/runtime/helpers/extends";
export var getVariantStyles = function getVariantStyles(key) {
  return {
    fontSize: key,
    fontWeight: key,
    lineHeight: key,
    letterSpacing: key,
    fontFamily: key
  };
};
export var maxLinesBaseStyle = {
  overflow: "hidden",
  whiteSpace: "normal",
  textOverflow: "ellipsis",
  display: "block",
  // todo check if these vendor prefixed properties are necessary
  "-webkit-box-orient": "vertical"
};
export var getMaxLines = function getMaxLines(numLines, variant) {
  return _extends({}, maxLinesBaseStyle, {
    "-webkit-line-clamp": numLines,
    maxHeight: "calc(".concat(numLines, " * $lineHeights").concat(variant, " * $fontSizes").concat(variant, ")")
  });
};
export var getMaxLinesVariants = function getMaxLinesVariants(maxLines, variants) {
  return _toConsumableArray(Array(maxLines)).map(function (_, numLines) {
    return variants.map(function (variant) {
      return {
        variant: variant.substring(1),
        // to remove the $
        maxLines: numLines + 1,
        css: _extends({}, getMaxLines(numLines + 1, variant))
      };
    });
  }).flat();
};