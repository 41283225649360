import { useRef } from "react";

import { SECTIONS } from "../constants";

const useSidebarInner = (): UseSidebarInner => {
  const sectionKeys = useRef(Object.keys(SECTIONS));
  const sectionTitles = useRef(Object.values(SECTIONS));

  return {
    sectionKeys: sectionKeys.current,
    sectionTitles: sectionTitles.current,
  };
};

export default useSidebarInner;
