export default function handleSearch(inputText, callback, minChars, requestDelayMs, onSearch, memoizedDebouncedSearch) {
  if (inputText.trim().length < minChars) {
    return callback(null);
  }
  if (!requestDelayMs) {
    return Promise.resolve(onSearch(inputText)).then(function (receivedOptions) {
      return callback(receivedOptions);
    })["catch"](function (error) {
      return callback(error);
    });
  }
  if (requestDelayMs) {
    return memoizedDebouncedSearch(inputText, callback);
  }
  return null;
}