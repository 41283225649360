import { toggleChecked } from "../logic";
var useMultiValueLabelCheckbox = function useMultiValueLabelCheckbox(_ref) {
  var data = _ref.data,
    selectProps = _ref.selectProps,
    onCheckboxClick = _ref.onCheckboxClick,
    forceUpdate = _ref.forceUpdate;
  // Read the current `value` of the Select component. This could contain one or multiple entries
  var currentSelectValue = selectProps.value;
  var thisItem;

  // Identify which item in the current value had its checkbox checked
  if (Array.isArray(currentSelectValue)) {
    thisItem = currentSelectValue.find(function (item) {
      return item.value === data.value;
    });
  }
  var handleCheckboxClick = function handleCheckboxClick() {
    toggleChecked(thisItem);
    if (typeof onCheckboxClick === "function") {
      onCheckboxClick(thisItem);
    }

    // set as array since onChange for isMulti's return value consistently assumes an array format. However, the type in react-select is not consistently an array.
    // the second is the action type, because we don't want to access specific actions such as select or deselect setting as null to not run other functions reactSelect functions. e.g. onSelect | onInputChange etc.
    selectProps.onChange(currentSelectValue, null);
    forceUpdate();
  };
  return {
    handleCheckboxClick: handleCheckboxClick
  };
};
export default useMultiValueLabelCheckbox;