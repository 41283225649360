import _extends from "@babel/runtime/helpers/extends";
import { theme } from "@vitality-ds/system";
export default (function (baseStyles) {
  return _extends({}, baseStyles, {
    boxSizing: "border-box",
    fontFamily: theme.fonts.textInput.value,
    fontSize: theme.fontSizes.textInput.value,
    lineHeight: theme.lineHeights.textInput.value,
    fontWeight: theme.fontWeights.textInput.value,
    letterSpacing: theme.letterSpacings.textInput.value,
    zIndex: theme.zIndices.portalElement.value,
    pointerEvents: "auto"
  });
});