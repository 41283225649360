import IconPrimitive from "@vitality-ds/icons";
import React from "react";

function Background(props) {
  return (
    <IconPrimitive {...props}>
      <path d="M19 11.5s-2 2.17-2 3.5a2 2 0 002 2 2 2 0 002-2c0-1.33-2-3.5-2-3.5M5.21 10L10 5.21 14.79 10m1.77-1.06L7.62 0 6.21 1.41l2.38 2.38-5.15 5.15c-.59.56-.59 1.53 0 2.12l5.5 5.5c.29.29.68.44 1.06.44s.77-.15 1.06-.44l5.5-5.5c.59-.59.59-1.56 0-2.12z" />
    </IconPrimitive>
  );
}
export default Background;
