import { BaseToken, Platforms } from "../types";

/**
 * string that represents the unit to be used with the font size.
 * as we base the sizes in `rem`, this can be used as a flag to
 * indicate to tools like Figma that they will need to convert to px
 */
export const SIZE_UNIT = "rem";

export type FontSizeValue = {
  /**
   * Font Size multipler in relative (rem) units.
   */
  size: number;
  sizeUnit?: typeof SIZE_UNIT;
  lineHeight: number;
  letterSpacing: number;
};

export interface FontSizeToken extends BaseToken {
  data: FontSizeValue;
}

// weightAsString = Figma requires the font name and weight in order to load the font
type FontWeightValue = {
  weightAsString: "Regular" | "Medium" | "Semibold" | "Bold";
  weight: number;
};

export interface FontWeightToken extends BaseToken {
  name: "regular" | "medium" | "semibold" | "bold";
  data: FontWeightValue;
}

export type FontFamilyTokens = {
  [platform in Platforms]: { [x in "sans" | "sansDisplay"]: FontFamilyToken };
};

export type FontSizeTokens = {
  [platform in Platforms]: { [x in FontSizeKeys]: FontSizeToken };
};

export type FontStyleValue = FontSizeValue &
  FontFamilyValue & {
    weight: number;
    weightAsString: string;
  };

type FontFamilyValue = {
  fontNameFigma: string;
  fontFamily: string;
};

export interface FontFamilyToken extends BaseToken {
  data: FontFamilyValue;
}

export interface FontStyleToken extends BaseToken {
  data: FontStyleValue;
}

export type FontSizeKeys =
  | "h100"
  | "h200"
  | "h300"
  | "h400"
  | "h500"
  | "h600"
  | "h700"
  | "h800"
  | "h900";

export type CommonFontStyleKeys =
  | "display100"
  | "display200"
  | "display300"
  | "display400"
  | "display500"
  | "display600"
  | "display700"
  | "display800"
  | "display900";

export type WebFontStyleKeys =
  | "body"
  | "caption"
  | "pageTitle"
  | "sectionTitle"
  | "sectionSubtitle"
  | "button"
  | "textInput"
  | CommonFontStyleKeys;

export type RnFontStyleKeys =
  | "microcopy"
  | "caption"
  | "body"
  | "sectionSubtitle"
  | "sectionTitle"
  | "pageTitle"
  | "navTitle"
  | "navTitleCompacted"
  | "button"
  | "textInput"
  | CommonFontStyleKeys;

type FontStyleKeys = WebFontStyleKeys | CommonFontStyleKeys;

export type FontStylesType = {
  web: { [styleName in WebFontStyleKeys]: FontStyleToken };
  reactNative: { [styleName in RnFontStyleKeys]: FontStyleToken };
};

export type ThemeFontStylesType = {
  FontFamilies: {
    web: {
      [key in FontStyleKeys | "default"]: string;
    };
    reactNative: {
      [key in RnFontStyleKeys | "default"]: string;
    };
  };
  FontSizes: {
    web: {
      [key in FontStyleKeys]: string;
    };
    reactNative: {
      [key in RnFontStyleKeys]: string;
    };
  };
  FontWeights: {
    web: {
      [key in FontStyleKeys]: number;
    };
    reactNative: {
      [key in RnFontStyleKeys]: number;
    };
  };
  LetterSpacings: {
    web: {
      [key in FontStyleKeys]: number;
    };
    reactNative: {
      [key in RnFontStyleKeys]: number;
    };
  };
  LineHeights: {
    web: {
      [key in FontStyleKeys]: number;
    };
    reactNative: {
      [key in RnFontStyleKeys]: number;
    };
  };
};
