import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["type"];
import getComponentFromLookup from "../../../helpers/logic/get-component-from-lookup";
import { getHasCheckableItems } from "../utils";
import DROPDOWN_SUB_MENU_ITEM_TYPES from "./constants";
var defaultLookup = "item";

// eslint-disable-next-line import/prefer-default-export
export var getDropdownSubMenuComponents = function getDropdownSubMenuComponents(items) {
  var hasCheckableItems = getHasCheckableItems(items);
  if (Array.isArray(items) && items.length !== 0) {
    return items.map(function (item, index) {
      var _item$type = item.type,
        type = _item$type === void 0 ? defaultLookup : _item$type,
        props = _objectWithoutProperties(item, _excluded);
      return getComponentFromLookup(DROPDOWN_SUB_MENU_ITEM_TYPES, type, defaultLookup, _extends({
        indentContent: hasCheckableItems,
        key: index
      }, props));
    });
  }
  return null;
};