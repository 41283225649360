import { Typography } from "@vitality-ds/components";
import { Link as GatsbyLink } from "gatsby";
import React from "react";

import ResultWrapper from "./styled";
import { ResultProps } from "./types";

function Result({
  page: { path, title, subTitle },
  ...restProps
}: ResultProps): JSX.Element {
  return (
    <ResultWrapper tabIndex={-1} {...restProps}>
      <GatsbyLink tabIndex={0} to={path} {...restProps}>
        <Typography color="primary" variant="display200">
          {title}
        </Typography>
        <Typography color="lowContrast">{subTitle}</Typography>
      </GatsbyLink>
    </ResultWrapper>
  );
}

export default Result;
