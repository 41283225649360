import { styled } from "@vitality-ds/system";

const ResultWrapper = styled("li", {
  padding: "$md",
  marginBottom: "$md",
  border: "1px solid transparent",
  borderRadius: "$default",
  "> a": {
    color: "inherit",
    textDecoration: "none",
  },
  "&:hover, &[data-focus='true']": {
    borderColor: "$greyA7",
    backgroundColor: "$grey1",
  },
  "&:active": {
    transform: "translateY(1px)",
  },
});

export default ResultWrapper;
