import { RadiiType } from "./types";

// eslint-disable-next-line import/prefer-default-export
export const Radii: RadiiType = {
  default: {
    name: "default",
    description: "The main shape radius we use",
    data: {
      value: 4,
    },
  },
  large: {
    name: "large",
    description: "Used for large container components",
    data: {
      value: 8,
    },
  },
  rounded: {
    name: "rounded",
    description:
      "Used for ensuring circular corners regarless of container width/height.",
    data: {
      value: 99999,
    },
  },
};
