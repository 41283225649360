import chipMultiValueStyles from "./components/Chip/MultiValue/styles/BaseMultiValue.styles";
import chipMultiValueLabelStyles from "./components/Chip/MultiValueLabel/styles/BaseMultiValueLabel.styles";
import controlStyles from "./styles/BaseControl.styles";
import groupStyles from "./styles/BaseGroup.styles";
import groupHeadingStyles from "./styles/BaseGroupHeading.styles";
import baseIndicatorsContainerStyles from "./styles/BaseIndicatorsContainer.styles";
import inputStyles from "./styles/BaseInput.styles";
import menuStyles from "./styles/BaseMenu.styles";
import menuListStyles from "./styles/BaseMenuList.styles";
import noOptionsMessageStyles from "./styles/BaseNoOptionsMessage.styles";
import optionStyles from "./styles/BaseOption.styles";
import placeholderStyles from "./styles/BasePlaceholder.styles";
import portalStyles from "./styles/BasePortal.styles";
import selectStyles from "./styles/BaseSelect.styles";
import valueContainerStyles from "./styles/BaseValueContainer.styles";
export var multiValueStyleOptions = {
  chip: {
    multiValue: function multiValue(baseStyles) {
      return chipMultiValueStyles(baseStyles);
    },
    multiValueLabel: function multiValueLabel(baseStyles) {
      return chipMultiValueLabelStyles(baseStyles);
    }
  }
};

// eslint-disable-next-line import/prefer-default-export

export var styles = {
  container: function container(baseStyles) {
    return selectStyles(baseStyles);
  },
  control: function control(baseStyles, state) {
    return controlStyles(baseStyles, state);
  },
  indicatorSeparator: function indicatorSeparator() {
    return {
      display: "none"
    };
  },
  menuList: function menuList(baseStyles) {
    return menuListStyles(baseStyles);
  },
  option: function option(baseStyles, state) {
    return optionStyles(baseStyles, state);
  },
  valueContainer: function valueContainer(baseStyles, state) {
    return valueContainerStyles(baseStyles, state);
  },
  placeholder: function placeholder(baseStyles, state) {
    return placeholderStyles(baseStyles, state);
  },
  noOptionsMessage: function noOptionsMessage(baseStyles) {
    return noOptionsMessageStyles(baseStyles);
  },
  loadingMessage: function loadingMessage(baseStyles) {
    return noOptionsMessageStyles(baseStyles);
  },
  groupHeading: function groupHeading(baseStyles) {
    return groupHeadingStyles(baseStyles);
  },
  group: function group(baseStyles) {
    return groupStyles(baseStyles);
  },
  input: function input(baseStyles, state) {
    return inputStyles(baseStyles, state);
  },
  menuPortal: function menuPortal(baseStyles) {
    return portalStyles(baseStyles);
  },
  menu: function menu(baseStyles) {
    return menuStyles(baseStyles);
  },
  indicatorsContainer: function indicatorsContainer(baseStyles) {
    return baseIndicatorsContainerStyles(baseStyles);
  }
};