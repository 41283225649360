import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";
var usePasswordVisible = function usePasswordVisible(value) {
  var _useState = useState(value),
    _useState2 = _slicedToArray(_useState, 2),
    passwordVisible = _useState2[0],
    setPasswordVisible = _useState2[1];
  return {
    passwordVisible: passwordVisible,
    setPasswordVisible: setPasswordVisible
  };
};
export default usePasswordVisible;