import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var borders = colorUseCases.borders;
export default css({
  borderColor: getColorScaleValueByUseCase("neutral", borders.separator),
  variants: {
    direction: {
      horizontal: {
        width: "100%",
        borderStyle: "solid none none"
      },
      vertical: {
        height: "100%",
        borderStyle: "none solid none"
      }
    },
    color: {
      onPrimaryBackground: {
        borderColor: getColorScaleValueByUseCase("primary", borders.separator)
      },
      neutral: {
        borderColor: getColorScaleValueByUseCase("neutral", borders.separator)
      }
    },
    size: {
      sm: {
        borderWidth: 0
      },
      md: {
        borderWidth: 0
      },
      lg: {
        borderWidth: 0
      }
    },
    spacing: {
      none: {
        margin: 0
      },
      sm: {
        margin: 0
      },
      md: {
        margin: 0
      },
      lg: {
        margin: 0
      }
    }
  },
  compoundVariants: [{
    direction: "horizontal",
    size: "sm",
    spacing: "none",
    css: {
      borderTopWidth: "1px",
      margin: 0
    }
  }, {
    direction: "horizontal",
    size: "sm",
    spacing: "sm",
    css: {
      borderTopWidth: "1px",
      margin: "$sm 0"
    }
  }, {
    direction: "horizontal",
    size: "sm",
    spacing: "md",
    css: {
      borderTopWidth: "1px",
      marginTop: "$md",
      marginBottom: "$md"
    }
  }, {
    direction: "horizontal",
    size: "sm",
    spacing: "lg",
    css: {
      borderTopWidth: "1px",
      marginTop: "$lg",
      marginBottom: "$lg"
    }
  }, {
    direction: "horizontal",
    size: "md",
    spacing: "none",
    css: {
      borderTopWidth: "2px",
      margin: 0
    }
  }, {
    direction: "horizontal",
    size: "md",
    spacing: "sm",
    css: {
      borderTopWidth: "2px",
      margin: "$sm 0"
    }
  }, {
    direction: "horizontal",
    size: "md",
    spacing: "md",
    css: {
      borderTopWidth: "2px",
      marginTop: "$md",
      marginBottom: "$md"
    }
  }, {
    direction: "horizontal",
    size: "md",
    spacing: "lg",
    css: {
      borderTopWidth: "2px",
      marginTop: "$lg",
      marginBottom: "$lg"
    }
  }, {
    direction: "horizontal",
    size: "lg",
    spacing: "none",
    css: {
      borderTopWidth: "$sm",
      margin: 0
    }
  }, {
    direction: "horizontal",
    size: "lg",
    spacing: "sm",
    css: {
      borderTopWidth: "$sm",
      margin: "$sm 0"
    }
  }, {
    direction: "horizontal",
    size: "lg",
    spacing: "md",
    css: {
      borderTopWidth: "$sm",
      marginTop: "$md",
      marginBottom: "$md"
    }
  }, {
    direction: "horizontal",
    size: "lg",
    spacing: "lg",
    css: {
      borderTopWidth: "$sm",
      marginTop: "$lg",
      marginBottom: "$lg"
    }
  }, {
    direction: "vertical",
    size: "sm",
    spacing: "none",
    css: {
      borderLeftWidth: "1px",
      margin: 0
    }
  }, {
    direction: "vertical",
    size: "sm",
    spacing: "sm",
    css: {
      borderLeftWidth: "1px",
      margin: "0 $sm"
    }
  }, {
    direction: "vertical",
    size: "sm",
    spacing: "md",
    css: {
      borderLeftWidth: "1px",
      marginLeft: "$md",
      marginRight: "$md"
    }
  }, {
    direction: "vertical",
    size: "sm",
    spacing: "lg",
    css: {
      borderLeftWidth: "1px",
      marginLeft: "$lg",
      marginRight: "$lg"
    }
  }, {
    direction: "vertical",
    size: "md",
    spacing: "none",
    css: {
      borderLeftWidth: "2px",
      margin: 0
    }
  }, {
    direction: "vertical",
    size: "md",
    spacing: "sm",
    css: {
      borderLeftWidth: "2px",
      margin: "0 $sm"
    }
  }, {
    direction: "vertical",
    size: "md",
    spacing: "md",
    css: {
      borderLeftWidth: "2px",
      marginLeft: "$md",
      marginRight: "$md"
    }
  }, {
    direction: "vertical",
    size: "md",
    spacing: "lg",
    css: {
      borderLeftWidth: "2px",
      marginLeft: "$lg",
      marginRight: "$lg"
    }
  }, {
    direction: "vertical",
    size: "lg",
    spacing: "none",
    css: {
      borderLeftWidth: "$sm",
      margin: 0
    }
  }, {
    direction: "vertical",
    size: "lg",
    spacing: "sm",
    css: {
      borderLeftWidth: "$sm",
      margin: "0 $sm"
    }
  }, {
    direction: "vertical",
    size: "lg",
    spacing: "md",
    css: {
      borderLeftWidth: "$sm",
      marginLeft: "$md",
      marginRight: "$md"
    }
  }, {
    direction: "vertical",
    size: "lg",
    spacing: "lg",
    css: {
      borderLeftWidth: "$sm",
      marginLeft: "$lg",
      marginRight: "$lg"
    }
  }]
});