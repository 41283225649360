/**
 * Figma requires fonts to have specific "Names" which are able to be read by Figma
 * https://www.figma.com/plugin-docs/api/FontName/
 *
 * These values should only be used for Figma
 */
// eslint-disable-next-line import/prefer-default-export
export const FIGMA_FRIENDLY_FONT_NAMES = {
  standard: "SF Pro Text",
  display: "SF Pro Display",
};
