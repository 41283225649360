import { css } from "@vitality-ds/system";
export default css({
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  gap: "$lg",
  flexWrap: "wrap",
  flexGrow: 1,
  "@bp1": {
    flexDirection: "row"
  }
});