import _extends from "@babel/runtime/helpers/extends";
import { colorUseCases, getColorScaleFromThemeObject, theme } from "@vitality-ds/system";
var borders = colorUseCases.borders,
  text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds;
var standardBorderColor = getColorScaleFromThemeObject("neutral", borders.uiElement, theme);
var activeBorderColor = getColorScaleFromThemeObject("primary", borders.uiElement_selected, theme);
var criticalBorderColor = getColorScaleFromThemeObject("critical", borders.uiElement_active, theme);
var getBorderColor = function getBorderColor(_ref) {
  var isDisabled = _ref.isDisabled,
    selectProps = _ref.selectProps;
  if (isDisabled) {
    return getColorScaleFromThemeObject("neutral", borders.uiElement_disabled, theme);
  }
  if (selectProps["aria-invalid"]) {
    return criticalBorderColor;
  }
  return standardBorderColor;
};
export default (function (baseStyles, state) {
  return _extends({}, baseStyles, {
    padding: "".concat(theme.space.xs.value, " ").concat(theme.space.md.value),
    borderRadius: theme.radii["default"].value,
    display: "flex",
    alignItems: "center",
    color: getColorScaleFromThemeObject("neutral", text.hiContrast, theme),
    minHeight: 32,
    background: state.isDisabled ? getColorScaleFromThemeObject("neutral", backgrounds.uiElement_disabled, theme) : getColorScaleFromThemeObject("neutral", backgrounds.app, theme),
    border: "1px solid",
    borderColor: getBorderColor(state),
    "&:hover:not(:active, :focus-within)": {
      borderColor: state.selectProps["aria-invalid"] === true ? criticalBorderColor : getColorScaleFromThemeObject("neutral", borders.uiElement_hovered, theme),
      backgroundColor: getColorScaleFromThemeObject("neutral", backgrounds.uiElement_hovered, theme)
    },
    "&:active, &:focus-within": {
      borderColor: state.selectProps["aria-invalid"] === true ? criticalBorderColor : activeBorderColor,
      boxShadow: "0 0 0 1px ".concat(state.selectProps["aria-invalid"] === true ? criticalBorderColor : activeBorderColor)
    }
  });
});