import { useMemo } from "react";
var useSidebarBar = function useSidebarBar(topOffset) {
  var style = useMemo(function () {
    return {
      marginTop: topOffset,
      minHeight: "calc(100vh - ".concat(topOffset, "px)")
    };
  }, [topOffset]);
  return {
    style: style
  };
};
export default useSidebarBar;