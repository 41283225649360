import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import { useMemo } from "react";
var useColumnSort = function useColumnSort(columns) {
  var sortFns = {};
  var defaultSortFunction = {};
  useMemo(function () {
    return columns.forEach(function (_ref) {
      var colId = _ref.colId,
        sortable = _ref.sortable,
        isDefaultSort = _ref.isDefaultSort;
      var defaultSortBy = typeof sortable === "boolean" ? function (a, b) {
        return a[colId] > b[colId] ? 1 : -1;
      } : sortable;
      sortFns = _extends({}, sortFns, _defineProperty({}, colId.toUpperCase(), sortable ? function (array) {
        return array.sort(defaultSortBy);
      } : null));
      defaultSortFunction = _extends({}, defaultSortFunction, isDefaultSort && sortable && {
        sortKey: colId.toUpperCase(),
        reverse: false
      });
    });
  }, [columns, sortFns, defaultSortFunction]);
  return {
    sortFns: sortFns,
    defaultSortFunction: defaultSortFunction
  };
};
export default useColumnSort;