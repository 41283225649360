import React from "react";
import { GlobalHotKeys } from "react-hotkeys";

import Results from "./components/Results";
import useSearchField from "./hooks/useSearchField";
import { Container, SearchInput } from "./styled";
import { SearchFieldProps } from "./types";

function SearchField({ searchIndex }: SearchFieldProps): JSX.Element {
  const {
    keyMap,
    handlers,
    query,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useSearchField(searchIndex);

  return (
    <Container {...getRootProps()}>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <SearchInput {...getInputProps()} />
      <Results
        getListboxProps={getListboxProps}
        getOptionProps={getOptionProps}
        groupedOptions={groupedOptions}
        query={query}
      />
    </Container>
  );
}

export default SearchField;
