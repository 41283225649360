import { FontSizeToken, ThemeFontStylesType } from "../../Font/types";
import { Platforms } from "../../types";
import { fontStylesTokens } from "../constants";
import commonGetter from "./commonGetter";

const getFontSizeValue = (
  { size, sizeUnit }: FontSizeToken["data"],
  platform: Platforms
) => {
  switch (platform) {
    case "web":
      return `${size}${sizeUnit}`;
    case "reactNative":
      return size;
    default:
      return size;
  }
};

export default <T extends Platforms>(platform: T) =>
  commonGetter({
    platform,
    tokens: fontStylesTokens[platform],
    getValue: getFontSizeValue,
  }) as ThemeFontStylesType["FontSizes"][T];
