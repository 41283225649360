import React from "react";
import IconPrimitive from "@vitality-ds/icons";

const StarMedium = (props) => (
  <IconPrimitive {...props}>
    <path
      d="M11.549 3.532a.502.502 0 01.903 0l2.39 4.868c.074.15.216.253.38.277l5.346.78c.413.06.578.57.28.863l-3.87 3.79a.507.507 0 00-.144.447l.913 5.35a.504.504 0 01-.73.534l-4.783-2.526a.501.501 0 00-.468 0L6.984 20.44a.504.504 0 01-.731-.534l.913-5.35a.507.507 0 00-.145-.448L3.153 10.32a.507.507 0 01.279-.863l5.346-.78a.504.504 0 00.38-.277l2.39-4.868z"
      fill="currentColor"
    />
  </IconPrimitive>
);

export default StarMedium;
