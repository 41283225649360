import { css } from "@vitality-ds/system";
import getVariantsByColorScale from "../helpers/getVariantsByColorScale";
export default css({
  $$size: "400px",
  width: "$$size",
  display: "flex",
  borderStyle: "solid",
  borderWidth: "4px 1px 1px 1px",
  borderRadius: "$default",
  boxShadow: "$lg",
  boxSizing: "border-box",
  padding: "$md",
  gap: "$sm",
  variants: {
    type: {
      success: getVariantsByColorScale("success"),
      warning: getVariantsByColorScale("warning"),
      error: getVariantsByColorScale("critical"),
      info: getVariantsByColorScale("info"),
      general: getVariantsByColorScale("neutral")
    }
  }
});