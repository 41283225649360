import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { debounce } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { handleIsOverflowingRecalculation, throttledIsOverflowingRecalculation } from "../logic";
var useIsOverflowing = function useIsOverflowing() {
  var overflowContainerRef = useRef();
  var _useState = useState(undefined),
    _useState2 = _slicedToArray(_useState, 2),
    isOverflowing = _useState2[0],
    setIsOverflowing = _useState2[1];
  useEffect(function () {
    var current = overflowContainerRef.current;
    handleIsOverflowingRecalculation(current, setIsOverflowing);
    if (typeof window === "undefined") return undefined;
    window.addEventListener("resize", throttledIsOverflowingRecalculation(debounce, current, setIsOverflowing));
    return function () {
      window.removeEventListener("resize", throttledIsOverflowingRecalculation(debounce, current, setIsOverflowing));
    };
  }, [overflowContainerRef]);
  return {
    overflowContainerRef: overflowContainerRef,
    isOverflowing: isOverflowing
  };
};
export default useIsOverflowing;