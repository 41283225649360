import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { css } from "@vitality-ds/system";
export default css(_defineProperty({
  display: "inline-flex",
  height: "$space$xl",
  alignItems: "center",
  fontSize: "$body",
  // reset browser list styles
  listStyleType: "none",
  margin: 0
}, "& svg", {
  marginTop: 0,
  marginLeft: "$sm",
  marginRight: "$sm"
}));