import type {
  BaseColors,
  ColorScale,
  StitchesColorScale,
  ThemeKeys,
} from "../../Color/types";

/**
 * Take a ColorScale and output it as an object representing the steps in`blue1: hsla(h, s, l, a)`
 * @returns all the colors in a scale as an object with its hslaString as the value
 */

export default <C extends BaseColors>(
  colorGroup: ColorScale<C>
): StitchesColorScale<C> => {
  const output: StitchesColorScale<C> = {} as StitchesColorScale<C>;

  Object.keys(colorGroup).forEach((colorName) => {
    const color = colorGroup[colorName as ThemeKeys];
    output[colorName] = color.data.hslaString;
  });

  return output;
};
