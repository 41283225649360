import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;

// eslint-disable-next-line import/prefer-default-export
export var BaseAdornmentTextStyles = css({
  color: getColorScaleValueByUseCase("neutral", text.lowContrast),
  fontFamily: "$caption",
  fontSize: "$caption",
  variants: {
    side: {
      start: {
        paddingLeft: "$sm"
      },
      end: {
        paddingRight: "$sm"
      }
    }
  }
});