import { Radii } from "../../Radii";
import { RadiusToken, ThemeRadiiType } from "../../Radii/types";
import { Platforms } from "../../types";
import appendPx from "../utils/appendPx";
import commonGetter from "./commonGetter";

const getLineHeightValue = (
  { value }: RadiusToken["data"],
  platform: Platforms
) => {
  switch (platform) {
    case "web":
      return appendPx(value);
    case "reactNative":
      return value;
    default:
      return value;
  }
};

export default <T extends Platforms>(platform: T): ThemeRadiiType<T> =>
  commonGetter({
    platform,
    tokens: Radii,
    getValue: getLineHeightValue,
  }) as ThemeRadiiType<T>;
