import FontFamilies from "./fontFamilies";
import FontSizes from "./fontSizes";
import FontWeights from "./fontWeights";
import { FontStylesType } from "./types";

export const CATEGORIES = {
  DISPLAY: "DISPLAY",
  UI: "UI",
};

const FontStyles: FontStylesType = {
  web: {
    body: {
      name: "Body",
      description: "Normal text for UI and body.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.regular.data.weightAsString,
        weight: FontWeights.regular.data.weight,
        size: FontSizes.web.h200.data.size,
        sizeUnit: FontSizes.web.h200.data.sizeUnit,
        lineHeight: FontSizes.web.h200.data.lineHeight,
        fontNameFigma: FontFamilies.web.sans.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h200.data.letterSpacing,
      },
    },
    caption: {
      name: "Caption",
      description: "Smaller, supporting text.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.regular.data.weightAsString,
        weight: FontWeights.regular.data.weight,
        size: FontSizes.web.h100.data.size,
        sizeUnit: FontSizes.web.h100.data.sizeUnit,
        lineHeight: FontSizes.web.h100.data.lineHeight,
        fontNameFigma: FontFamilies.web.sans.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h100.data.letterSpacing,
      },
    },
    pageTitle: {
      name: "Page Title",
      description: "💡 Used for page titles. Use only once per page.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.regular.data.weightAsString,
        weight: FontWeights.regular.data.weight,
        size: FontSizes.web.h500.data.size,
        sizeUnit: FontSizes.web.h500.data.sizeUnit,
        lineHeight: FontSizes.web.h500.data.lineHeight,
        fontNameFigma: FontFamilies.web.sans.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h500.data.letterSpacing,
      },
    },
    sectionTitle: {
      name: "Section Title",
      description: "💡 Used to title a section of a page or modal title.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        size: FontSizes.web.h400.data.size,
        sizeUnit: FontSizes.web.h400.data.sizeUnit,
        lineHeight: FontSizes.web.h400.data.lineHeight,
        fontNameFigma: FontFamilies.web.sans.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h400.data.letterSpacing,
      },
    },
    sectionSubtitle: {
      name: "Section Subtitle",
      description: "💡 Used to support section title.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        size: FontSizes.web.h300.data.size,
        sizeUnit: FontSizes.web.h300.data.sizeUnit,
        lineHeight: FontSizes.web.h300.data.lineHeight,
        fontNameFigma: FontFamilies.web.sans.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h300.data.letterSpacing,
      },
    },
    button: {
      name: "Button",
      description: "Used only for Button text.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.semibold.data.weightAsString,
        weight: FontWeights.semibold.data.weight,
        size: FontSizes.web.h200.data.size,
        sizeUnit: FontSizes.web.h200.data.sizeUnit,
        lineHeight: 1.85,
        fontNameFigma: FontFamilies.web.sans.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: 0,
      },
    },
    textInput: {
      name: "Text Input",
      description: "Used only for text inputs.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.regular.data.weightAsString,
        weight: FontWeights.regular.data.weight,
        size: FontSizes.web.h300.data.size,
        sizeUnit: FontSizes.web.h300.data.sizeUnit,
        lineHeight: FontSizes.web.h300.data.lineHeight,
        fontNameFigma: FontFamilies.web.sans.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: 0,
      },
    },
    display100: {
      name: "Display 100",
      description: "💡 Small text, usually supporting body text or labels.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.web.h100.data.size,
        sizeUnit: FontSizes.web.h100.data.sizeUnit,
        lineHeight: FontSizes.web.h100.data.lineHeight,
        weightAsString: FontWeights.bold.data.weightAsString,
        weight: FontWeights.bold.data.weight,
        fontNameFigma: FontFamilies.web.sans.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h100.data.letterSpacing,
      },
    },
    display200: {
      name: "Display 200",
      description: "💡 UI and paragraph text.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.web.h200.data.size,
        sizeUnit: FontSizes.web.h200.data.sizeUnit,
        lineHeight: FontSizes.web.h200.data.lineHeight,
        weightAsString: FontWeights.semibold.data.weightAsString,
        weight: FontWeights.semibold.data.weight,
        fontNameFigma: FontFamilies.web.sans.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h200.data.letterSpacing,
      },
    },
    display300: {
      name: "Display 300",
      description: "💡 Input field text size.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.web.h300.data.size,
        sizeUnit: FontSizes.web.h300.data.sizeUnit,
        lineHeight: FontSizes.web.h300.data.lineHeight,
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        fontNameFigma: FontFamilies.web.sans.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h300.data.letterSpacing,
      },
    },
    display400: {
      name: "Display 400",
      description: "💡 Subtitles, modal titles etc.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.web.h400.data.size,
        sizeUnit: FontSizes.web.h400.data.sizeUnit,
        lineHeight: FontSizes.web.h400.data.lineHeight,
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        fontNameFigma: FontFamilies.web.sans.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h400.data.letterSpacing,
      },
    },
    display500: {
      name: "Display 500",
      description: "💡 Oversized Screen titles. Use in moderation.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.web.h500.data.size,
        sizeUnit: FontSizes.web.h500.data.sizeUnit,
        lineHeight: FontSizes.web.h500.data.lineHeight,
        weightAsString: FontWeights.regular.data.weightAsString,
        weight: FontWeights.regular.data.weight,
        fontNameFigma: FontFamilies.web.sans.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h400.data.letterSpacing,
      },
    },
    display600: {
      name: "Display 600",
      description: "💡 Used for page titles. Use only once per page.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.web.h600.data.size,
        sizeUnit: FontSizes.web.h600.data.sizeUnit,
        lineHeight: FontSizes.web.h600.data.lineHeight,
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        fontNameFigma: FontFamilies.web.sansDisplay.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h600.data.letterSpacing,
      },
    },
    display700: {
      name: "Display 700",
      description: "Add description here.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.web.h700.data.size,
        sizeUnit: FontSizes.web.h700.data.sizeUnit,
        lineHeight: FontSizes.web.h700.data.lineHeight,
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        fontNameFigma: FontFamilies.web.sansDisplay.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h700.data.letterSpacing,
      },
    },
    display800: {
      name: "Display 800",
      description:
        "💡 Use for intentionally obtrusive text like fullscreen loading messages.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.web.h800.data.size,
        sizeUnit: FontSizes.web.h800.data.sizeUnit,
        lineHeight: FontSizes.web.h800.data.lineHeight,
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        fontNameFigma: FontFamilies.web.sansDisplay.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h800.data.letterSpacing,
      },
    },
    display900: {
      name: "Display 900",
      description: "💡 Oversized Screen titles. Use in moderation.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.web.h900.data.size,
        sizeUnit: FontSizes.web.h900.data.sizeUnit,
        lineHeight: FontSizes.web.h900.data.lineHeight,
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        fontNameFigma: FontFamilies.web.sansDisplay.data.fontNameFigma,
        fontFamily: FontFamilies.web.sans.data.fontFamily,
        letterSpacing: FontSizes.web.h900.data.letterSpacing,
      },
    },
  },
  reactNative: {
    microcopy: {
      name: "Microcopy",
      description: "Smaller, supporting text.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.regular.data.weightAsString,
        weight: FontWeights.regular.data.weight,
        size: FontSizes.reactNative.h100.data.size,
        sizeUnit: FontSizes.reactNative.h100.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h100.data.lineHeight,
        fontNameFigma: FontFamilies.reactNative.sans.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h100.data.letterSpacing,
      },
    },
    caption: {
      name: "Caption",
      description: "Smaller, supporting text.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.regular.data.weightAsString,
        weight: FontWeights.regular.data.weight,
        size: FontSizes.reactNative.h200.data.size,
        sizeUnit: FontSizes.reactNative.h200.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h200.data.lineHeight,
        fontNameFigma: FontFamilies.reactNative.sans.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h200.data.letterSpacing,
      },
    },
    body: {
      name: "Body",
      description: "Normal text for UI and body.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.regular.data.weightAsString,
        weight: FontWeights.regular.data.weight,
        size: FontSizes.reactNative.h300.data.size,
        sizeUnit: FontSizes.reactNative.h300.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h300.data.lineHeight,
        fontNameFigma: FontFamilies.reactNative.sans.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h300.data.letterSpacing,
      },
    },
    sectionSubtitle: {
      name: "Section Subtitle",
      description: "💡 Used to support section title.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.semibold.data.weightAsString,
        weight: FontWeights.semibold.data.weight,
        size: FontSizes.reactNative.h300.data.size,
        sizeUnit: FontSizes.reactNative.h300.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h300.data.lineHeight,
        fontNameFigma: FontFamilies.reactNative.sans.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sansDisplay.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h300.data.letterSpacing,
      },
    },
    sectionTitle: {
      name: "Section Title",
      description: "💡 Used to title a section of a page or modal title.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.semibold.data.weightAsString,
        weight: FontWeights.semibold.data.weight,
        size: FontSizes.reactNative.h400.data.size,
        sizeUnit: FontSizes.reactNative.h400.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h400.data.lineHeight,
        fontNameFigma: FontFamilies.reactNative.sansDisplay.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sansDisplay.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h400.data.letterSpacing,
      },
    },
    pageTitle: {
      name: "Page Title",
      description: "💡 Used for page titles. Use only once per page.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        size: FontSizes.reactNative.h500.data.size,
        sizeUnit: FontSizes.reactNative.h500.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h500.data.lineHeight,
        fontNameFigma: FontFamilies.reactNative.sansDisplay.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sansDisplay.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h500.data.letterSpacing,
      },
    },
    navTitle: {
      name: "Nav Title",
      description: "Used for showing Nav Titles",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.bold.data.weightAsString,
        weight: FontWeights.bold.data.weight,
        size: FontSizes.reactNative.h500.data.size,
        sizeUnit: FontSizes.reactNative.h500.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h500.data.lineHeight,
        fontNameFigma: FontFamilies.reactNative.sansDisplay.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sansDisplay.data.fontFamily,
        letterSpacing: 0,
      },
    },
    navTitleCompacted: {
      name: "Nav Title (Compacted)",
      description: "Used for showing Nav Titles when compacted",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.regular.data.weightAsString,
        weight: FontWeights.regular.data.weight,
        size: FontSizes.reactNative.h300.data.size,
        sizeUnit: FontSizes.reactNative.h300.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h300.data.lineHeight,
        fontNameFigma: FontFamilies.reactNative.sans.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: 0,
      },
    },
    button: {
      name: "Button",
      description: "Used only for Button text.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.semibold.data.weightAsString,
        weight: FontWeights.semibold.data.weight,
        size: FontSizes.reactNative.h200.data.size,
        sizeUnit: FontSizes.reactNative.h200.data.sizeUnit,
        lineHeight: 30,
        fontNameFigma: FontFamilies.reactNative.sans.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: 0,
      },
    },
    textInput: {
      name: "Text Input",
      description: "Used only for text inputs.",
      category: CATEGORIES.UI,
      data: {
        weightAsString: FontWeights.regular.data.weightAsString,
        weight: FontWeights.regular.data.weight,
        size: FontSizes.reactNative.h300.data.size,
        sizeUnit: FontSizes.reactNative.h300.data.sizeUnit,
        lineHeight: 19,
        fontNameFigma: FontFamilies.reactNative.sans.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: 0,
      },
    },
    display100: {
      name: "Display 100",
      description: "💡 Small text, usually supporting body text or labels.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.reactNative.h100.data.size,
        sizeUnit: FontSizes.reactNative.h100.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h100.data.lineHeight,
        weightAsString: FontWeights.bold.data.weightAsString,
        weight: FontWeights.bold.data.weight,
        fontNameFigma: FontFamilies.reactNative.sans.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h100.data.letterSpacing,
      },
    },
    display200: {
      name: "Display 200",
      description: "💡 UI and paragraph text.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.reactNative.h200.data.size,
        sizeUnit: FontSizes.reactNative.h200.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h200.data.lineHeight,
        weightAsString: FontWeights.semibold.data.weightAsString,
        weight: FontWeights.semibold.data.weight,
        fontNameFigma: FontFamilies.reactNative.sans.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h200.data.letterSpacing,
      },
    },
    display300: {
      name: "Display 300",
      description: "💡 Input field text size.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.reactNative.h300.data.size,
        sizeUnit: FontSizes.reactNative.h300.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h300.data.lineHeight,
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        fontNameFigma: FontFamilies.reactNative.sans.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h300.data.letterSpacing,
      },
    },
    display400: {
      name: "Display 400",
      description: "💡 Subtitles, modal titles etc.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.reactNative.h400.data.size,
        sizeUnit: FontSizes.reactNative.h400.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h400.data.lineHeight,
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        fontNameFigma: FontFamilies.reactNative.sans.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h400.data.letterSpacing,
      },
    },
    display500: {
      name: "Display 500",
      description: "💡 Oversized Screen titles. Use in moderation.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.reactNative.h500.data.size,
        sizeUnit: FontSizes.reactNative.h500.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h500.data.lineHeight,
        weightAsString: FontWeights.regular.data.weightAsString,
        weight: FontWeights.regular.data.weight,
        fontNameFigma: FontFamilies.reactNative.sans.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h400.data.letterSpacing,
      },
    },
    display600: {
      name: "Display 600",
      description: "💡 Used for page titles. Use only once per page.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.reactNative.h600.data.size,
        sizeUnit: FontSizes.reactNative.h600.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h600.data.lineHeight,
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        fontNameFigma: FontFamilies.reactNative.sansDisplay.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h600.data.letterSpacing,
      },
    },
    display700: {
      name: "Display 700",
      description: "Add description here.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.reactNative.h700.data.size,
        sizeUnit: FontSizes.reactNative.h700.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h700.data.lineHeight,
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        fontNameFigma: FontFamilies.reactNative.sansDisplay.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h700.data.letterSpacing,
      },
    },
    display800: {
      name: "Display 800",
      description:
        "💡 Use for intentionally obtrusive text like fullscreen loading messages.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.reactNative.h800.data.size,
        sizeUnit: FontSizes.reactNative.h800.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h800.data.lineHeight,
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        fontNameFigma: FontFamilies.reactNative.sansDisplay.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h800.data.letterSpacing,
      },
    },
    display900: {
      name: "Display 900",
      description: "💡 Oversized Screen titles. Use in moderation.",
      category: CATEGORIES.DISPLAY,
      data: {
        size: FontSizes.reactNative.h900.data.size,
        sizeUnit: FontSizes.reactNative.h900.data.sizeUnit,
        lineHeight: FontSizes.reactNative.h900.data.lineHeight,
        weightAsString: FontWeights.medium.data.weightAsString,
        weight: FontWeights.medium.data.weight,
        fontNameFigma: FontFamilies.reactNative.sansDisplay.data.fontNameFigma,
        fontFamily: FontFamilies.reactNative.sans.data.fontFamily,
        letterSpacing: FontSizes.reactNative.h900.data.letterSpacing,
      },
    },
  },
};

export default FontStyles;
