import { debounce } from "lodash";
import { useMemo } from "react";
var useMemoizedDebouncedSearch = function useMemoizedDebouncedSearch(_ref) {
  var requestDelayMs = _ref.requestDelayMs,
    onSearch = _ref.onSearch;
  var memoizedDebouncedSearch = useMemo(function () {
    return debounce(function (inputText, callback) {
      Promise.resolve(onSearch(inputText)).then(function (receivedOptions) {
        return callback(receivedOptions);
      })["catch"](function (error) {
        return callback(error);
      });
    }, requestDelayMs);
  }, [requestDelayMs, onSearch]);
  return {
    memoizedDebouncedSearch: memoizedDebouncedSearch
  };
};
export default useMemoizedDebouncedSearch;