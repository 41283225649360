import { TextInput } from "@vitality-ds/components";
import { styled } from "@vitality-ds/system";
import React from "react";

import DocsFlex from "../../../DocsFlex";
import { INPUT_WIDTH } from "./constants";
import { SearchInputType } from "./types";

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputType>(
  ({ onChange, value, ...restProps }: SearchInputType, ref) => {
    return (
      <DocsFlex align="center" justify="stretch" css={{ flex: 1 }}>
        <TextInput
          ref={ref}
          onChange={onChange}
          name="docsSearch"
          value={value}
          placeholder="Search the docs"
          adornEnd={{ type: "shortcut", props: { keys: ["⌘", "K"] } }}
          {...restProps}
        />
      </DocsFlex>
    );
  }
);

SearchInput.displayName = "SearchInput";

export const Container = styled("div", {
  display: "none",
  "@bp1": { display: "flex" },
  alignSelf: "stretch",
  position: "relative",
  width: INPUT_WIDTH,
});
