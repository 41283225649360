import React from "react";
import IconPrimitive from "@vitality-ds/icons";

const CleanHands = (props) => (
  <IconPrimitive {...props}>
    <path
      d="M17.25 3l-1.083 3.845L14 7.976l2.167 1.131L17.25 12.5l1.083-3.393 2.167-1.13-2.167-1.132L17.25 3zM10 6l-.5 2-1.5.5 1.5.5.5 2 .5-2 1.5-.5-1.5-.5-.5-2zM3 15a.5.5 0 01.5-.5H5a.5.5 0 01.5.5v5.498a.5.5 0 01-.5.5H3.5a.5.5 0 01-.5-.5V15zM8.853 14.594c.466-.212 1.604 0 1.604 0l3.313.744s.829.265 1.605.265c.777 0 .313 1.063-.207 1.434-.52.37-.897.35-.897.35l-2.675-.032s2.33.425 3.21.478c.446.027 1.21-.286 2.065-.637.832-.342 1.75-.718 2.542-.85 1.605-.265 1.864 1.169 1.346 1.7-.518.53-4.659 2.548-5.384 2.814-.567.208-1.254.139-2.028.061a27.05 27.05 0 00-.664-.061c-.904-.069-1.595-.288-2.28-.506-.733-.233-1.459-.464-2.43-.503-.326-.013-1.09.027-1.473.05v-4.43c.663-.236 2.006-.719 2.353-.877z"
      fill="currentColor"
    />
  </IconPrimitive>
);

export default CleanHands;
