import { SelectTypes, useRowSelect } from "@table-library/react-table-library/select";
import { SelectClickTypes } from "@table-library/react-table-library/types";
import { useEffect, useMemo } from "react";
var useSelectRowLogic = function useSelectRowLogic(tableData, onSelectChange, defaultActiveRow) {
  var defaultSelectedRows = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var selectLogic = useRowSelect(tableData, {
    state: {
      id: defaultActiveRow,
      ids: defaultSelectedRows
    },
    onChange: onSelectChange
  }, {
    rowSelect: SelectTypes.SingleSelect,
    buttonSelect: SelectTypes.SingleSelect,
    clickType: SelectClickTypes.ButtonClick
  });
  var tableRowIds = useMemo(function () {
    return tableData.nodes.map(function (node) {
      return node.id;
    });
  }, [tableData.nodes]);
  useEffect(function () {
    // Remove rows from selection if they no longer exist in tableData
    var deletedIds = selectLogic.state.ids.filter(function (rowId) {
      return !tableRowIds.includes(rowId);
    });
    if (deletedIds.length) {
      selectLogic.fns.onRemoveByIds(deletedIds);
    }
  }, [tableRowIds, selectLogic]);
  return selectLogic;
};
export default useSelectRowLogic;