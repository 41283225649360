/* eslint-disable-next-line import/prefer-default-export */
export var MASK_APPEARANCES = {
  "default": {
    mask: false
  },
  medicare: {
    placeholder: "____ _____ _",
    guide: true,
    mask: [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, " ", /\d/]
  },
  creditCard: {
    guide: true,
    mask: [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/],
    placeholder: "4242 4242 4242 4242"
  },
  date: {
    guide: true,
    mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
    placeholder: "01/01/1970"
  },
  durationMinutes: {
    guide: false,
    mask: [/[1-9]/, /\d/, /\d/, /\d/]
  },
  bankBsb: {
    mask: [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]
  },
  ausPostCode: {
    mask: [/\d/, /\d/, /\d/, /\d/],
    placeholder: "____"
  },
  ausPhone: {
    placeholder: "(__) ____ ____",
    guide: false,
    mask: ["(", /[0-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/]
  }
};