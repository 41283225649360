import { css } from "@vitality-ds/system";
export default css({
  display: "flex",
  flexDirection: "column",
  border: 0,
  paddingBlock: "$sm",
  gap: "$md",
  variants: {
    direction: {
      horizontal: {
        flexDirection: "row",
        flexWrap: "wrap"
      }
    }
  }
});