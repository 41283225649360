import { css } from "@vitality-ds/system";

// eslint-disable-next-line import/prefer-default-export
export var BaseIconButtonWrapper = css({
  // @Todo When we have the primitives package, this should become an internal variant
  button: {
    padding: 0,
    $$size: "24px",
    height: "$$size",
    width: "$$size"
  },
  svg: {
    $$size: "22px",
    height: "$$size",
    width: "$$size"
  }
});