import { css } from "@stitches/react";
import { colorUseCases, getColorScaleValueByUseCase } from "@vitality-ds/system";
import { compactMinHeight, defaultMinHeight } from "../../../../styles/BaseCell.styles";
var borders = colorUseCases.borders;
export default css({
  display: "flex",
  flexWrap: "wrap",
  alignContent: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  borderBottom: "1px solid ".concat(getColorScaleValueByUseCase("neutral", borders.uiElement)),
  height: defaultMinHeight * 2,
  variants: {
    size: {
      "default": {
        height: defaultMinHeight * 2
      },
      compact: {
        height: compactMinHeight * 2
      }
    }
  }
});