import {
  colorUseCases,
  getColorScaleValueByUseCase,
  styled,
} from "@vitality-ds/system";

import DocsBox from "../../../DocsBox";
import { HEADER_HEIGHT } from "../../../Header/constants";
import { SIDEBAR_WIDTH } from "../../constants";

const { backgrounds } = colorUseCases;

export const SIDEBAR_TRANSITION_TIMING = "350ms ease";

export const Overlay = styled(DocsBox, {
  position: "fixed",
  height: "100vh",
  width: "100vw",
  backgroundColor: "rgba(0,0,0,0.3)",
  top: HEADER_HEIGHT,
  left: 0,
  zIndex: 1,
  "@bp1": {
    display: "none",
  },
});

export const Container = styled("nav", {
  display: "block",
  position: "fixed",
  top: HEADER_HEIGHT,
  width: SIDEBAR_WIDTH,
  boxSizing: "border-box",
  height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  padding: "$md",
  marginRight: "calc($xxl * 2)",
  paddingBottom: "$xl",
  backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.app),
  "@bp1": {
    backgroundColor: "transparent",
    position: "sticky",
  },
  fontSize: "$body",
  zIndex: 1,
  overscrollBehavior: "contain",
  left: 0,
  overflowY: "auto",
  transition: `left ${SIDEBAR_TRANSITION_TIMING}`,

  variants: {
    isOpen: {
      true: {
        boxShadow: "$large",
        left: 0,
        "@bp1": {
          boxShadow: "none",
          display: "block",
        },
      },
      false: {
        left: -SIDEBAR_WIDTH,
      },
    },
  },
});
