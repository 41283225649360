import { css } from "@vitality-ds/system";
import CHECKED_ANIMATION from "./BaseChipCheckboxIcon.keyframes";
export var ANIMATION_DURATION = "1s ease-in-out";
export default css({
  $$size: "12px",
  width: "$$size",
  height: "$$size",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  variants: {
    state: {
      UNCHECKED: {},
      LOADING: {},
      CHECKED: {
        animation: "".concat(CHECKED_ANIMATION, " ").concat(ANIMATION_DURATION)
      }
    }
  }
});