import { styled } from "@vitality-ds/system";

import DocsFlex from "../DocsFlex";
import { FOOTER_HEIGHT } from "../Footer/constants";
import { HEADER_HEIGHT } from "../Header/constants";

export const ContainerFlex = styled(DocsFlex, {
  paddingTop: HEADER_HEIGHT,
});

export const MainWrapperFlex = styled(DocsFlex, {
  minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  position: "relative",
});

export const MainFlex = styled(DocsFlex, {
  flex: 1,
  paddingBottom: FOOTER_HEIGHT,
  color: "$greyA12",
  width: "100%",
  maxWidth: 1440,
  paddingInline: "$xxl",
  marginInline: "auto",
});
