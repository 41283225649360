import { DateTime } from "luxon";
import { APPLICATION_ICON_TRIGGERS, BRAND_ICONS, TITLES_TO_IGNORE } from "./constants";
export var getTimes = function getTimes(timestamp) {
  if (!timestamp) {
    return {
      date: null,
      time: null,
      humanTime: null,
      invalidTime: null
    };
  }
  var dateTime = DateTime.fromISO(timestamp.replace(" ", "T"));
  var date = dateTime.toLocaleString();
  var time = dateTime.toFormat("tt", {
    locale: "en-US"
  });
  var humanTime = dateTime.toRelative();
  return {
    date: date,
    time: time,
    humanTime: humanTime,
    invalidTime: dateTime.invalid !== null
  };
};
export var getBrand = function getBrand(name) {
  var _name$split$filter$;
  var brand = name && ((_name$split$filter$ = name.split(" ").filter(function (word) {
    return APPLICATION_ICON_TRIGGERS.includes(word.toLowerCase());
  })[0]) === null || _name$split$filter$ === void 0 ? void 0 : _name$split$filter$.toLowerCase());
  return BRAND_ICONS[brand];
};
export var getInitials = function getInitials(name, forcedInitials) {
  if (forcedInitials) {
    return forcedInitials;
  }
  if (!name) {
    return null;
  }
  var initials = name.split(" ").filter(function (word) {
    return word && !TITLES_TO_IGNORE.includes(word.toLowerCase());
  }).map(function (word) {
    return word && word[0].toUpperCase();
  }).join("");
  if (initials.length > 2) {
    return null;
  }
  return initials;
};