import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useReducer } from "react";
var useForceUpdate = function useForceUpdate() {
  /**
   * Because of how React-Select handles updates, we need to force the
   * component to re-render after handleCheckboxClick() fires
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  var _useReducer = useReducer(function (x) {
      return x + 1;
    }, 0),
    _useReducer2 = _slicedToArray(_useReducer, 2),
    cacheBreak = _useReducer2[0],
    forceUpdate = _useReducer2[1];
  return [forceUpdate, cacheBreak];
};
export default useForceUpdate;