import _extends from "@babel/runtime/helpers/extends";
import { theme } from "@vitality-ds/system";
export default (function (baseStyles, _ref) {
  var isMulti = _ref.isMulti;
  return _extends({}, baseStyles, {
    padding: "0 ".concat(theme.space.sm),
    marginLeft: isMulti && -8,
    gap: isMulti && "2px 4px",
    input: {
      marginLeft: isMulti && 8
    }
  });
});