export var MAX_TOASTS = 3;
export var TIME_ENTER = 500;
export var TIME_WAIT = 1000;
export var TIME_EXIT = 1000;
export var TIME_DISPLAY = 5000;
export var TOAST_STATES = {
  pending: 0,
  enter: 1,
  display: 2,
  exit: 3,
  dead: 4
};