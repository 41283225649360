var BASE_RADIX = 10;
export var convertStringToNum = function convertStringToNum(value) {
  if (typeof value !== "string" || !value) {
    return null;
  }
  return parseInt(value, BASE_RADIX);
};
export var rowsPerPageSelectOptions = function rowsPerPageSelectOptions(rowsPerPageOptions) {
  if (!rowsPerPageOptions) {
    return [];
  }
  return rowsPerPageOptions.map(function (option) {
    return {
      label: option.toString(),
      value: option.toString()
    };
  });
};