import { FontWeightToken } from "./types";

/*
  weightAsString = the name of the font weight for Figma to understand
  https://www.figma.com/plugin-docs/api/FontName/
*/

const FontWeights: { [x: string]: FontWeightToken } = {
  regular: {
    name: "regular",
    description: "Regular font weight used with the default text.",
    data: { weightAsString: "Regular", weight: 400 },
  },
  medium: {
    name: "medium",
    description: "Medium font weight used with some headings.",
    data: { weightAsString: "Medium", weight: 500 },
  },
  semibold: {
    name: "semibold",
    description: "Medium font weight used with some headings.",
    data: { weightAsString: "Semibold", weight: 600 },
  },
  bold: {
    name: "bold",
    description: "Bold font weight used with the default text.",
    data: { weightAsString: "Bold", weight: 700 },
  },
};

export default FontWeights;
