import _extends from "@babel/runtime/helpers/extends";
import { css } from "@vitality-ds/system";
import { avatarColorVariants } from "../../../../Avatar/styles/BaseAvatarContainer.styles";
export default css(_extends({
  $$size: "24px",
  // for some reason setting this as just the number breaks it :?
  minWidth: "$$size",
  minHeight: "$$size",
  borderRadius: "$rounded",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}, avatarColorVariants.primary, {
  "& > svg": {
    marginTop: 0 // @todo need to review the random -mt on all icons
  }
}));