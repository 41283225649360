export var getStyles = function getStyles(element) {
  var styles = getComputedStyle(element);
  var paddingTop = styles.paddingTop,
    paddingBottom = styles.paddingBottom,
    lineHeight = styles.lineHeight,
    borderTopWidth = styles.borderTopWidth,
    borderBottomWidth = styles.borderBottomWidth;
  return {
    paddingTop: paddingTop,
    paddingBottom: paddingBottom,
    lineHeight: lineHeight,
    borderTopWidth: borderTopWidth,
    borderBottomWidth: borderBottomWidth
  };
};
export var convertStringToNumber = function convertStringToNumber(value) {
  // handles values like "24px"
  var returnNumber = Math.floor(parseFloat(value));
  if (Number.isNaN(returnNumber)) return 0;
  return returnNumber;
};
export var convertRowsToHeight = function convertRowsToHeight(textareaRef, rows) {
  var _getStyles = getStyles(textareaRef),
    paddingTop = _getStyles.paddingTop,
    paddingBottom = _getStyles.paddingBottom,
    lineHeight = _getStyles.lineHeight,
    borderTopWidth = _getStyles.borderTopWidth,
    borderBottomWidth = _getStyles.borderBottomWidth;
  var numericPaddingY = convertStringToNumber(paddingTop) + convertStringToNumber(paddingBottom);
  var numericBorderWidth = convertStringToNumber(borderTopWidth) + convertStringToNumber(borderBottomWidth);
  var numericLineHeight = convertStringToNumber(lineHeight);
  // calculate textarea height
  var contentHeight = Math.round(rows * numericLineHeight);
  var textareaHeight = Math.round(contentHeight + numericPaddingY + numericBorderWidth);
  return textareaHeight;
};
export var smartResize = function smartResize(_ref) {
  var textareaRef = _ref.textareaRef,
    _ref$minRows = _ref.minRows,
    minRows = _ref$minRows === void 0 ? 4 : _ref$minRows,
    _ref$maxRows = _ref.maxRows,
    maxRows = _ref$maxRows === void 0 ? 20 : _ref$maxRows;
  if (textareaRef.current) {
    // temporarily 0 out height for accurate scrollHeight
    textareaRef.current.style.height = "0px";
    var scrollHeight = textareaRef.current.scrollHeight;
    var minHeight = convertRowsToHeight(textareaRef.current, minRows);
    var maxHeight = convertRowsToHeight(textareaRef.current, maxRows);
    var _getStyles2 = getStyles(textareaRef.current),
      borderTopWidth = _getStyles2.borderTopWidth,
      borderBottomWidth = _getStyles2.borderBottomWidth;
    var borderWidth = Math.round(convertStringToNumber(borderTopWidth) + convertStringToNumber(borderBottomWidth));
    var styledScrollHeight = Math.ceil(scrollHeight + borderWidth);
    if (scrollHeight <= minHeight) {
      textareaRef.current.style.height = "".concat(minHeight, "px");
    } else if (styledScrollHeight < maxHeight) {
      textareaRef.current.style.height = "".concat(styledScrollHeight, "px");
    } else if (styledScrollHeight >= maxHeight) {
      textareaRef.current.style.height = "".concat(maxHeight, "px");
    }
  }
};
export var manualResize = function manualResize(_ref2) {
  var textareaRef = _ref2.textareaRef,
    _ref2$minRows = _ref2.minRows,
    minRows = _ref2$minRows === void 0 ? 4 : _ref2$minRows,
    _ref2$maxRows = _ref2.maxRows,
    maxRows = _ref2$maxRows === void 0 ? 20 : _ref2$maxRows;
  var minHeight = convertRowsToHeight(textareaRef.current, minRows);
  var maxHeight = convertRowsToHeight(textareaRef.current, maxRows);
  textareaRef.current.style.height = "".concat(minHeight, "px");
  textareaRef.current.style.maxHeight = "".concat(maxHeight, "px");
};