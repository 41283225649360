import ButtonAdornment from "../ButtonAdornment";
import IconAdornment from "../IconAdornment";
import IconButtonAdornment from "../IconButtonAdornment";
import ShortcutAdornment from "../ShortcutAdornment";
import SpinnerAdornment from "../SpinnerAdornment";
import TextAdornment from "../TextAdornment";

// eslint-disable-next-line import/prefer-default-export
export var ADORNMENT_TYPES = {
  "default": TextAdornment,
  text: TextAdornment,
  icon: IconAdornment,
  iconButton: IconButtonAdornment,
  button: ButtonAdornment,
  shortcut: ShortcutAdornment,
  spinner: SpinnerAdornment
};