import IconPrimitive from "@vitality-ds/icons";
import React from "react";

function Info(props) {
  return (
    <IconPrimitive {...props}>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12zm11-1v6h-2v-6h2zm-1 9c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13v2h-2V7h2z"
        clipRule="evenodd"
      />
    </IconPrimitive>
  );
}
export default Info;
