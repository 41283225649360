import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _extends from "@babel/runtime/helpers/extends";
var _css;
import { BaseIcon } from "@vitality-ds/icons";
import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import { AVAILABLE_MAX_LINES, AVAILABLE_VARIANTS } from "../constants";
import { getMaxLines, getMaxLinesVariants, getVariantStyles } from "./helpers";
var text = colorUseCases.text;
var overflowSharedStyles = {
  overflow: "hidden"
};
export default css((_css = {
  fontFamily: "$default",
  fontSize: "inherit",
  // Reset browser default margins added to certain semantic HTML elements
  marginBlock: 0
}, _defineProperty(_css, "& > ".concat(BaseIcon), {
  // Aligning Icons to text baseline = most Icons have 2px inner bottom spacing -> 2/24 * 2 = 0.2 rounded up
  marginTop: "-.2em"
}), _defineProperty(_css, "variants", {
  /**
   * Set the text element's text alignment
   */
  textAlign: {
    start: {
      textAlign: "start"
    },
    end: {
      textAlign: "end"
    },
    left: {
      textAlign: "left"
    },
    right: {
      textAlign: "right"
    },
    center: {
      textAlign: "center"
    },
    justify: {
      textAlign: "justify"
    },
    matchParent: {
      textAlign: "match-parent"
    }
  },
  /**
   * Allows you to handle CSS text overflow behaviour
   */
  textOverflow: {
    ellipsis: _extends({}, overflowSharedStyles, {
      textOverflow: "ellipsis"
    }),
    clip: _extends({}, overflowSharedStyles, {
      textOverflow: "clip"
    })
  },
  /**
   * Allows you to handle CSS white space behaviour
   */
  wrap: {
    "true": _extends({}, overflowSharedStyles, {
      whiteSpace: "break-spaces"
    }),
    "false": _extends({}, overflowSharedStyles, {
      whiteSpace: "nowrap"
    })
  },
  /**
   * Sets the color of the text.
   */
  color: {
    inherit: {
      color: "inherit"
    },
    hiContrast: {
      color: getColorScaleValueByUseCase("greyA", text.hiContrast)
    },
    primary: {
      color: getColorScaleValueByUseCase("primary", text.hiSaturation)
    },
    lowContrast: {
      color: getColorScaleValueByUseCase("greyA", text.lowContrast)
    },
    disabled: {
      color: getColorScaleValueByUseCase("greyA", text.disabled)
    },
    accent: {
      color: getColorScaleValueByUseCase("accent", text.hiSaturation)
    },
    info: {
      color: getColorScaleValueByUseCase("info", text.hiSaturation)
    },
    moderate: {
      color: getColorScaleValueByUseCase("yellow", text.hiSaturation)
    },
    critical: {
      color: getColorScaleValueByUseCase("critical", text.hiSaturation)
    },
    warning: {
      color: getColorScaleValueByUseCase("warning", text.hiSaturation)
    },
    success: {
      color: getColorScaleValueByUseCase("success", text.hiSaturation)
    }
  },
  /**
   * Customises the font size and weight to a pre-set option.
   */
  variant: {
    inherit: getVariantStyles("inherit"),
    caption: getVariantStyles("$caption"),
    button: getVariantStyles("$button"),
    body: getVariantStyles("$body"),
    paragraph: _extends({}, getVariantStyles("$body"), {
      "& + &": {
        marginTop: "$md"
      }
    }),
    sectionSubtitle: getVariantStyles("$sectionSubtitle"),
    sectionTitle: getVariantStyles("$sectionTitle"),
    pageTitle: getVariantStyles("$pageTitle"),
    display100: getVariantStyles("$display100"),
    display200: getVariantStyles("$display200"),
    display300: getVariantStyles("$display300"),
    display400: getVariantStyles("$display400"),
    display500: getVariantStyles("$display500"),
    display600: getVariantStyles("$display600"),
    display700: getVariantStyles("$display700"),
    display800: getVariantStyles("$display800"),
    display900: getVariantStyles("$display900")
  },
  /**
   * Sets a limit on the maximum number of lines to display.
   */
  maxLines: {
    1: getMaxLines(1, "$body"),
    2: getMaxLines(2, "$body"),
    3: getMaxLines(3, "$body")
  }
}), _defineProperty(_css, "compoundVariants", _toConsumableArray(getMaxLinesVariants(AVAILABLE_MAX_LINES, AVAILABLE_VARIANTS))), _defineProperty(_css, "ul, ol", {
  listStylePosition: "inside",
  paddingInlineStart: 0,
  marginBlock: "inherit"
}), _css));