import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useMemo, useState } from "react";
var useCheckedItems = function useCheckedItems(value) {
  var _useState = useState(value),
    _useState2 = _slicedToArray(_useState, 2),
    checkedItems = _useState2[0],
    setCheckedItems = _useState2[1];
  return useMemo(function () {
    return {
      checkedItems: checkedItems,
      setCheckedItems: setCheckedItems
    };
  }, [checkedItems, setCheckedItems]);
};
export default useCheckedItems;