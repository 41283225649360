import React from "react";

import HomePageHeader from "./components/HomePageHeader";
import StandardHeader from "./components/StandardHeader";
import { HeaderProps } from "./types";

function Header({
  constrainWidth,
  toggleSidebarOpen,
  isHomePage,
  sidebarOpen,
}: HeaderProps): JSX.Element {
  if (isHomePage) {
    return <HomePageHeader />;
  }

  return (
    <StandardHeader
      constrainWidth={constrainWidth}
      sidebarOpen={sidebarOpen}
      toggleSidebarOpen={toggleSidebarOpen}
    />
  );
}
export default Header;
