import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useMemo, useState } from "react";
import { convertISOToAusDate, createEventObject, handleOnInteractOutside } from "../logic";
var useDatePicker = function useDatePicker(onChange, onSelectedDateChange, value) {
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setIsOpen = _useState2[1];
  var _useState3 = useState(value && convertISOToAusDate(value)),
    _useState4 = _slicedToArray(_useState3, 2),
    textValue = _useState4[0],
    setTextValue = _useState4[1];
  useMemo(function () {
    if (!(textValue !== null && textValue !== void 0 && textValue.includes("_")) && value !== "") {
      setTextValue(convertISOToAusDate(value));
    }
  }, [value]);
  var onCalendarCallbackValueChange = useCallback(function (newValue) {
    setIsOpen(false);
    setTextValue(newValue);
    onChange(createEventObject(newValue));
    onSelectedDateChange();
  }, [onChange]);
  var onTextInputValueChange = function onTextInputValueChange(event) {
    onChange(createEventObject(event.target.value));
    setTextValue(event.target.value);
  };
  for (var _len = arguments.length, allowedElementIds = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
    allowedElementIds[_key - 3] = arguments[_key];
  }
  var onInteractOutside = handleOnInteractOutside.apply(void 0, [setIsOpen].concat(allowedElementIds));
  var onIconButtonClick = function onIconButtonClick(e) {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };
  var onInputFocus = function onInputFocus() {
    return setIsOpen(true);
  };
  return {
    onCalendarCallbackValueChange: onCalendarCallbackValueChange,
    onTextInputValueChange: onTextInputValueChange,
    onInteractOutside: onInteractOutside,
    onIconButtonClick: onIconButtonClick,
    onInputFocus: onInputFocus,
    isOpen: isOpen,
    textValue: textValue
  };
};
export default useDatePicker;