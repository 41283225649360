import React from "react";

import DocsBox from "../../../DocsBox";
import SidebarSection from "./components/SidebarSection";
import useSidebarInner from "./hooks/useSidebarInner";
import { Container, Overlay } from "./styled";
import { SidebarInnerProps } from "./types";

function SidebarInner({
  isOpen,
  data,
  toggleSidebarOpen,
}: SidebarInnerProps): JSX.Element {
  const { sectionKeys, sectionTitles } = useSidebarInner();

  return (
    <>
      {isOpen && <Overlay onClick={toggleSidebarOpen} />}
      <Container
        isOpen={{
          "@initial": isOpen,
          "@bp1": true,
        }}
      >
        <DocsBox>
          {sectionKeys.map((sectionKey, index) => (
            <SidebarSection
              key={sectionKey}
              sectionTitle={sectionTitles[index]}
              data={data[sectionKey]}
            />
          ))}
        </DocsBox>
      </Container>
    </>
  );
}

export default SidebarInner;
