export var calculateIsOverflowing = function calculateIsOverflowing(currentElement) {
  return currentElement.scrollWidth > currentElement.clientWidth;
};
export var handleIsOverflowingRecalculation = function handleIsOverflowingRecalculation(currentElement, setIsOverflowing) {
  if (!currentElement) return;
  var isOverflowing = calculateIsOverflowing(currentElement);
  setIsOverflowing(isOverflowing);
};
export var throttledIsOverflowingRecalculation = function throttledIsOverflowingRecalculation(debounce, currentElement, setIsOverflowing) {
  return debounce(function () {
    handleIsOverflowingRecalculation(currentElement, setIsOverflowing);
  }, 250);
};