import { useEffect, useLayoutEffect, useRef } from "react";
var useInterval = function useInterval(callback, delay) {
  // Reference to store current callback, we don't need to set the initial ref.
  var savedCallback = useRef(null);

  // we use layout effect here incase rendering takes a long time.
  // In theory a callback could update things that require dom interactions
  useLayoutEffect(function () {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval, after the render phase this is because we want to ensure the first
  // callback is triggered after the first render the rest happens outside of the react lifecycle
  useEffect(function () {
    if (delay !== null && savedCallback.current) {
      var id = setInterval(function () {
        return savedCallback.current();
      }, delay);
      return function () {
        return clearInterval(id);
      };
    }
    return function () {
      return null;
    };
  }, [delay]);
};
export default useInterval;