import { Platforms } from "../../types";
import getColors from "./getColors";
import getFonts from "./getFonts";
import getFontSizes from "./getFontSizes";
import getFontWeights from "./getFontWeights";
import getLetterSpacings from "./getLetterSpacings";
import getLineHeights from "./getLineHeights";
import getRadii from "./getRadii";
import getShadows from "./getShadows";
import getSpace from "./getSpace";
import getZIndices from "./getZIndices";

const getTheme = <T extends Platforms>(platform: T) => ({
  colors: getColors(),
  fonts: getFonts(platform),
  fontSizes: getFontSizes(platform),
  fontWeights: getFontWeights(platform),
  letterSpacings: getLetterSpacings(platform),
  lineHeights: getLineHeights(platform),
  radii: getRadii(platform),
  shadows: getShadows(platform),
  space: getSpace(platform),
  zIndices: getZIndices(platform),
});

export default getTheme;
