import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
export default css({
  $$size: "1em",
  $$color: getColorScaleValueByUseCase("greyA", text.hiContrast),
  lineHeight: "1em",
  verticalAlign: "middle",
  width: "$$size",
  height: "$$size",
  "& path": {
    fill: "$$color"
  },
  variants: {
    /**
     * Sets the color of the icon
     */
    color: {
      inherit: {
        $$color: "currentColor"
      },
      hiContrast: {
        $$color: getColorScaleValueByUseCase("greyA", text.hiContrast)
      },
      disabled: {
        $$color: getColorScaleValueByUseCase("greyA", text.disabled)
      },
      success: {
        $$color: getColorScaleValueByUseCase("success", text.hiSaturation)
      },
      critical: {
        $$color: getColorScaleValueByUseCase("critical", text.hiSaturation)
      },
      warning: {
        $$color: getColorScaleValueByUseCase("warning", text.hiSaturation)
      },
      info: {
        $$color: getColorScaleValueByUseCase("info", text.hiSaturation)
      },
      moderate: {
        $$color: getColorScaleValueByUseCase("yellow", text.hiSaturation)
      },
      primary: {
        $$color: getColorScaleValueByUseCase("primary", text.hiSaturation)
      },
      lowContrast: {
        $$color: getColorScaleValueByUseCase("greyA", text.lowContrast)
      },
      accent: {
        $$color: getColorScaleValueByUseCase("accent", text.hiSaturation)
      }
    },
    /**
     * Sets the size of the icon. If set to `inherit`, the icon will inherit the font size of its parent.
     */
    size: {
      inherit: {
        $$size: "1em"
      },
      sm: {
        $$size: "16px"
      },
      md: {
        $$size: "24px"
      },
      lg: {
        $$size: "32px"
      }
    }
  }
});