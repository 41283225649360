import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds,
  borders = colorUseCases.borders;
export default css({
  outline: "none",
  width: "100%",
  borderRadius: "$default",
  fontSize: "$body",
  fontFamily: "$textInput",
  lineHeight: "$body",
  fontWeight: "$textInput",
  padding: "$md",
  boxSizing: "border-box",
  color: getColorScaleValueByUseCase("neutral", text.hiContrast),
  background: getColorScaleValueByUseCase("neutral", backgrounds.app),
  $$defaultBorderColor: getColorScaleValueByUseCase("neutral", borders.uiElement),
  border: "solid 1px $$defaultBorderColor",
  "&::placeholder": {
    color: getColorScaleValueByUseCase("neutral", text.placeholder)
  },
  "&:enabled:hover:not(:active, :focus-visible)": {
    background: getColorScaleValueByUseCase("neutral", backgrounds.uiElement),
    borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_hovered)
  },
  "&:enabled:active, &:enabled:focus-visible": {
    $$activeBorderColor: getColorScaleValueByUseCase("primary", borders.uiElement_active),
    borderColor: "$$activeBorderColor",
    boxShadow: "0 0 0 1px $$activeBorderColor"
  },
  "&:disabled": {
    background: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_disabled),
    color: getColorScaleValueByUseCase("neutral", text.disabled),
    cursor: "not-allowed"
  },
  variants: {
    hasError: {
      "true": {
        $$errorBorderColor: getColorScaleValueByUseCase("critical", borders.uiElement_active),
        "&:enabled, &:enabled:hover:not(:active, :focus-visible)": {
          borderColor: "$$errorBorderColor"
        },
        "&:enabled:hover:not(:active, :focus-visible)": {
          background: getColorScaleValueByUseCase("neutral", backgrounds.uiElement)
        },
        "&:enabled:active, &:enabled:focus-visible": {
          borderColor: "$$errorBorderColor",
          boxShadow: "0 0 0 1px $$errorBorderColor"
        }
      }
    },
    resize: {
      auto: {
        resize: "both"
      },
      vertical: {
        resize: "vertical"
      },
      horizontal: {
        resize: "horizontal"
      },
      smart: {
        resize: "none"
      },
      none: {
        resize: "none"
      }
    }
  }
});