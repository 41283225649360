import { getColorScaleValueByUseCase } from "@vitality-ds/system";
import { colorUseCases } from "@vitality-ds/tokens";

// eslint-disable-next-line import/prefer-default-export
export var getTextColorVariant = function getTextColorVariant(colorScale) {
  return {
    color: getColorScaleValueByUseCase(colorScale, colorUseCases.text.hiContrast),
    a: {
      color: "".concat(getColorScaleValueByUseCase(colorScale, colorUseCases.text.hiSaturation))
    }
  };
};
export var getCompactTextColorVariant = function getCompactTextColorVariant(colorScale) {
  return {
    color: getColorScaleValueByUseCase(colorScale, colorUseCases.text.hiSaturation)
  };
};