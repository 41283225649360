import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useMemo, useRef, useState } from "react";
import { convertISOToAusDate } from "../../DatePicker/logic";
import { createRangeEventObject, determineFallbackDefault, turnValueArrayToJSDate } from "../logic";
var useDateRangePicker = function useDateRangePicker(value, onChange, defaultValueFromPreset) {
  var safeValue = useMemo(function () {
    return Array.isArray(value) && value.length === 2 ? value.map(function (v) {
      return v === null ? "" : v;
    }) : ["", ""];
  }, [value]);
  var _useState = useState({
      startDate: safeValue[0] !== "" ? safeValue[0] : determineFallbackDefault("from", defaultValueFromPreset),
      endDate: safeValue[1] !== "" ? safeValue[1] : determineFallbackDefault("to", defaultValueFromPreset)
    }),
    _useState2 = _slicedToArray(_useState, 2),
    selectedValue = _useState2[0],
    setSelectedValue = _useState2[1];
  var _useState3 = useState({
      startDate: {},
      endDate: {}
    }),
    _useState4 = _slicedToArray(_useState3, 2),
    onChangeObjects = _useState4[0],
    setOnChangeObjects = _useState4[1];
  useMemo(function () {
    onChange(createRangeEventObject(onChangeObjects));
  }, [onChangeObjects]);
  var inputRef = useRef(null);
  var calendarValue = turnValueArrayToJSDate(safeValue);
  var handleClearButtonClick = function handleClearButtonClick() {
    setSelectedValue({
      startDate: "",
      endDate: ""
    });
    setOnChangeObjects(function () {
      return {
        startDate: {
          isValid: true,
          errorMessage: null,
          textValue: "",
          value: ""
        },
        endDate: {
          isValid: true,
          errorMessage: null,
          textValue: "",
          value: ""
        }
      };
    });
  };
  var handleNewStartDate = function handleNewStartDate(newStartValue) {
    setOnChangeObjects(function (currentValues) {
      return _extends({}, currentValues, {
        startDate: newStartValue
      });
    });
    setSelectedValue(function (currentValues) {
      return _extends({}, currentValues, {
        startDate: newStartValue.value || ""
      });
    });
  };
  var handleNewEndDate = function handleNewEndDate(newEndValue) {
    setOnChangeObjects(function (currentValues) {
      return _extends({}, currentValues, {
        endDate: newEndValue
      });
    });
    setSelectedValue(function (currentValues) {
      return _extends({}, currentValues, {
        endDate: newEndValue.value || ""
      });
    });
  };
  var handlePresetSelect = function handlePresetSelect(option) {
    setSelectedValue({
      startDate: option.from,
      endDate: option.to
    });
    setOnChangeObjects(function () {
      return {
        startDate: {
          isValid: true,
          errorMessage: null,
          textValue: convertISOToAusDate(option.from),
          value: option.from
        },
        endDate: {
          isValid: true,
          errorMessage: null,
          textValue: convertISOToAusDate(option.to),
          value: option.to
        }
      };
    });
  };
  return {
    selectedValue: selectedValue,
    inputRef: inputRef,
    calendarValue: calendarValue,
    handleClearButtonClick: handleClearButtonClick,
    handleNewStartDate: handleNewStartDate,
    handleNewEndDate: handleNewEndDate,
    handlePresetSelect: handlePresetSelect
  };
};
export default useDateRangePicker;