import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var backgrounds = colorUseCases.backgrounds,
  text = colorUseCases.text;
/**
 * Note that these styles are applied to the container, not the actual `button` element because we re-use the styling for the Select component's inner components which provide the button
 *
 * see: src/Input/Select/components/Chip/MultiValueRemove
 */

export default css({
  display: "flex",
  justifyContent: "center",
  boxSizing: "border-box",
  $$size: "16px",
  flexShrink: 0,
  width: "$$size",
  height: "$$size",
  fontSize: "$body",
  borderRadius: "$rounded",
  overflow: "hidden",
  "&:hover:not(:has(button[disabled]))": {
    backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElement_hovered),
    color: getColorScaleValueByUseCase("primary", text.hiSaturation)
  },
  "&:active:not(button[disabled])": {
    backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElement_active),
    color: getColorScaleValueByUseCase("primary", text.hiSaturation)
  },
  "& button[disabled]": {
    color: getColorScaleValueByUseCase("neutral", text.disabled),
    cursor: "not-allowed"
  }
});