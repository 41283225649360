import IconPrimitive from "@vitality-ds/icons";
import React from "react";

function Responsive(props) {
  return (
    <IconPrimitive {...props}>
      <path d="M22 17h-4v-7h4m1-2h-6a1 1 0 00-1 1v10a1 1 0 001 1h6a1 1 0 001-1V9a1 1 0 00-1-1M4 6h18V4H4a2 2 0 00-2 2v11H0v3h14v-3H4V6z" />
    </IconPrimitive>
  );
}
export default Responsive;
