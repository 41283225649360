import type { StitchesColorThemeObject, ThemeKeys } from "../../Color/types";
import removeNonDigitCharacters from "./removeNonDigitCharacters";

/*
  Stitches leverages aliasing for theme values. 
  Our tokens define "primary: cyan" which maps the cyan scale to the primary theme key
  This fn maps over all the "steps" in the cyan scale to generate 
    primary1: $cyan1,
    primary2: $cyan2,

  etc.

  This dollar sign notation is specific to Stitches.
*/
export default (theme): StitchesColorThemeObject => {
  const output = {};

  Object.keys(theme).forEach((usage) => {
    Object.keys(theme[usage as ThemeKeys]).forEach((colorName) => {
      const key = `${usage}${removeNonDigitCharacters(colorName)}`;
      const color = theme[usage as ThemeKeys][colorName];

      output[key] = `$${color.data.codeName}`;
    });
  });

  return output as StitchesColorThemeObject;
};
