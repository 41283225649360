import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var backgrounds = colorUseCases.backgrounds;
export default css({
  background: getColorScaleValueByUseCase("neutral", backgrounds.app),
  boxShadow: "$lg",
  borderRadius: "$large",
  fontFamily: "$body",
  fontSize: "$body",
  lineHeight: "$body",
  fontWeight: "$body",
  padding: "$md",
  display: "flex",
  flexDirection: "column",
  gap: "$sm",
  zIndex: "$floatingElements",
  boxSizing: "border-box",
  maxHeight: "calc(var(--radix-dropdown-menu-content-available-height) - var(--vitality-space-md))",
  overflow: "auto"
});