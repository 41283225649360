import { DateTime, Duration } from "luxon";
import { ERROR_MESSAGES } from "./constants";
// eslint-disable-next-line import/prefer-default-export
export function timeIntervals(interval, startTime, endTime, disabledTimes) {
  // initialise an empty options array
  var timeList = [];

  // Initialise 3 date-time objects
  var dateTime = DateTime.fromFormat(startTime, "T");
  var startMinute = DateTime.fromFormat(startTime, "T");
  var endMinute = DateTime.fromFormat(endTime, "T");

  // finds the minute within the day to begin starting from (a day has 1439 minutes so 0:00 = minute 0000)
  startMinute = Duration.fromObject({
    hours: startMinute.hour,
    minutes: startMinute.minute
  }).shiftTo("minutes");
  startMinute = startMinute.values.minutes;

  // finds the minute within the day to end at (a day has 1439 minutes so 23:59 = minute 1439)
  endMinute = Duration.fromObject({
    hours: endMinute.hour,
    minutes: endMinute.minute
  }).shiftTo("minutes");
  endMinute = endMinute.values.minutes;

  // starts and the start minute, ends at the end minute and after every cycle jumps intervals length of minutes
  for (var i = startMinute; i < endMinute; i += interval) {
    // get the dateTime data for this interval
    var intervalTime = DateTime.fromObject({
      hour: dateTime.hour,
      minute: dateTime.minute
    }).toFormat("HH:mm").toLowerCase();

    // checks to see if it is disabled
    var isDisabled = false;
    for (var j = 0; j < disabledTimes.length; j += 1) {
      if (intervalTime === disabledTimes[j]) {
        isDisabled = true;
      }
    }

    // add's it to the list
    timeList.push({
      label: intervalTime,
      value: intervalTime,
      disabled: isDisabled
    });

    // increments the dateTime by the interval
    dateTime = DateTime.fromObject({
      hour: dateTime.hour,
      minute: dateTime.minute
    }).plus({
      minutes: interval
    });
  }

  // returns array of times as options
  return timeList;
}
export function validateTime(time) {
  return DateTime.fromFormat(time, "HH:mm").isValid;
}
export function createEventObject(inputTime, disabledTimes) {
  if (inputTime === "") {
    return {
      errorMessage: null,
      isValid: true,
      value: ""
    };
  }

  // check to see if the time is disabled
  for (var i = 0; i < disabledTimes.length; i += 1) {
    if (inputTime === disabledTimes[i]) {
      return {
        errorMessage: ERROR_MESSAGES.timeDisabled,
        isValid: false,
        value: inputTime
      };
    }
  }
  var isValid = validateTime(inputTime);
  var errorMessage = /[a-zA-Z]/g.test(inputTime) ? ERROR_MESSAGES.timeIncludesLetters : ERROR_MESSAGES.invalidTime;
  return {
    errorMessage: isValid ? null : errorMessage,
    isValid: isValid,
    value: inputTime
  };
}
export var parseTimeString = function parseTimeString(timeStringParam) {
  var timeString = timeStringParam;
  if (!timeString || typeof timeString !== "string") return null;
  timeString = timeString.toLowerCase().replace(/[\s.]/g, "");
  // if the last character is an "a" or "p", add the "m"
  if (timeString.slice(-1) === "a" || timeString.slice(-1) === "p") {
    timeString += "m";
  }

  // try to parse time input
  var pattern = /^(am|pm)?([0-9]?[0-9])\W?([0-5][0-9])?\W?([0-5][0-9])?(am|pm)?$/;
  var time = timeString.match(pattern);
  if (!time) {
    return null;
  }
  var hour = parseInt(time[2], 10);
  var ampm = time[1] || time[5];
  var hours = hour;
  var minutes = time[3] * 1 || 0;
  var seconds = time[4] * 1 || 0;
  if (hour <= 12 && ampm) {
    var isPm = ampm === "pm";
    if (hour === 12) {
      hours = isPm ? 12 : 0;
    } else {
      hours = hour + (isPm ? 12 : 0);
    }
  }
  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds
  };
};
export function formatTimeString(timeString) {
  var leadingZero = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (!timeString || typeof timeString !== "string") {
    return "";
  }
  var time = parseTimeString(timeString);
  if (!time) return timeString; // invalid time string
  var hoursString = leadingZero ? "".concat(time.hours).padStart(2, "0") : "".concat(time.hours);
  var minutesString = "".concat(time.minutes).padStart(2, "0");
  return "".concat(hoursString, ":").concat(minutesString);
}