import { colorUseCases, getColorScaleValueByUseCase } from "@vitality-ds/system";
var backgrounds = colorUseCases.backgrounds,
  borders = colorUseCases.borders,
  text = colorUseCases.text;
function getVariantsByColorScale(colorScale) {
  return {
    backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.app),
    color: getColorScaleValueByUseCase(colorScale, text.hiContrast),
    svg: {
      path: {
        fill: getColorScaleValueByUseCase(colorScale, text.lowContrast)
      }
    },
    borderColor: "".concat(getColorScaleValueByUseCase(colorScale, text.hiSaturation), " ").concat(getColorScaleValueByUseCase(colorScale, borders.uiElement), " ").concat(getColorScaleValueByUseCase(colorScale, borders.uiElement))
  };
}
export default getVariantsByColorScale;