import { useCallback, useState } from "react";

import { LocalState } from "../types";

const useLocalState = <T>(initialValue: T): LocalState<T> => {
  const [value, setState] = useState(initialValue);

  const setLocalState = useCallback(
    (newValue: T) => {
      setState(newValue);
    },
    [setState]
  );

  return [value, setLocalState];
};

export default useLocalState;
