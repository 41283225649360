import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var backgrounds = colorUseCases.backgrounds;
export default css({
  position: "relative",
  display: "flex",
  overflow: "hidden",
  background: getColorScaleValueByUseCase("neutralA", backgrounds.uiElement),
  borderRadius: "$rounded",
  width: "100%",
  height: 4,
  minWidth: 20,
  // Fix overflow clipping in Safari
  // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
  transform: "translateZ(0)"
});