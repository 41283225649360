import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { sizes } from "./constants";
// eslint-disable-next-line import/prefer-default-export
export var getSpaceScaleVariants = function getSpaceScaleVariants(cssProperty) {
  var output = {};
  sizes.forEach(function (size) {
    // Allow the user to pass a 'none' variant
    var value = size === "none" ? 0 : "$".concat(size);
    output[size] = _defineProperty({}, cssProperty, value);
  });
  return output;
};