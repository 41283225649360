export function selectIdsByTablePage(tablePagination, data) {
  var _tablePagination$stat = tablePagination.state,
    page = _tablePagination$stat.page,
    size = _tablePagination$stat.size;
  var lastOnPage = (page + 1) * size;
  var firstOnPage = lastOnPage - size + 1;
  var selectableIds = [];
  for (var i = firstOnPage; i <= lastOnPage; i += 1) {
    if (data.nodes[i - 1]) {
      selectableIds.push(data.nodes[i - 1].id);
    }
  }
  return selectableIds;
}
export function toggleSelection(selectLogic, idsOnPage) {
  if (selectLogic.state.ids.length > 0) {
    selectLogic.fns.onToggleAll({});
  } else {
    selectLogic.fns.onAddByIds(idsOnPage);
  }
}
export function toggle(selectLogic, tablePagination, data) {
  if (tablePagination) {
    toggleSelection(selectLogic, selectIdsByTablePage(tablePagination, data));
  } else {
    selectLogic.fns.onToggleAll({});
  }
}