import { Link } from "gatsby";
import React from "react";

import { SidebarLink } from "./styled";
import { SidebarItemProps } from "./types";

function SidebarItem({ index, node }: SidebarItemProps): JSX.Element {
  return (
    <SidebarLink
      as={Link}
      key={index}
      to={node.fields.slug}
      activeClassName="active"
    >
      {node.frontmatter.title}
    </SidebarLink>
  );
}

export default SidebarItem;
