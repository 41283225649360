import CurrencyInput from "../../Input/CurrencyInput";
import DatePicker from "../../Input/DatePicker";
import DateRangePicker from "../../Input/DateRangePicker";
import DurationInput from "../../Input/DurationInput";
import PasswordInput from "../../Input/PasswordInput";
import RadioButtons from "../../Input/RadioButtons";
import SearchInput from "../../Input/SearchInput";
import { AsyncSelect, CreatableSelect, SearchSelectInput, Select } from "../../Input/Select";
import Textarea from "../../Input/Textarea";
import TextInput from "../../Input/TextInput";
import TimePicker from "../../Input/TimePicker";
import CheckboxList from "../CheckboxList";

// eslint-disable-next-line import/prefer-default-export
export var INPUT_MAPPINGS = {
  text: {
    component: TextInput
  },
  checkbox: {
    component: CheckboxList
  },
  radioButtons: {
    component: RadioButtons
  },
  textarea: {
    component: Textarea
  },
  password: {
    component: PasswordInput
  },
  currency: {
    component: CurrencyInput
  },
  duration: {
    component: DurationInput
  },
  select: {
    component: Select
  },
  creatableSelect: {
    component: CreatableSelect
  },
  asyncSelect: {
    component: AsyncSelect
  },
  searchSelect: {
    component: SearchSelectInput
  },
  timePicker: {
    component: TimePicker
  },
  datePicker: {
    component: DatePicker
  },
  dateRangePicker: {
    component: DateRangePicker
  },
  searchInput: {
    component: SearchInput
  }
};