import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var borders = colorUseCases.borders;
export default css({
  fontFamily: "$body",
  fontSize: "$body",
  fontWeight: "$body",
  lineHeight: "$body",
  "&:focus-visible": {
    outline: "none",
    boxShadow: "0 0 0 $space$outlineWidth ".concat(getColorScaleValueByUseCase("primary", borders.focusRing))
  }
});