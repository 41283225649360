import { FontSizeToken, ThemeFontStylesType } from "../../Font/types";
import { Platforms } from "../../types";
import { fontStylesTokens } from "../constants";
import commonGetter from "./commonGetter";

const getLetterSpacingValue = (
  { letterSpacing }: FontSizeToken["data"],
  platform: Platforms
) => {
  switch (platform) {
    case "web":
      return letterSpacing;
    case "reactNative":
      return letterSpacing;
    default:
      return letterSpacing;
  }
};

export default <T extends Platforms>(platform: T) =>
  commonGetter({
    platform,
    tokens: fontStylesTokens[platform],
    getValue: getLetterSpacingValue,
  }) as ThemeFontStylesType["LetterSpacings"][T];
