import {
  colorUseCases,
  CSS,
  getColorScaleValueByUseCase,
} from "@vitality-ds/system";

import { sharedStyles } from "../InlineCode";

const { backgrounds } = colorUseCases;

export const sidebarWidthVar = "vitality-sidebar-width";
export const TOC_WIDTH = 285;

const styles: Record<string, CSS> = {
  global: {
    pre: {
      // hardcoding because safari makes pre tags "reset" the rem inheritance
      fontSize: 16,
    },
    body: {
      // override user agent stylesheet
      margin: 0,
      background: getColorScaleValueByUseCase("neutral", backgrounds.app),
    },
    code: sharedStyles,
  },
};

export default styles;
