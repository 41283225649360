import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
export default css({
  fontSize: "$body",
  fontFamily: "$body",
  lineHeight: "$body",
  color: getColorScaleValueByUseCase("neutral", text.hiContrast),
  userSelect: "none",
  variants: {
    disabled: {
      "true": {
        color: getColorScaleValueByUseCase("neutral", text.disabled)
      }
    }
  }
});