import { styled } from "@vitality-ds/system";

import DocsBox from "../../../../../DocsBox";

// eslint-disable-next-line import/prefer-default-export
export const Container = styled(DocsBox, {
  display: "block",
  marginTop: "$xxl",
  marginBottom: "$md",
});
