import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var backgrounds = colorUseCases.backgrounds;
export default css({
  backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.card),
  boxShadow: "$sm",
  padding: "$lg",
  boxSizing: "border-box",
  borderRadius: "$default",
  zIndex: "$floatingElements",
  maxHeight: "calc(var(--radix-popover-content-available-height) - var(--vitality-space-md))",
  overflow: "auto"
});