import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
// eslint-disable-next-line import/prefer-default-export
export var handleChange = function handleChange(checkedItems, setCheckedItems) {
  return function (checkboxValue) {
    if (checkedItems.includes(checkboxValue)) {
      setCheckedItems(checkedItems.filter(function (item) {
        return item !== checkboxValue;
      }));
    } else {
      setCheckedItems([].concat(_toConsumableArray(checkedItems), [checkboxValue]));
    }
  };
};