import { CommonGetterParams } from "../types";

export default ({
  platform,
  tokens,
  getValue,
  additionalValues,
}: CommonGetterParams) => {
  const output = {};

  Object.keys(tokens).map((token) => {
    output[token] = getValue(tokens[token].data, platform);
    return null;
  });

  return { ...output, ...additionalValues };
};
