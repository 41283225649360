import { css } from "@vitality-ds/system";
export default css({
  display: "flex",
  gap: "$lg",
  variants: {
    tabAlignment: {
      stretch: {
        flex: 1
      },
      initial: {
        flex: 0
      }
    }
  }
});