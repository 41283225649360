import { usePagination as useTablePagination } from "@table-library/react-table-library/pagination";
import { getPaginationProps } from "../logic";
var usePagination = function usePagination(data, pagination) {
  if (!pagination) return null;
  var rowsPerPageOptions = getPaginationProps(pagination);
  return {
    tablePagination: useTablePagination(data, {
      state: {
        page: 0,
        size: rowsPerPageOptions[0]
      }
    }),
    rowsPerPageOptions: rowsPerPageOptions
  };
};
export default usePagination;