import {
  colorUseCases,
  getColorScaleValueByUseCase,
  styled,
} from "@vitality-ds/system";

const { backgrounds } = colorUseCases;

export const MagentusLogoLink = styled("a", {
  margin: "-$md",
  padding: "$md",
  alignItems: "center",
  display: "none",
  pointerEvents: "none",
  svg: {
    maxWidth: 120,
    color: "$neutralA11",
    marginBottom: -3,
  },
  "@bp1": {
    pointerEvents: "all",
    display: "flex",
  },
  borderRadius: "$default",
  "&:hover": {
    backgroundColor: "$grey2",
  },
  "&:focus": {
    boxShadow: `0 0 0 $space$outlineWidth ${getColorScaleValueByUseCase(
      "warning",
      backgrounds.uiElement_hovered
    )}`,
    outline: "none",
  },
});

export const HamburgerWrapper = styled("div", {
  display: "block",
  "@bp1": { display: "none" },
});
export const Hamburger = styled("span", {
  display: "block",
  transition: "transform 250ms ease",
  variants: {
    isOpen: {
      true: {
        transform: "rotateZ(90deg)",
      },
    },
  },
});
