import { colorUseCases, css, getColorScaleValueByUseCase, keyframes } from "@vitality-ds/system";
var backgrounds = colorUseCases.backgrounds;
var indeterminate = keyframes({
  "0%": {
    width: "12%"
  },
  "50%": {
    width: "50%"
  },
  "100%": {
    left: "100%",
    width: "0%"
  }
});
export default css({
  backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid),
  background: "linear-gradient(20deg, $primary8 0%, $primary10 100%)",
  width: "100%",
  height: "100%",
  transition: "transform 660ms cubic-bezier(0.65, 0, 0.35, 1)",
  variants: {
    /**
     * Boolean to represent the indeterminate state of the component
     */
    indeterminate: {
      "true": {
        position: "absolute",
        height: "100%",
        left: "0%",
        animation: "".concat(indeterminate, " 1300ms ease infinite")
      }
    }
  }
});