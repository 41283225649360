import { DateTime } from "luxon";
import { AUS_FORMAT, ERRORS, ISO_FORMAT } from "./constants";
export function shortHandToDateObject(date) {
  if (date) {
    var time = DateTime.fromString(date, "dd/MM/yyyy");
    if (time.isValid) {
      return time.toJSDate();
    }
  }
  return null;
}

/**
 * This function is used to determine if, once the user clicks outside the datepicker whether or not the element they clicked on was from a specfic list of "allowed" element ids. For example, we still want them to be able to focus the text input field, so we pass its id.
 */
export var handleOnInteractOutside = function handleOnInteractOutside(setIsOpen) {
  for (var _len = arguments.length, allowedElementIds = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    allowedElementIds[_key - 1] = arguments[_key];
  }
  return function (event) {
    // when we click outside the datepicker, check if it's the input field or the icon button
    var target = event.target;
    if (allowedElementIds.includes(target === null || target === void 0 ? void 0 : target.id)) {
      return;
    }
    setIsOpen(false);
  };
};
export var convertISOToAusDate = function convertISOToAusDate() {
  var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return DateTime.fromFormat(date, ISO_FORMAT).toFormat(AUS_FORMAT);
};
export var convertAusToISODate = function convertAusToISODate() {
  var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return DateTime.fromFormat(date, AUS_FORMAT).toFormat(ISO_FORMAT);
};
export function createEventObject(textValue) {
  var incompleteDate = textValue.includes("_");
  var errorMessage = incompleteDate ? ERRORS.incomplete : ERRORS.invalid;
  var validISO = convertAusToISODate(textValue) !== "Invalid DateTime";
  var isoDate = validISO ? convertAusToISODate(textValue) : "";
  var isValid = validISO;
  isValid = textValue !== "" ? isValid : true;
  return {
    value: isValid ? isoDate : "",
    isValid: isValid,
    errorMessage: isValid ? null : errorMessage,
    textValue: textValue
  };
}