import { FontStyleToken, ThemeFontStylesType } from "../../Font/types";
import { Platforms } from "../../types";
import { fontStylesTokens } from "../constants";
import commonGetter from "./commonGetter";

const getFontWeightValue = (
  { weight }: Partial<FontStyleToken["data"]>,
  platform: Platforms
) => {
  switch (platform) {
    case "web":
      return weight;
    case "reactNative":
      return `${weight}`;
    default:
      return weight;
  }
};

export default <T extends Platforms>(platform: T) =>
  commonGetter({
    platform,
    tokens: fontStylesTokens[platform],
    getValue: getFontWeightValue,
  }) as ThemeFontStylesType["FontWeights"][T];
