import { css, keyframes } from "@vitality-ds/system";
import EASING_FUNCTION from "../constants";
var contentShow = keyframes({
  "0%": {
    opacity: 0,
    transform: "translate(-50%, -45%) scale(0.96)"
  },
  "100%": {
    opacity: 1,
    transform: "translate(-50%, -50%) scale(1)"
  }
});
export default css({
  position: "fixed",
  width: "100%",
  bottom: 0,
  zIndex: "$dialog",
  maxHeight: "calc(100vh - ".concat("$lg", "px)"),
  "@bp1": {
    top: "50%",
    left: "50%",
    width: "unset",
    bottom: "unset",
    maxHeight: "unset",
    transform: "translate(-50%, -50%)",
    animation: "".concat(contentShow, " 550ms ").concat(EASING_FUNCTION)
  },
  variants: {
    isLayeredModal: {
      "true": {
        zIndex: "calc($dialog + 2)"
      }
    }
  }
});