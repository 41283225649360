import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useEffect, useState } from "react";
import { DEFAULT_THEME_CONTEXT_VALUE } from "../constants";
import { handleCycleTheme, handleSetInitialState, setMediaListeners } from "../logic";
var useTheme = function useTheme(themeOverride) {
  var _document;
  var _useState = useState(DEFAULT_THEME_CONTEXT_VALUE.theme),
    _useState2 = _slicedToArray(_useState, 2),
    theme = _useState2[0],
    setTheme = _useState2[1];
  var htmlElement = typeof document !== "undefined" && ((_document = document) === null || _document === void 0 ? void 0 : _document.documentElement);
  if (!htmlElement) {
    return DEFAULT_THEME_CONTEXT_VALUE;
  }

  // Set initial state
  useEffect(function () {
    handleSetInitialState(htmlElement, setTheme, DEFAULT_THEME_CONTEXT_VALUE.theme, themeOverride);
  }, []);
  useEffect(function () {
    if (!themeOverride) {
      setMediaListeners({
        htmlElement: htmlElement,
        onChange: setTheme
      });
    }
  }, [setTheme]);
  var cycleTheme = useCallback(function () {
    handleCycleTheme(htmlElement, theme, setTheme);
  }, [theme, setTheme]);
  return {
    theme: theme,
    cycleTheme: cycleTheme
  };
};
export default useTheme;