import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const blue: ColorScale<"blue"> = {
  blue1: {
    name: "blue1",
    data: generateColorData("blue1", { h: 212, s: 35, l: 9, a: 1 }),
  },
  blue2: {
    name: "blue2",
    data: generateColorData("blue2", { h: 212, s: 50, l: 11, a: 1 }),
  },
  blue3: {
    name: "blue3",
    data: generateColorData("blue3", { h: 212, s: 59, l: 15, a: 1 }),
  },
  blue4: {
    name: "blue4",
    data: generateColorData("blue4", { h: 212, s: 65, l: 17, a: 1 }),
  },
  blue5: {
    name: "blue5",
    data: generateColorData("blue5", { h: 212, s: 71, l: 20, a: 1 }),
  },
  blue6: {
    name: "blue6",
    data: generateColorData("blue6", { h: 212, s: 77, l: 23, a: 1 }),
  },
  blue7: {
    name: "blue7",
    data: generateColorData("blue7", { h: 212, s: 85, l: 27, a: 1 }),
  },
  blue8: {
    name: "blue8",
    data: generateColorData("blue8", { h: 212, s: 89, l: 34, a: 1 }),
  },
  blue9: {
    name: "blue9",
    data: generateColorData("blue9", { h: 212, s: 100, l: 50, a: 1 }),
  },
  blue10: {
    name: "blue10",
    data: generateColorData("blue10", { h: 209, s: 100, l: 60, a: 1 }),
  },
  blue11: {
    name: "blue11",
    data: generateColorData("blue11", { h: 210, s: 100, l: 66, a: 1 }),
  },
  blue12: {
    name: "blue12",
    data: generateColorData("blue12", { h: 206, s: 98, l: 95, a: 1 }),
  },
  blue13: {
    name: "blue13",
    data: generateColorData("blue13", { h: 212, s: 35, l: 9, a: 1 }),
  },
};

export default blue;
