import { getColorScaleValueByUseCase } from "@vitality-ds/system";
import { colorUseCases } from "@vitality-ds/tokens";
import { BUTTON_BORDER_WIDTH } from "../constants";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds,
  borders = colorUseCases.borders;
export var getColorVariant = function getColorVariant(colorScale) {
  return {
    border: "".concat(BUTTON_BORDER_WIDTH, " solid transparent"),
    color: getColorScaleValueByUseCase(colorScale, text.onSolidBackgrounds),
    backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElementSolid),
    "&:hover": {
      backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElementSolid_hovered)
    },
    "&:active": {
      backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElementSolid_active)
    },
    "&[disabled]": {
      cursor: "not-allowed",
      color: getColorScaleValueByUseCase("neutral", text.disabled),
      backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_disabled)
    },
    "&:focus-visible": {
      boxShadow: "0 0 0 $space$outlineWidth ".concat(getColorScaleValueByUseCase(colorScale, borders.focusRing)),
      outline: "none"
    }
  };
};
export var getSubtleVariant = function getSubtleVariant(colorScale) {
  return {
    color: getColorScaleValueByUseCase(colorScale, text.hiSaturation),
    border: "".concat(BUTTON_BORDER_WIDTH, " solid ").concat(getColorScaleValueByUseCase(colorScale, borders.uiElement)),
    backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElement),
    "&:hover": {
      backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElement_hovered)
    },
    "&:active": {
      backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElement_active)
    },
    "&:focus-visible": {
      boxShadow: "0 0 0 $space$outlineWidth ".concat(getColorScaleValueByUseCase(colorScale, borders.focusRing)),
      outline: "none"
    },
    "&[disabled]": {
      cursor: "not-allowed",
      color: getColorScaleValueByUseCase("neutral", text.disabled),
      backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_disabled),
      borderColor: "transparent"
    }
  };
};
export var getOnSurfaceVariant = function getOnSurfaceVariant(colorScale) {
  return {
    color: getColorScaleValueByUseCase(colorScale, text.hiSaturation),
    border: "".concat(BUTTON_BORDER_WIDTH, " solid ").concat(getColorScaleValueByUseCase(colorScale, borders.uiElement)),
    backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElementOnSurface),
    "&:hover": {
      backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElementOnSurface_hovered)
    },
    "&:active": {
      backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElementOnSurface_active)
    },
    "&:focus-visible": {
      boxShadow: "0 0 0 $space$outlineWidth ".concat(getColorScaleValueByUseCase(colorScale, borders.focusRing)),
      outline: "none"
    },
    "&[disabled]": {
      cursor: "not-allowed",
      color: getColorScaleValueByUseCase("neutral", text.disabled),
      backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_disabled),
      borderColor: "transparent"
    }
  };
};
export var getGhostVariant = function getGhostVariant(colorScale) {
  return {
    border: "".concat(BUTTON_BORDER_WIDTH, " solid transparent"),
    backgroundColor: "transparent",
    color: getColorScaleValueByUseCase(colorScale, text.lowContrast),
    "&:hover": {
      backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElement_hovered)
    },
    "&:active": {
      backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElement_active)
    },
    "&:focus-visible": {
      boxShadow: "0 0 0 $space$outlineWidth ".concat(getColorScaleValueByUseCase(colorScale, borders.focusRing)),
      outline: "none"
    },
    "&[disabled]": {
      cursor: "not-allowed",
      color: getColorScaleValueByUseCase("neutral", text.disabled),
      backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_disabled)
    }
  };
};
export var getLinkVariant = function getLinkVariant(colorScale) {
  return {
    border: "".concat(BUTTON_BORDER_WIDTH, " solid transparent"),
    backgroundColor: "transparent",
    color: getColorScaleValueByUseCase(colorScale, text.hiSaturation),
    "&:hover:not([disabled])": {
      backgroundColor: "transparent",
      textDecoration: "underline"
    },
    "&:active:not([disabled])": {
      transform: "none"
    },
    "&:active": {
      backgroundColor: "transparent",
      color: getColorScaleValueByUseCase(colorScale, text.hiContrast)
    },
    "&[disabled]": {
      cursor: "not-allowed",
      color: getColorScaleValueByUseCase("neutral", text.disabled),
      backgroundColor: "transparent"
    }
  };
};