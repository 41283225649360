import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
export default css({
  marginLeft: "$xs",
  variants: {
    hasError: {
      "true": {
        color: getColorScaleValueByUseCase("critical", text.hiSaturation)
      }
    }
  }
});