import { styled } from "@vitality-ds/system";
import { BaseLabel } from "../../Form/Label/styled";
import BaseDateRangePickerStyles from "./styles/BaseDateRangePicker.styles";
import BaseDateRangePickerLabelStyles from "./styles/BaseDateRangePickerLabel.styles";
import DateContainerStyles from "./styles/DateContainer.styles";
import DatePickerContainerStyles from "./styles/DatePickerContainer.styles";
import ExtrasContainerStyles from "./styles/ExtrasContainers";
export var BaseDateRangePickerLabel = styled(BaseLabel, BaseDateRangePickerLabelStyles);
export var BaseDateRangePicker = styled("div", BaseDateRangePickerStyles);
export var DateContainer = styled("div", DateContainerStyles);
export var DatePickerContainer = styled("div", DatePickerContainerStyles);
export var ExtrasContainer = styled("div", ExtrasContainerStyles);