import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds;
export default css({
  boxSizing: "border-box",
  fontFamily: "$default",
  fontSize: "$caption",
  lineHeight: "$caption",
  borderRadius: "$default",
  zIndex: "$tooltip",
  maxWidth: 300,
  backgroundColor: getColorScaleValueByUseCase("neutral", text.hiContrast),
  color: getColorScaleValueByUseCase("neutral", backgrounds.app),
  padding: "$md"
});