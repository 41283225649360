import _extends from "@babel/runtime/helpers/extends";
import { Space } from "@vitality-ds/tokens";
import AvatarButton from "../Avatar/AvatarButton";
import Button from "../Button";
import IconButton from "../IconButton";
import Submenu from "./components/Submenu";
import BASE_ITEM_TYPES from "./components/Submenu/constants";
export var FLOATING_ELEMENT_SIDE_OFFSET = Space.sm.data.value;
export var DROPDOWN_MENU_ITEM_TYPES = _extends({}, BASE_ITEM_TYPES, {
  subMenu: {
    component: Submenu
  }
});
export var TRIGGER_TYPES = {
  button: {
    component: Button
  },
  iconButton: {
    component: IconButton
  },
  avatar: {
    component: AvatarButton
  }
};