import { colorUseCases, getColorScaleFromThemeObject, theme } from "@vitality-ds/system";
import { BaseIconButtonCell } from "../components/Cells/RowActions/styled";
var borders = colorUseCases.borders,
  backgrounds = colorUseCases.backgrounds;
var borderColor = getColorScaleFromThemeObject("neutral", borders.uiElement, theme);
var neutralBackground = getColorScaleFromThemeObject("neutral", backgrounds.app, theme);
var neutralAHovered = getColorScaleFromThemeObject("neutralA", borders.uiElement_hovered, theme);
var neutralABackground = getColorScaleFromThemeObject("neutralA", backgrounds.app, theme);
var backgroundSizes = {
  shadowCover: "60px",
  shadow: "6px"
};
var resizerActiveColor = getColorScaleFromThemeObject("neutral", borders.uiElement_hovered, theme);
var scrollShadow = " \n  background-image: \n    linear-gradient(to right, ".concat(neutralBackground, ", transparent),\n    linear-gradient(to left, ").concat(neutralBackground, ", transparent),\n    linear-gradient(to right, ").concat(neutralAHovered, ", ").concat(neutralABackground, "),\n    linear-gradient(to left, ").concat(neutralAHovered, ", ").concat(neutralABackground, ");\n\n  background-position: left center, right center, left center, right center;\n  background-repeat: no-repeat;\n  background-color: ").concat(neutralBackground, ";\n  background-size: ").concat(backgroundSizes.shadowCover, " 100%, ").concat(backgroundSizes.shadowCover, " 100%, ").concat(backgroundSizes.shadow, " 100%, ").concat(backgroundSizes.shadow, " 100%;\n  background-attachment: local, local, scroll, scroll;\n");
export var resizerTheme = function resizerTheme(minWidth) {
  return {
    resizerWidth: 16,
    minWidth: minWidth
  };
};
var getTableStyles = function getTableStyles(tableLayout, numOfCols) {
  return "\n    width: 100%;\n    background: none;\n    // must be assigned via css variable otherwise doesn't dynamically update on resize\n    --data-table-library_grid-template-columns: ".concat(tableLayout || "repeat(".concat(numOfCols, ", minmax(auto, 1fr))"), ";\n\n    .tr:hover {\n      td > div > ").concat(BaseIconButtonCell, " {\n        opacity: 1;\n      }\n    }\n\n    .resizer-area {\n      display: flex;\n      justify-content: center;\n      align-items: unset; \n    }\n\n    .resizer-handle {\n      position: unset;\n    }\n\n    .resizer-area:hover, .resizer-area:active {\n      cursor: col-resize;\n    }\n    \n    .resizer-area:hover .resizer-handle, .resizer-area:active .resizer-handle {\n      border-radius: ").concat(theme.radii["default"].value, ";\n      width: 4px;\n    }\n    \n    .resizer-area:hover .resizer-handle {\n      background: ").concat(borderColor, ";\n    }\n\n    .resizer-area:active .resizer-handle {\n      background: ").concat(resizerActiveColor, ";\n    }\n\n    .stiff > div {\n        display: flex;\n        flex-wrap: wrap;\n        justify-content: center;\n        overflow: visible;\n    }\n\n    tr.row-select-selected {\n      background-color: ").concat(getColorScaleFromThemeObject("neutralA", backgrounds.uiElement_selected, theme), "\n    }\n\n    ").concat(scrollShadow, "\n  ");
};
export default getTableStyles;