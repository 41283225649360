import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var borders = colorUseCases.borders,
  backgrounds = colorUseCases.backgrounds;
export default css({
  boxSizing: "border-box",
  border: "1px solid ".concat(getColorScaleValueByUseCase("neutral", borders.uiElement)),
  backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.app),
  width: "100%",
  padding: "$md",
  borderRadius: "$default",
  display: "flex",
  justifyContent: "space-between",
  gap: "$md",
  flexWrap: "wrap",
  variants: {
    floating: {
      "true": {
        boxShadow: "$md",
        borderColor: "transparent"
      }
    }
  }
});