import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var backgrounds = colorUseCases.backgrounds;
export default css({
  backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.card),
  paddingTop: "$xl",
  paddingBottom: "$xl",
  borderTopLeftRadius: "$large",
  borderTopRightRadius: "$large",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxHeight: "calc(100vh - 16px)",
  boxShadow: "$lg",
  "@bp1": {
    minWidth: 320,
    borderRadius: "$large",
    maxHeight: "95vh"
  },
  variants: {
    width: {
      sm: {
        "@bp1": {
          maxWidth: 400
        }
      },
      md: {
        "@bp1": {
          maxWidth: 600
        }
      },
      lg: {
        "@bp1": {
          maxWidth: 1000
        }
      }
    }
  }
});