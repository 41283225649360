import { getColorScaleValueByUseCase } from "@vitality-ds/system";
import { colorUseCases } from "@vitality-ds/tokens";
var backgrounds = colorUseCases.backgrounds,
  text = colorUseCases.text;
export var getColorVariant = function getColorVariant(colorScale) {
  return {
    backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElement)
  };
};
export var getTitleColorVariant = function getTitleColorVariant(colorScale) {
  return {
    color: getColorScaleValueByUseCase(colorScale, text.hiSaturation)
  };
};