import {
  colorUseCases,
  getColorScaleValueByUseCase,
  styled,
} from "@vitality-ds/system";

import { HEADER_HEIGHT } from "../../constants";

const { backgrounds } = colorUseCases;

const Container = styled("header", {
  display: "flex",
  position: "fixed",
  alignItems: "center",
  justifyContent: "space-between",
  backdropFilter: "blur(8px)",
  top: 0,
  left: 0,
  width: "100%",
  height: HEADER_HEIGHT,
  zIndex: 2,
  "::selection": {
    backgroundColor: getColorScaleValueByUseCase(
      "primary",
      backgrounds.uiElement_active
    ),
  },
});

export default Container;
