import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
export default css({
  display: "flex",
  fontSize: "$caption",
  lineHeight: 1,
  fontFamily: "$caption",
  color: getColorScaleValueByUseCase("neutral", text.hiContrast),
  // safeguard against products which may have hardcoded margins on `label` HTML elements
  margin: 0
});