import React from "react";
import IconPrimitive from "@vitality-ds/icons";

const HealthCare = (props) => (
  <IconPrimitive {...props}>
    <g clip-path="url(#clip0_4_75)">
      <path
        d="M8.41 20.252l1.339 1.178.01.009 1.528 1.32a1.09 1.09 0 001.427 0l1.528-1.32.01-.009c3.027-2.676 5.296-4.726 6.937-6.724C23.107 12.372 24 10.26 24 8.064 24 4.09 20.886.975 16.911.975c-1.786 0-3.552.673-4.91 1.84C10.64 1.647 8.875.974 7.088.974 3.114.975 0 4.09 0 8.064c0 4.805 3.345 7.742 8.41 12.188zM7.634 9.818h2.91v-2.91h2.91v2.91h2.91v2.91h-2.91v2.91h-2.91v-2.91h-2.91v-2.91z"
        fill="currentColor"
      />
    </g>
    <clipPath id="clip0_4_75">
      <path fill="currentColor" d="M0 0h24v24H0z" />
    </clipPath>
  </IconPrimitive>
);

export default HealthCare;
