import { css } from "@vitality-ds/system";
export default css({
  lineHeight: 1,
  display: "inline-block",
  svg: {
    width: "1em",
    height: "1em",
    path: {
      fill: "currentColor"
    },
    // Todo: find out why icons require top margin reset
    marginTop: 0
  }
});