import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
export default css({
  fill: "none",
  stroke: getColorScaleValueByUseCase("neutralA", text.lowContrast),
  strokeDasharray: 60,
  strokeDashoffset: "inherit",
  strokeLinecap: "round",
  strokeWidth: 1.5,
  "@media screen and (forced-colors: active)": {
    filter: "grayscale(100%)",
    stroke: "CanvasText"
  },
  variants: {
    color: {
      neutral: {
        stroke: getColorScaleValueByUseCase("neutral", text.onSolidBackgrounds)
      },
      primary: {
        stroke: getColorScaleValueByUseCase("primary", text.onSolidBackgrounds)
      },
      critical: {
        stroke: getColorScaleValueByUseCase("critical", text.onSolidBackgrounds)
      },
      success: {
        stroke: getColorScaleValueByUseCase("neutral", text.onSolidBackgrounds)
      },
      info: {
        stroke: getColorScaleValueByUseCase("info", text.onSolidBackgrounds)
      },
      warning: {
        stroke: getColorScaleValueByUseCase("warning", text.onSolidBackgrounds)
      }
    }
  }
});