import { Elevations } from "../../Elevation";
import {
  ElevationsKeys,
  ElevationToken,
  ThemeElevationType,
} from "../../Elevation/types";
import { Platforms } from "../../types";
import createBoxShadows from "../utils/createBoxShadows";

const getShadows = (
  data: ElevationToken["data"] | ElevationToken["dataRN"],
  platform: Platforms
) => {
  switch (platform) {
    case "web":
      return createBoxShadows(data as ElevationToken["data"]);
    case "reactNative":
      return data;
    default:
      return null;
  }
};

export default <T extends Platforms>(platform: T): ThemeElevationType<T> => {
  const output = {};

  Object.keys(Elevations).map((token: ElevationsKeys) => {
    if (platform === "reactNative") {
      output[token] = getShadows(Elevations[token].dataRN, platform);
      return null;
    }

    output[token] = getShadows(Elevations[token].data, platform);
    return null;
  });

  return output as ThemeElevationType<T>;
};
