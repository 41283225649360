import { Alert, Completed, Error, Info } from "@vitality-ds/icons";
// eslint-disable-next-line import/prefer-default-export
export var DEFAULT_CONTENT = {
  success: {
    icon: Completed,
    title: "Success",
    spinnerColor: "success",
    iconColor: "success"
  },
  info: {
    icon: Info,
    title: "Information",
    spinnerColor: "info",
    iconColor: "info"
  },
  warning: {
    icon: Alert,
    title: "Warning",
    spinnerColor: "warning",
    iconColor: "warning"
  },
  critical: {
    icon: Error,
    title: "Critical",
    spinnerColor: "critical",
    iconColor: "critical"
  }
};