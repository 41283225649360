import { css, keyframes } from "@vitality-ds/system";
var rotate = keyframes({
  to: {
    transform: "rotate(360deg)"
  }
});
export default css({
  display: "inline-flex",
  verticalAlign: "middle",
  animation: "".concat(rotate, " 0.8s infinite"),
  animationTimingFunction: "cubic-bezier(0.4, 0.15, 0.6, 0.85)",
  transformOrigin: "center"
});