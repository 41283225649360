import { css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import { colorUseCases } from "@vitality-ds/tokens";
export default css({
  background: "none",
  padding: "$xs",
  marginLeft: "-$xs",
  border: 0,
  fontFamily: "$caption",
  fontSize: "$caption",
  lineHeight: "$caption",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline"
  },
  variants: {
    color: {
      neutral: {
        color: getColorScaleValueByUseCase("neutral", colorUseCases.text.lowContrast),
        outlineColor: getColorScaleValueByUseCase("neutral", colorUseCases.borders.focusRing)
      },
      primary: {
        color: getColorScaleValueByUseCase("primary", colorUseCases.text.hiSaturation),
        outlineColor: getColorScaleValueByUseCase("primary", colorUseCases.borders.focusRing)
      }
    }
  },
  defaultVariants: {
    color: "neutral"
  }
});