import { FontFamilies } from "../../Font";
import { FontFamilyToken, ThemeFontStylesType } from "../../Font/types";
import { Platforms } from "../../types";
import { fontStylesTokens } from "../constants";
import commonGetter from "./commonGetter";

const getFontValue = (
  { fontFamily }: FontFamilyToken["data"],
  platform: Platforms
) => {
  switch (platform) {
    case "web":
      return fontFamily;
    case "reactNative":
      return fontFamily;
    default:
      return fontFamily;
  }
};

export default <T extends Platforms>(platform: T) =>
  commonGetter({
    tokens: fontStylesTokens[platform],
    platform,
    getValue: getFontValue,
    additionalValues:
      platform === "web"
        ? {
            default: getFontValue(FontFamilies.web.sans.data, "web"),
          }
        : null,
  }) as ThemeFontStylesType["FontFamilies"][T];
