import IconPrimitive from "@vitality-ds/icons";
import React from "react";

function Sun(props) {
  return (
    <IconPrimitive {...props}>
      <path d="M11.1667 2V4.5H12.8333V2H11.1667ZM5.51917 4.34083L4.34083 5.51917L6.1075 7.28583L7.2875 6.10833L5.51917 4.34083ZM18.4808 4.34083L16.7142 6.1075L17.8917 7.28667L19.6592 5.51917L18.4808 4.34083ZM12 6.16667C8.78833 6.16667 6.16667 8.78833 6.16667 12C6.16667 15.2117 8.78833 17.8333 12 17.8333C15.2117 17.8333 17.8333 15.2117 17.8333 12C17.8333 8.78833 15.2117 6.16667 12 6.16667ZM12 7.83333C14.3108 7.83333 16.1667 9.68917 16.1667 12C16.1667 14.3108 14.3108 16.1667 12 16.1667C9.68917 16.1667 7.83333 14.3108 7.83333 12C7.83333 9.68917 9.68917 7.83333 12 7.83333ZM2 11.1667V12.8333H4.5V11.1667H2ZM19.5 11.1667V12.8333H22V11.1667H19.5ZM6.10833 16.7133L4.34083 18.4808L5.51917 19.6592L7.28583 17.8925L6.10833 16.7133ZM17.8917 16.7133L16.7133 17.8917L18.4808 19.6592L19.6592 18.4808L17.8925 16.7142L17.8917 16.7133ZM11.1667 19.5V22H12.8333V19.5H11.1667Z" />
    </IconPrimitive>
  );
}
export default Sun;
