import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
var baseHeaderColor = getColorScaleValueByUseCase("neutral", text.hiContrast);
var baseIconColor = getColorScaleValueByUseCase("neutral", text.lowContrast);
export default css({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  boxSizing: "border-box",
  paddingBottom: "$lg",
  paddingLeft: "$xl",
  paddingRight: "$xl",
  order: "1",
  $$textColor: baseHeaderColor,
  $$iconColor: baseIconColor,
  color: "$$textColor",
  svg: {
    color: "$$iconColor"
  },
  variants: {
    variant: {
      custom: {
        $$textColor: baseHeaderColor,
        $$iconColor: baseIconColor
      },
      transactional: {
        $$textColor: baseHeaderColor,
        $$iconColor: baseIconColor
      },
      passive: {
        $$textColor: baseHeaderColor,
        $$iconColor: baseIconColor
      },
      warning: {
        $$textColor: getColorScaleValueByUseCase("warning", text.hiContrast),
        $$iconColor: getColorScaleValueByUseCase("warning", text.lowContrast)
      },
      destructive: {
        $$textColor: getColorScaleValueByUseCase("critical", text.hiContrast),
        $$iconColor: getColorScaleValueByUseCase("critical", text.lowContrast)
      },
      acknowledgement: {
        $$textColor: baseHeaderColor,
        $$iconColor: baseIconColor
      }
    }
  }
});