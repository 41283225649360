import { useMemo } from "react";
import { getCustomComponents, getStyles } from "../logic";
var useGetCustomComponents = function useGetCustomComponents(_ref) {
  var hideDropdownIndicator = _ref.hideDropdownIndicator,
    _ref$multiSelectType = _ref.multiSelectType,
    multiSelectType = _ref$multiSelectType === void 0 ? "chip" : _ref$multiSelectType,
    component = _ref.component,
    _ref$valueRenderType = _ref.valueRenderType,
    valueRenderType = _ref$valueRenderType === void 0 ? "default" : _ref$valueRenderType;
  var customStyles = useMemo(function () {
    return getStyles(multiSelectType);
  }, [multiSelectType]);
  var customComponents = useMemo(function () {
    return getCustomComponents({
      hideDropdownIndicator: hideDropdownIndicator,
      multiSelectType: multiSelectType,
      component: component,
      valueRenderType: valueRenderType
    });
  }, [hideDropdownIndicator]);
  return {
    customComponents: customComponents,
    styles: customStyles
  };
};
export default useGetCustomComponents;