import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const greyA: ColorScale<"greyA"> = {
  greyA1: {
    name: "greyA1",
    data: generateColorData("greyA1", { h: 192, s: 54, l: 79, a: 0.01 }),
  },
  greyA2: {
    name: "greyA2",
    data: generateColorData("greyA2", { h: 192, s: 54, l: 69, a: 0.05 }),
  },
  greyA3: {
    name: "greyA3",
    data: generateColorData("greyA3", { h: 192, s: 54, l: 79, a: 0.08 }),
  },
  greyA4: {
    name: "greyA4",
    data: generateColorData("greyA4", { h: 192, s: 54, l: 79, a: 0.1 }),
  },
  greyA5: {
    name: "greyA5",
    data: generateColorData("greyA5", { h: 192, s: 54, l: 79, a: 0.12 }),
  },
  greyA6: {
    name: "greyA6",
    data: generateColorData("greyA6", { h: 192, s: 54, l: 79, a: 0.2 }),
  },
  greyA7: {
    name: "greyA7",
    data: generateColorData("greyA7", { h: 192, s: 54, l: 79, a: 0.24 }),
  },
  greyA8: {
    name: "greyA8",
    data: generateColorData("greyA8", { h: 192, s: 44, l: 78, a: 0.34 }),
  },
  greyA9: {
    name: "greyA9",
    data: generateColorData("greyA9", { h: 192, s: 44, l: 78, a: 0.54 }),
  },
  greyA10: {
    name: "greyA10",
    data: generateColorData("greyA10", { h: 192, s: 44, l: 78, a: 0.6 }),
  },
  greyA11: {
    name: "greyA11",
    data: generateColorData("greyA11", { h: 192, s: 74, l: 90, a: 0.85 }),
  },
  greyA12: {
    name: "greyA12",
    data: generateColorData("greyA12", { h: 192, s: 14, l: 93, a: 1 }),
  },
  greyA13: {
    name: "greyA13",
    data: generateColorData("greyA13", { h: 192, s: 20, l: 99, a: 1 }),
  },
};

export default greyA;
