import _extends from "@babel/runtime/helpers/extends";
import { useMemo } from "react";
import getComponentFromLookup from "../../../../helpers/logic/get-component-from-lookup";
import BUTTON_TYPES from "../constants";
var useToolbar = function useToolbar(actions) {
  var ActionsComponents = useMemo(function () {
    return actions === null || actions === void 0 ? void 0 : actions.map(function (action, index) {
      var type = action.type,
        props = action.props,
        id = action.id;
      return getComponentFromLookup(BUTTON_TYPES, type, "button", _extends({
        size: "compact",
        key: id || index
      }, props));
    });
  }, [actions]);
  return {
    ActionsComponents: ActionsComponents
  };
};
export default useToolbar;