var spacingVariant = {
  /**
   * This property is shorthand for the gap CSS property. Gap values refer to the `theme.space` object.
   */
  spacing: {
    none: {
      gap: 0
    },
    xs: {
      gap: "$xs"
    },
    sm: {
      gap: "$sm"
    },
    md: {
      gap: "$md"
    },
    lg: {
      gap: "$lg"
    },
    xl: {
      gap: "$xl"
    },
    xxl: {
      gap: "$xxl"
    }
  }
};
export default spacingVariant;