import { FolderImage } from "./Images/folder";
import { PadlockImage } from "./Images/padlock";
import { ReferenceScreenImage } from "./Images/referenceScreen";
import { SpeechBubbleImage } from "./Images/speechBubble";
var IMAGE_COMPONENT_MAP = {
  folder: {
    component: FolderImage
  },
  padlock: {
    component: PadlockImage
  },
  referenceScreen: {
    component: ReferenceScreenImage
  },
  speechBubble: {
    component: SpeechBubbleImage
  }
};
export default IMAGE_COMPONENT_MAP;