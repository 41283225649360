import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";
var useTruncateContent = function useTruncateContent() {
  var _useState = useState(true),
    _useState2 = _slicedToArray(_useState, 2),
    isTruncated = _useState2[0],
    setIsTruncated = _useState2[1];
  var toggleTruncated = function toggleTruncated() {
    return setIsTruncated(!isTruncated);
  };
  return {
    isTruncated: isTruncated,
    toggleTruncated: toggleTruncated
  };
};
export default useTruncateContent;