import { css } from "@vitality-ds/system";
export default css({
  display: "flex",
  flex: 1,
  gap: "$md",
  alignItems: "flex-start",
  svg: {
    flexShrink: 0
  },
  variants: {
    size: {
      compact: {
        gap: "$sm"
      },
      "default": {}
    }
  }
});