import {
  AllScales,
  ColorStepsNumbers,
  StitchesColorTokenValues,
} from "../types";

const getColorScaleValueByUseCase = (
  scale: AllScales,
  useCase: ColorStepsNumbers
): StitchesColorTokenValues => `$colors$${scale}${useCase}`;

export default getColorScaleValueByUseCase;
