import { globalCss } from "@vitality-ds/system";
import { useCallback, useEffect } from "react";

import useLocalState from "../../../hooks/useLocalState";
import style from "../globalStyle";
import { UseWrapper } from "../types";

const globalStyles = globalCss(style.global);

const useWrapper = (pageContext: { layout: string }): UseWrapper => {
  const [isSidebarOpen, setSidebarOpen] = useLocalState(false);
  const { layout } = pageContext;
  const isHomePage = layout === "home_page";

  const toggleSidebarOpen = useCallback(() => {
    setSidebarOpen(!isSidebarOpen);
  }, [isSidebarOpen, setSidebarOpen]);

  useEffect(() => {
    globalStyles();
  }, []);

  return {
    isHomePage,
    isSidebarOpen,
    toggleSidebarOpen,
  };
};

export default useWrapper;
