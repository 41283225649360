import { Duration } from "luxon";
import { ALLOWED_CHARACTERS } from "./constants";

// eslint-disable-next-line import/prefer-default-export
export function format(duration) {
  var formattedDuration = "";
  if (!duration) {
    return null;
  }
  var validatedDuration = duration === null || duration === void 0 ? void 0 : duration.toString().replace(ALLOWED_CHARACTERS, "");
  var durationInt = parseInt(validatedDuration, 10);
  if (!durationInt) {
    durationInt = 0;
  }
  var rescaledDuration = Duration.fromObject({
    minutes: durationInt
  }).rescale().toObject();
  var largeDenominationsAsHours = parseInt(Duration.fromObject({
    years: rescaledDuration === null || rescaledDuration === void 0 ? void 0 : rescaledDuration.years,
    months: rescaledDuration === null || rescaledDuration === void 0 ? void 0 : rescaledDuration.months,
    weeks: rescaledDuration === null || rescaledDuration === void 0 ? void 0 : rescaledDuration.weeks,
    days: rescaledDuration === null || rescaledDuration === void 0 ? void 0 : rescaledDuration.days,
    hours: rescaledDuration === null || rescaledDuration === void 0 ? void 0 : rescaledDuration.hours
  }).toFormat("h"), 10);
  rescaledDuration.hours = largeDenominationsAsHours;
  if (rescaledDuration.hours) {
    formattedDuration += "".concat(rescaledDuration.hours, "h ");
  }
  if (rescaledDuration.minutes) {
    formattedDuration += "".concat(rescaledDuration.minutes, "m");
  }
  return formattedDuration;
}