import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds,
  borders = colorUseCases.borders;
export default css({
  position: "relative",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "$sm",
  height: 32,
  WebkitAppearance: "none",
  width: "100%",
  $$borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement),
  border: "1px solid $$borderColor",
  borderRadius: "$default",
  backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.app),
  color: getColorScaleValueByUseCase("neutral", text.hiContrast),
  variants: {
    textAlign: {
      left: {
        textAlign: "left"
      },
      right: {
        textAlign: "right"
      }
    },
    disabled: {
      "true": {
        "&, & input": {
          cursor: "not-allowed"
        },
        $$borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_disabled),
        backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_disabled),
        color: getColorScaleValueByUseCase("neutral", text.disabled)
      },
      "false": {
        "&:hover:not(:active, :focus-within)": {
          $$borderColor: getColorScaleValueByUseCase("neutral", borders.uiElement_hovered),
          backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement_hovered)
        },
        "&:focus-within": {
          $$borderColor: getColorScaleValueByUseCase("primary", borders.uiElement_selected),
          borderColor: "$$borderColor",
          boxShadow: "0 0 0 1px $$borderColor"
        },
        "&:active": {
          $$borderColor: getColorScaleValueByUseCase("primary", borders.uiElement_active),
          borderColor: "$$borderColor",
          boxShadow: "0 0 0 1px $$borderColor"
        }
      }
    },
    hasError: {
      "true": {
        $$borderColor: getColorScaleValueByUseCase("critical", borders.uiElement_active),
        borderColor: "$$borderColor",
        "&:hover:not(:active, :focus-within)": {
          $$borderColor: getColorScaleValueByUseCase("critical", borders.uiElement_active)
        },
        "&:focus-within": {
          $$borderColor: getColorScaleValueByUseCase("critical", borders.uiElement_selected)
        },
        "&:active": {
          $$borderColor: getColorScaleValueByUseCase("critical", borders.uiElement_active),
          borderColor: "$$borderColor",
          boxShadow: "0 0 0 1px $$borderColor"
        }
      }
    }
  },
  compoundVariants: [{
    hasError: true,
    disabled: true,
    css: {
      $$borderColor: getColorScaleValueByUseCase("critical", borders.uiElement_active)
    }
  }]
});