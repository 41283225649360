import _extends from "@babel/runtime/helpers/extends";
import { TextCell } from "../Cells";
import { CELL_TYPES, DEFAULT_EMPTY_PLACEHOLDER } from "./constants";
function isCellContentsEmpty(rowData, colId, computedCellProps) {
  return rowData[colId] !== 0 && !rowData[colId] && !computedCellProps;
}
function getCellType(cellType, _ref, rowData, emptyPlaceholderText) {
  var colId = _ref.colId,
    getCellProps = _ref.getCellProps;
  var computedCellProps = typeof getCellProps === "function" && getCellProps(colId, rowData);
  if (isCellContentsEmpty(rowData, colId, computedCellProps)) {
    return {
      as: TextCell,
      children: emptyPlaceholderText || DEFAULT_EMPTY_PLACEHOLDER
    };
  }
  var _ref2 = CELL_TYPES[cellType] || CELL_TYPES.text,
    Component = _ref2.component,
    defaultGetCellProps = _ref2.getCellProps;
  var getCellPropsFn = getCellProps || defaultGetCellProps;
  return _extends({}, getCellPropsFn(colId, rowData), {
    as: Component
  });
}
export default getCellType;