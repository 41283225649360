import { getDefaultContent } from "../logic";
var useCalloutContent = function useCalloutContent(_ref) {
  var title = _ref.title,
    severity = _ref.severity,
    size = _ref.size,
    icon = _ref.icon;
  var _getDefaultContent = getDefaultContent(severity),
    defaultTitle = _getDefaultContent.title,
    defaultIcon = _getDefaultContent.icon,
    iconColor = _getDefaultContent.iconColor,
    spinnerColor = _getDefaultContent.spinnerColor;
  var iconSize = size === "compact" ? "sm" : "md";
  var titleToDisplay = title || defaultTitle;
  var iconToDisplay = icon || defaultIcon;
  return {
    titleToDisplay: titleToDisplay,
    iconToDisplay: iconToDisplay,
    iconColor: iconColor,
    spinnerColor: spinnerColor,
    iconSize: iconSize
  };
};
export default useCalloutContent;