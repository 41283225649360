import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import { BaseInputWrapper } from "../../TextInput/styled";
var borders = colorUseCases.borders;
export default css(_defineProperty({
  display: "flex",
  gap: "$sm",
  flex: 1,
  alignItems: "center",
  overflow: "visible",
  marginRight: "$sm",
  borderRight: "1px solid ".concat(getColorScaleValueByUseCase("neutral", borders.uiElement)),
  minWidth: 155,
  "& >:not(label)": {
    width: "100%"
  }
}, "".concat(BaseInputWrapper), {
  borderColor: "transparent",
  backgroundColor: "transparent",
  paddingLeft: 0,
  minWidth: 130
}));