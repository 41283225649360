import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { DateTime } from "luxon";
import { MONTHS, TOOLTIP_TEXTS } from "./constants";
export function generateYears(minYear, maxYear) {
  var arr = [];
  for (var i = minYear; i <= maxYear; i += 1) {
    arr.push({
      label: i.toString(),
      value: i.toString()
    });
  }
  return arr;
}
export function generateMonths() {
  var arr = [];
  for (var i = 0; i <= 11; i += 1) {
    arr.push({
      label: MONTHS[i],
      value: MONTHS[i]
    });
  }
  return arr;
}
export function convertLuxonYearMonthStateToDate(year, month) {
  return new Date(year, month);
}
export var determineSelectedDate = function determineSelectedDate(selectedDate, showFirstDateInRangeOnOpen) {
  if (!Array.isArray(selectedDate)) {
    return selectedDate;
  }
  var _selectedDate = _slicedToArray(selectedDate, 2),
    firstDate = _selectedDate[0],
    secondDate = _selectedDate[1];
  if (showFirstDateInRangeOnOpen) {
    return firstDate;
  }
  if (Number.isNaN(Date.parse(secondDate))) {
    return Number.isNaN(Date.parse(firstDate)) ? null : firstDate;
  }
  return secondDate;
};
export function getInitialState(currentTime, selectedDate) {
  if (selectedDate) {
    var selectedDateAsLuxon = DateTime.fromJSDate(selectedDate);
    return {
      selectedMonth: {
        // -1 to align the luxon current time with Date constructor and array indexes
        value: {
          value: MONTHS[selectedDateAsLuxon.month - 1],
          label: MONTHS[selectedDateAsLuxon.month - 1]
        },
        monthInt: selectedDateAsLuxon.month - 1
      },
      selectedYear: {
        value: {
          value: selectedDateAsLuxon.year.toString(),
          label: selectedDateAsLuxon.year.toString()
        },
        yearInt: selectedDateAsLuxon.year
      }
    };
  }
  return {
    selectedMonth: {
      // -1 to align the luxon current time with Date constructor and array indexes
      value: {
        value: MONTHS[currentTime.month - 1],
        label: MONTHS[currentTime.month - 1]
      },
      monthInt: currentTime.month - 1
    },
    selectedYear: {
      value: {
        value: currentTime.year.toString(),
        label: currentTime.year.toString()
      },
      yearInt: currentTime.year
    }
  };
}
export function dateObjectToShortHand(date) {
  if (date instanceof Date) {
    var time = DateTime.fromJSDate(date);
    return time.toFormat("dd/MM/yyyy");
  }
  throw new Error("Parsed value is not an instance of the JS Date object, and therefore cannot be converted, ensure inputted value is an instance of Date");
}
function tooltipText(action, month, year) {
  switch (action) {
    case "midYear":
      return "".concat(TOOLTIP_TEXTS.navigate, " ").concat(MONTHS[month]);
    case "edgeYear":
      return "".concat(TOOLTIP_TEXTS.navigate, " ").concat(MONTHS[month], ", ").concat(year);
    case "end":
      return TOOLTIP_TEXTS.endOfRange;
    default:
      throw new Error("toolTip could not be generated");
  }
}
function determineNextMonth(currentMonth, currentYear, maxYear) {
  var nextMonth = parseInt(currentMonth.monthInt, 10) + 1;
  if (nextMonth > 11) {
    nextMonth = 0;
    return currentYear.yearInt + 1 <= maxYear ? tooltipText("edgeYear", nextMonth, currentYear.yearInt + 1) : tooltipText("end", nextMonth, currentYear.yearInt + 1);
  }
  return tooltipText("midYear", nextMonth, currentYear.yearInt + 1);
}
function determinePrevMonth(currentMonth, currentYear, minYear) {
  var prevMonth = parseInt(currentMonth.monthInt, 10) - 1;
  if (prevMonth < 0) {
    prevMonth = 11;
    return currentYear.yearInt - 1 >= minYear ? tooltipText("edgeYear", prevMonth, currentYear.yearInt - 1) : tooltipText("end", prevMonth, currentYear.yearInt - 1);
  }
  return tooltipText("midYear", prevMonth, currentYear.yearInt - 1);
}
export function getTooltipContents(currentMonth, currentYear, minYear, maxYear) {
  return {
    prevMonthTooltip: determinePrevMonth(currentMonth, currentYear, minYear),
    nextMonthTooltip: determineNextMonth(currentMonth, currentYear, maxYear)
  };
}
export function stateManager(state, action, minYear, maxYear) {
  switch (action.type) {
    case "DECREMENT_MONTH":
      if (state.selectedMonth.monthInt - 1 < 0 && state.selectedYear.yearInt === minYear) {
        return state;
      }
      if (state.selectedMonth.monthInt - 1 < 0) {
        return _extends({}, state, {
          selectedMonth: {
            value: {
              value: MONTHS[11],
              label: MONTHS[11]
            },
            monthInt: 11
          },
          selectedYear: {
            value: {
              value: (state.selectedYear.yearInt - 1).toString(),
              label: (state.selectedYear.yearInt - 1).toString()
            },
            yearInt: state.selectedYear.yearInt - 1
          }
        });
      }
      return _extends({}, state, {
        selectedMonth: {
          value: {
            value: MONTHS[state.selectedMonth.monthInt - 1],
            label: MONTHS[state.selectedMonth.monthInt - 1]
          },
          monthInt: state.selectedMonth.monthInt - 1
        }
      });
    case "INCREMENT_MONTH":
      if (state.selectedMonth.monthInt + 1 > 11 && state.selectedYear.yearInt === maxYear) {
        return state;
      }
      if (state.selectedMonth.monthInt + 1 > 11) {
        return _extends({}, state, {
          selectedMonth: {
            value: {
              value: MONTHS[0],
              label: MONTHS[0]
            },
            monthInt: 0
          },
          selectedYear: {
            value: {
              value: (state.selectedYear.yearInt + 1).toString(),
              label: (state.selectedYear.yearInt + 1).toString()
            },
            yearInt: state.selectedYear.yearInt + 1
          }
        });
      }
      return _extends({}, state, {
        selectedMonth: {
          value: {
            value: MONTHS[state.selectedMonth.monthInt + 1],
            label: MONTHS[state.selectedMonth.monthInt + 1]
          },
          monthInt: state.selectedMonth.monthInt + 1
        }
      });
    case "GOTO_MONTH":
      return _extends({}, state, {
        selectedMonth: {
          value: {
            value: MONTHS[MONTHS.indexOf(action.monthSelection.value)],
            label: MONTHS[MONTHS.indexOf(action.monthSelection.value)]
          },
          monthInt: MONTHS.indexOf(action.monthSelection.value)
        }
      });
    case "GOTO_YEAR":
      return _extends({}, state, {
        selectedYear: {
          value: {
            value: action.yearSelection.value,
            label: action.yearSelection.label
          },
          yearInt: parseInt(action.yearSelection.value, 10)
        }
      });
    default:
      return state;
  }
}