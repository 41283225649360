import { DateTime } from "luxon";
import PRESET_OPTIONS from "./constants";
export function turnValueArrayToJSDate(value) {
  var arr = [];
  for (var i = 0; i < value.length; i += 1) {
    if (!Number.isNaN(Date.parse(DateTime.fromString(value[i], "yyyy-MM-dd").toJSDate()))) arr.push(DateTime.fromString(value[i], "yyyy-MM-dd").toJSDate());
  }
  return arr;
}
export function stateObjectToArray(stateObject) {
  return [stateObject.startDate ? stateObject.startDate : "", stateObject.endDate ? stateObject.endDate : ""];
}
export function determineFallbackDefault(toOrFrom, defaultValueFromPreset) {
  if (defaultValueFromPreset) {
    var selectedOption = PRESET_OPTIONS.find(function (item) {
      return item.key === defaultValueFromPreset;
    });
    return selectedOption[toOrFrom];
  }
  return "";
}
export function createRangeEventObject(onChangeObjects) {
  var startDate = onChangeObjects.startDate,
    endDate = onChangeObjects.endDate;
  return {
    value: [startDate.value || "", endDate.value || ""],
    isValid: [startDate.isValid, endDate.isValid],
    errorMessage: [startDate.errorMessage, endDate.errorMessage],
    textValue: [startDate.textValue, endDate.textValue]
  };
}