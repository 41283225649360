import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const orange: ColorScale<"orange"> = {
  orange1: {
    name: "orange1",
    data: generateColorData("orange1", { h: 35, s: 70, l: 99, a: 1 }),
  },
  orange2: {
    name: "orange2",
    data: generateColorData("orange2", { h: 35, s: 83, l: 97, a: 1 }),
  },
  orange3: {
    name: "orange3",
    data: generateColorData("orange3", { h: 35, s: 100, l: 95, a: 1 }),
  },
  orange4: {
    name: "orange4",
    data: generateColorData("orange4", { h: 23, s: 100, l: 92, a: 1 }),
  },
  orange5: {
    name: "orange5",
    data: generateColorData("orange5", { h: 23, s: 100, l: 88, a: 1 }),
  },
  orange6: {
    name: "orange6",
    data: generateColorData("orange6", { h: 23, s: 100, l: 82, a: 1 }),
  },
  orange7: {
    name: "orange7",
    data: generateColorData("orange7", { h: 23, s: 100, l: 75, a: 1 }),
  },
  orange8: {
    name: "orange8",
    data: generateColorData("orange8", { h: 23, s: 94, l: 64, a: 1 }),
  },
  orange9: {
    name: "orange9",
    data: generateColorData("orange9", { h: 23, s: 99, l: 48, a: 1 }),
  },
  orange10: {
    name: "orange10",
    data: generateColorData("orange10", { h: 23, s: 100, l: 46, a: 1 }),
  },
  orange11: {
    name: "orange11",
    data: generateColorData("orange11", { h: 23, s: 100, l: 37, a: 1 }),
  },
  orange12: {
    name: "orange12",
    data: generateColorData("orange12", { h: 23, s: 60, l: 17, a: 1 }),
  },
  orange13: {
    name: "orange13",
    data: generateColorData("orange13", { h: 35, s: 70, l: 99, a: 1 }),
  },
};

export default orange;
