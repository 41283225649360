import { useMemo, useRef } from "react";
var useContainerAnimations = function useContainerAnimations(toast, getContainerAnimationStyles, getToastAnimationStyles) {
  var ref = useRef(null);
  var height = ref.current ? ref.current.offsetHeight : 72;
  var containerStyles = useMemo(function () {
    return getContainerAnimationStyles(toast.state, height);
  }, [toast.state, height]);
  var toastStyles = useMemo(function () {
    return getToastAnimationStyles(toast.state);
  }, [toast.state]);
  return [containerStyles, toastStyles, ref];
};
export default useContainerAnimations;