import { useMemo } from "react";
import handleSearch from "../logic";
var useLoadOptions = function useLoadOptions(onSearch, minChars, requestDelayMs, memoizedDebouncedSearch) {
  var loadOptions = useMemo(function () {
    return function (inputText, callback) {
      return handleSearch(inputText, callback, minChars, requestDelayMs, onSearch, memoizedDebouncedSearch);
    };
  }, [onSearch, minChars, requestDelayMs, memoizedDebouncedSearch]);
  return {
    loadOptions: loadOptions
  };
};
export default useLoadOptions;