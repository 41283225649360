import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import { SIDEBAR_BORDER_WIDTH, SIDEBAR_WIDTH } from "../constants";
var backgrounds = colorUseCases.backgrounds,
  text = colorUseCases.text,
  borders = colorUseCases.borders;
export default css({
  position: "fixed",
  bottom: 0,
  right: -SIDEBAR_WIDTH - SIDEBAR_BORDER_WIDTH,
  top: 0,
  display: "flex",
  zIndex: "$dialog",
  transition: "right 250ms cubic-bezier(0.16, 1, 0.3, 1)",
  width: SIDEBAR_WIDTH,
  background: getColorScaleValueByUseCase("neutral", backgrounds.app),
  borderLeft: "".concat(SIDEBAR_BORDER_WIDTH, "px solid ").concat(getColorScaleValueByUseCase("neutral", borders.uiElement)),
  color: getColorScaleValueByUseCase("neutral", text.hiContrast),
  variants: {
    isOpen: {
      "true": {
        right: 0
      }
    }
  }
});