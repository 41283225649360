import { SpaceType } from "./types";

// eslint-disable-next-line import/prefer-default-export
export const Space: SpaceType = {
  xs: {
    name: "xs",
    description: "Extra Small spacing",
    data: {
      value: 2,
    },
  },
  sm: {
    name: "sm",
    description: "Small spacing",
    data: {
      value: 4,
    },
  },
  md: {
    name: "md",
    description: "Medium spacing (default)",
    data: {
      value: 8,
    },
  },
  lg: {
    name: "lg",
    description: "Large spacing",
    data: {
      value: 16,
    },
  },
  xl: {
    name: "xl",
    description: "Extra Large spacing",
    data: {
      value: 24,
    },
  },
  xxl: {
    name: "xxl",
    description: "Extra Extra Large spacing",
    data: {
      value: 32,
    },
  },
};
