import _extends from "@babel/runtime/helpers/extends";
import { getColorScaleValueByUseCase } from "@vitality-ds/system";
import { colorUseCases } from "@vitality-ds/tokens";
var text = colorUseCases.text,
  borders = colorUseCases.borders,
  backgrounds = colorUseCases.backgrounds;
var buttonResetStyles = {
  border: 0
};
var getFocusStyle = function getFocusStyle(colorScale) {
  return {
    outline: "none",
    boxShadow: "0 0 0 $space$outlineWidth ".concat(getColorScaleValueByUseCase(colorScale, borders.focusRing))
  };
};
var getLowImportanceVariant = function getLowImportanceVariant(colorScale) {
  return {
    backgroundColor: "transparent",
    color: getColorScaleValueByUseCase(colorScale, text.hiSaturation),
    borderColor: "transparent",
    "a&": {
      "&:focus-visible": getFocusStyle(colorScale)
    },
    "button&": _extends({}, buttonResetStyles, {
      "&:hover": {
        backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.subtle)
      },
      "&:focus-visible": getFocusStyle(colorScale),
      "&:active": {
        backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElement_active)
      }
    })
  };
};
var getHighImportanceVariant = function getHighImportanceVariant(colorScale) {
  return {
    color: getColorScaleValueByUseCase(colorScale, text.onSolidBackgrounds),
    backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElementSolid),
    borderColor: "transparent",
    "a&": {
      "&:focus-visible": getFocusStyle(colorScale)
    },
    "button&": _extends({}, buttonResetStyles, {
      "&:hover": {
        backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElementSolid_hovered)
      },
      "&:focus-visible": _extends({
        backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElementSolid_focused)
      }, getFocusStyle(colorScale)),
      "&:active": {
        backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElementSolid_active)
      }
    })
  };
};
var getNormalImportanceVariant = function getNormalImportanceVariant(colorScale) {
  return {
    color: getColorScaleValueByUseCase(colorScale, text.hiSaturation),
    backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElement),
    borderColor: getColorScaleValueByUseCase(colorScale, borders.uiElement),
    "a&": {
      "&:focus-visible": getFocusStyle(colorScale)
    },
    "button&": _extends({}, buttonResetStyles, {
      "&:hover": {
        backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElement_hovered)
      },
      "&:focus-visible": _extends({
        backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElement_focused)
      }, getFocusStyle(colorScale)),
      "&:active": {
        backgroundColor: getColorScaleValueByUseCase(colorScale, backgrounds.uiElement_active)
      }
    })
  };
};
export { getNormalImportanceVariant, getLowImportanceVariant, getHighImportanceVariant };