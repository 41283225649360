import React from "react";
import IconPrimitive from "@vitality-ds/icons";

const Documentation = (props) => (
  <IconPrimitive {...props}>
    <path
      fill="currentColor"
      d="M9.16 10.09a.818.818 0 01-.818-.817V3H2.615a.818.818 0 00-.818.818v16.364c0 .452.366.818.818.818h12a.818.818 0 00.818-.818V10.09H9.161zm2.182 7.092H5.888a.818.818 0 010-1.636h5.454a.818.818 0 010 1.636zm0-3.273H5.888a.818.818 0 010-1.636h5.454a.818.818 0 010 1.636z"
    ></path>
    <path fill="currentColor" d="M9.979 3.48v4.974h4.975L9.979 3.48z"></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.385 3a.818.818 0 01.818.818v14.615a.818.818 0 01-.24.579l-1.748 1.748a.815.815 0 01-1.157 0l-1.749-1.748a.818.818 0 01-.24-.579V3.818A.818.818 0 0117.889 3h3.497zM21 4h-1v14h1V4z"
      clipRule="evenodd"
    ></path>
  </IconPrimitive>
);

export default Documentation;
