import {
  SidebarContext as VitalitySidebarContext,
  VitalityProvider,
} from "@vitality-ds/components";
import React from "react";

import BackgroundGradient from "../BackgroundGradient";
import Footer from "../Footer";
import Header from "../Header";
import { HEADER_HEIGHT } from "../Header/constants";
import Sidebar from "../Sidebar";
import { sidebarWidthVar } from "./globalStyle";
import useWrapper from "./hooks/useWrapper";
import { ContainerFlex, MainFlex, MainWrapperFlex } from "./styled";
import { WrapperType } from "./types";

function WrapContainer({ children }: { children: JSX.Element }) {
  const { isSidebarOpen, SIDEBAR_WIDTH } = React.useContext(
    VitalitySidebarContext
  );
  return (
    <MainWrapperFlex
      css={{
        [`--${sidebarWidthVar}`]: isSidebarOpen ? `${SIDEBAR_WIDTH}px` : "0px",
      }}
      align="stretch"
      direction="column"
      justify="stretch"
    >
      {children}
    </MainWrapperFlex>
  );
}

function Wrapper({ children, pageContext }: WrapperType): JSX.Element {
  const { isHomePage, isSidebarOpen, toggleSidebarOpen } =
    useWrapper(pageContext);

  return (
    <VitalityProvider config={{ sidebar: { topOffset: HEADER_HEIGHT + 1 } }}>
      <ContainerFlex direction="column">
        <BackgroundGradient showGrid={isHomePage} />
        <Header
          constrainWidth={isHomePage}
          toggleSidebarOpen={() => toggleSidebarOpen(!isSidebarOpen)}
          sidebarOpen={isSidebarOpen}
          isHomePage={isHomePage}
        />
        <MainWrapperFlex align="stretch" direction="column" justify="stretch">
          <WrapContainer>
            <>
              <MainFlex as="main" justify="center">
                {!isHomePage && (
                  <Sidebar
                    toggleSidebarOpen={() => toggleSidebarOpen(!isSidebarOpen)}
                    isOpen={isSidebarOpen}
                  />
                )}
                {children}
              </MainFlex>
              <Footer isHomePage={isHomePage} />
            </>
          </WrapContainer>
        </MainWrapperFlex>
      </ContainerFlex>
    </VitalityProvider>
  );
}

export default Wrapper;
