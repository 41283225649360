import { DateTime } from "luxon";
import { determineLastFinancialYear, determineThisFinancialYear } from "./helpers/determineFinancialYear";
var time = DateTime.now();
var PRESET_OPTIONS = [{
  key: "today",
  label: "Today",
  from: time.startOf("day").toFormat("yyyy-MM-dd"),
  to: time.endOf("day").toFormat("yyyy-MM-dd")
}, {
  key: "yesterday",
  label: "Yesterday",
  from: time.minus({
    day: 1
  }).startOf("day").toFormat("yyyy-MM-dd"),
  to: time.minus({
    day: 1
  }).endOf("day").toFormat("yyyy-MM-dd")
}, {
  key: "this_week",
  label: "This week",
  from: time.startOf("week").toFormat("yyyy-MM-dd"),
  to: time.endOf("week").toFormat("yyyy-MM-dd")
}, {
  key: "last_week",
  label: "Last week",
  from: time.minus({
    week: 1
  }).startOf("week").toFormat("yyyy-MM-dd"),
  to: time.minus({
    week: 1
  }).endOf("week").toFormat("yyyy-MM-dd")
}, {
  key: "this_month",
  label: "This month",
  from: time.startOf("month").toFormat("yyyy-MM-dd"),
  to: time.endOf("month").toFormat("yyyy-MM-dd")
}, {
  key: "last_month",
  label: "Last month",
  from: time.minus({
    month: 1
  }).startOf("month").toFormat("yyyy-MM-dd"),
  to: time.minus({
    month: 1
  }).endOf("month").toFormat("yyyy-MM-dd")
}, {
  key: "this_quarter",
  label: "This quarter",
  from: time.startOf("quarter").toFormat("yyyy-MM-dd"),
  to: time.endOf("quarter").toFormat("yyyy-MM-dd")
}, {
  key: "last_quarter",
  label: "Last quarter",
  from: time.minus({
    quarter: 1
  }).startOf("quarter").toFormat("yyyy-MM-dd"),
  to: time.minus({
    quarter: 1
  }).endOf("quarter").toFormat("yyyy-MM-dd")
}, {
  key: "past_12_months",
  label: "Past 12 months",
  from: time.minus({
    month: 12
  }).toFormat("yyyy-MM-dd"),
  to: time.toFormat("yyyy-MM-dd")
}, {
  key: "past_24_months",
  label: "Past 24 months",
  from: time.minus({
    month: 24
  }).toFormat("yyyy-MM-dd"),
  to: time.toFormat("yyyy-MM-dd")
}, {
  key: "this_financial_year",
  label: "This financial year",
  from: determineThisFinancialYear("from", time),
  to: determineThisFinancialYear("to", time)
}, {
  key: "last_financial_year",
  label: "Last financial year",
  from: determineLastFinancialYear("from", time),
  to: determineLastFinancialYear("to", time)
}];
export default PRESET_OPTIONS;