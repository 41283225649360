import Button from "../../../Button";
import IconButton from "../../../IconButton";
var BUTTON_TYPES = {
  iconButton: {
    component: IconButton
  },
  button: {
    component: Button
  }
};
export default BUTTON_TYPES;