import { Index } from "elasticlunr";

import { VoidFn } from "../../../../types";
import { IndexType, SearchIndex } from "./types";

export const onLoadIndex = (
  index: typeof Index,
  searchIndex: SearchIndex
): Index<unknown> => index.load(searchIndex);

export const getOnInputChange = (
  query: string,
  setQuery: VoidFn<string>,
  setResults: VoidFn<unknown[]>,
  index: Index<IndexType>
) => {
  setQuery(query);
  // Query the index with search string to get an [] of IDs
  setResults(
    index
      .search(query, { expand: true })
      // Map over each ID and return the full document
      .map(({ ref }) => index.documentStore.getDoc(ref))
      .sort((a, b) => {
        if (a.category < b.category) {
          return -1;
        }
        if (a.category > b.category) {
          return 1;
        }
        return 0;
      })
  );
};
