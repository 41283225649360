import { Platforms } from "../../types";

export default (platform: Platforms) => {
  switch (platform) {
    case "web":
      return {
        floatingElements: 3000,
        blanket: 5000,
        dialog: 5100,
        toast: 5200,
        tooltip: 5500,
        portalElement: 5600,
      };
    case "reactNative":
      return null;
    default:
      return null;
  }
};
