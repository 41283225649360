import { DateTime } from "luxon";
var june = DateTime.fromObject({
  month: 6,
  day: 30,
  hour: 0,
  second: 0
});
var july = DateTime.fromObject({
  month: 7,
  day: 1,
  hour: 0,
  second: 0
});
export function determineThisFinancialYear(toOrFrom, currentTime) {
  var paramJune = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : june;
  var paramJuly = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : july;
  if (toOrFrom === "from") {
    if (paramJuly > currentTime) {
      return paramJuly.minus({
        year: 1
      }).toFormat("yyyy-MM-dd");
    }
    return paramJuly.toFormat("yyyy-MM-dd");
  }
  if (toOrFrom === "to") {
    if (paramJune < currentTime) {
      return paramJune.plus({
        year: 1
      }).toFormat("yyyy-MM-dd");
    }
    return paramJune.toFormat("yyyy-MM-dd");
  }
  return null;
}
export function determineLastFinancialYear(toOrFrom, currentTime) {
  var paramJune = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : june;
  var paramJuly = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : july;
  if (toOrFrom === "from") {
    if (paramJuly > currentTime) {
      return paramJuly.minus({
        year: 2
      }).toFormat("yyyy-MM-dd");
    }
    return paramJuly.minus({
      year: 1
    }).toFormat("yyyy-MM-dd");
  }
  if (toOrFrom === "to") {
    if (paramJune > currentTime) {
      return paramJune.minus({
        year: 1
      }).toFormat("yyyy-MM-dd");
    }
    return paramJune.toFormat("yyyy-MM-dd");
  }
  return null;
}