import { Fallback, Image, Root } from "@radix-ui/react-avatar";
import { styled } from "@vitality-ds/system";
import { AVATAR_FALLBACK_CLASSNAME } from "./constants";
import baseAvatarContainerStyles, { interactiveStyles } from "./styles/BaseAvatarContainer.styles";
import baseAvatarImageStyles from "./styles/BaseAvatarImage.styles";

// Allow Radix component to be used as a CSS selector
Fallback.toString = function () {
  return ".".concat(AVATAR_FALLBACK_CLASSNAME);
};
export var BaseAvatarContainer = styled(Root, baseAvatarContainerStyles);
export var BaseAvatarImage = styled(Image, baseAvatarImageStyles);
export var BaseAvatarButton = styled("button", interactiveStyles);
export var BaseAvatarLink = styled("a", interactiveStyles);