import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds;
export default css({
  color: getColorScaleValueByUseCase("neutral", text.lowContrast),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  $$size: 22,
  height: "$$size",
  width: "$$size",
  alignSelf: "center",
  borderRadius: "$rounded",
  padding: "$xs",
  fontSize: "$$size",
  "&:hover": {
    backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElement_hovered),
    svg: {
      stroke: getColorScaleValueByUseCase("primary", text.hiSaturation)
    }
  },
  variants: {
    disabled: {
      "true": {
        color: getColorScaleValueByUseCase("neutral", text.disabled)
      }
    }
  }
});