import { css } from "@vitality-ds/system";

// eslint-disable-next-line import/prefer-default-export
export var BaseSpinnerWrapper = css({
  svg: {
    marginTop: "unset",
    $$size: "22px",
    height: "$$size",
    width: "$$size"
  }
});