import { css, keyframes } from "@vitality-ds/system";
var loadIn = keyframes({
  from: {
    transform: "rotate(50deg)",
    opacity: 0,
    strokeDashoffset: 60
  },
  to: {
    transform: "rotate(230deg)",
    opacity: 1,
    strokeDashoffset: 50
  }
});
export default css({
  animation: "".concat(loadIn, " 1s ease-in-out"),
  animationFillMode: "forwards",
  opacity: 0
});