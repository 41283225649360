import IconPrimitive from "@vitality-ds/icons";
import React from "react";

function Text(props) {
  return (
    <IconPrimitive {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.32 4.72V7.2a.72.72 0 01-1.44 0V4a.72.72 0 01.72-.72h12.8a.72.72 0 01.72.72v3.2a.72.72 0 11-1.44 0V4.72h-4.8v14.56h1.927a.72.72 0 110 1.44h-5.6a.72.72 0 110-1.44h1.913V4.72h-4.8z"
        clipRule="evenodd"
      />
    </IconPrimitive>
  );
}
export default Text;
