import { Link as GatsbyLink } from "gatsby";
import React from "react";

import vitalityLogo from "../../../../../../images/vitality.svg";
import { Image, LogoFlex } from "./styled";

function Logo() {
  return (
    <LogoFlex>
      <GatsbyLink to="/">
        <Image src={vitalityLogo} />
      </GatsbyLink>
    </LogoFlex>
  );
}

export default Logo;
