import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
export default css({
  variants: {
    disabledStyling: {
      "true": {
        color: getColorScaleValueByUseCase("neutral", colorUseCases.text.disabled),
        // && increase specificity - fancy !important that is unlikely into interact with other styles unintentionally
        "&&::after": {
          visibility: "visible"
        }
      }
    }
  }
});