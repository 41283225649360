export function getHeaderCheckboxState(selectData) {
  if (selectData.state.all) {
    return true;
  }
  if (!selectData.state.none) {
    return "indeterminate";
  }
  return false;
}
export function getPaginationProps(pagination) {
  if (!pagination) return null;
  var _pagination$rowsPerPa = pagination.rowsPerPageOptions,
    rowsPerPageOptions = _pagination$rowsPerPa === void 0 ? [25, 50, 75] : _pagination$rowsPerPa;
  return rowsPerPageOptions;
}